import { Component, createRef as CreateRef } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Form, Row, Col, Input, Button, Typography, message as Message } from 'antd';

import {
	MailOutlined as MailOutlinedIcon,
	ReloadOutlined as ReloadOutlinedIcon
} from '@ant-design/icons';

import ReCaptcha from 'react-google-recaptcha';

import { DefaultErrorMessage, GlobalState } from '../../../../../Contracts';
import { getServerErrorMessage } from '../../../../../Contracts/Util';
import { ForgotPasswordFormValues, ForgotPasswordRequest } from '../../../../../Contracts/Login';

import LoginActionCreators from '../../../../../Actions/Login';

const LogTag: string = 'ForgotPassword Form Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isLoginLoading: state.login.isLoading,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showLoginLoader: () => dispatch(LoginActionCreators.showLoginLoader()),
		hideLoginLoader: () => dispatch(LoginActionCreators.hideLoginLoader()),
		forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) => dispatch(LoginActionCreators.forgotPassword(forgotPasswordRequest))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
	readonly afterSubmit?: () => void;
	readonly showLoginPage: () => void;
}

export class ForgotPasswordForm extends Component<Props> {

	reCaptchaRef = CreateRef<ReCaptcha>();

	forgotPassword = async (forgotPasswordFormValues: ForgotPasswordFormValues) => {
		this.props.showLoginLoader();
		try {
			const googleRecaptchaToken = await this.reCaptchaRef.current?.executeAsync();
			if (googleRecaptchaToken && googleRecaptchaToken?.length > 0) {
				const forgotPasswordRequest: ForgotPasswordRequest = { ...forgotPasswordFormValues };
				await this.props.forgotPassword(forgotPasswordRequest);
				Message.success('Password reset email has been sent successfully', 3);
				this.props.hideLoginLoader();
				if (this.props.afterSubmit) {
					this.props.afterSubmit();
				}
			} else {
				Message.error(DefaultErrorMessage, 3);
			}
		} catch (error: any) {
			console.error(LogTag, 'ForgotPassword', error, forgotPasswordFormValues.email);
			Message.error(getServerErrorMessage(error), 3);
		} finally {
			this.props.hideLoginLoader();
		}
	}

	render() {
		return (
			<Form layout="vertical" onFinish={(forgotPasswordFormValues) => this.forgotPassword(forgotPasswordFormValues as ForgotPasswordFormValues)} className="forgot-password-form">
				<Form.Item label="Email" name="email" hasFeedback={true} rules={[
					{ required: true, type: 'email', whitespace: true, message: 'Please enter your email' }
				]}>
					<Input id="email" name="email" type="email" placeholder="Enter email" disabled={this.props.isLoginLoading} prefix={<MailOutlinedIcon />} autoComplete="email" />
				</Form.Item>
				<div className="margin-top-32px">
					<Row justify="center" align="middle">
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Button type="primary" htmlType="submit" disabled={this.props.isLoginLoading} loading={this.props.isLoginLoading} icon={<ReloadOutlinedIcon />} className="forgot-password-button full-width">Reset Password</Button>
						</Col>
					</Row>
				</div>
				<Row justify="center" align="middle" gutter={32} className="margin-top-12px">
					<Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14} className="link text-underline align-center" onClick={this.props.showLoginPage}>
						<Typography.Text className="link">Have Password?</Typography.Text>
					</Col>
				</Row>
				<div className="display-none">
					<ReCaptcha ref={this.reCaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string} theme={this.props.colorScheme} />
				</div>
			</Form>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
