import { Component, Suspense, lazy as Lazy } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Modal } from 'antd';

import { ReduxStore, Persistor } from './ReduxStore';

import './App.scss';

import { AuthenticationRoute, PrivateRoute, DefaultRoute } from './Components/Util';
import AppBoundary from './Components/Util/AppBoundary';

import { AppRoutes } from './Contracts';

import Loader from './Components/Util/UI/Loader';

// Authentication Pages
import LoginPage from './Components/AuthenticationPage/LoginPage';
import SignupPage from './Components/AuthenticationPage/SignupPage';
import ForgotPasswordPage from './Components/AuthenticationPage/ForgotPasswordPage';
// Static Website Pages
import WebsitePage from './Components/WebsitePage';
import PrivacyPolicyPage from './Components/WebsitePage/PrivacyPolicyPage';
import TermsConditionsPage from './Components/WebsitePage/TermsConditionsPage';
import CookiePolicyPage from './Components/WebsitePage/CookiePolicyPage';
import DisclaimerPage from './Components/WebsitePage/DisclaimerPage';
import TeamPage from './Components/WebsitePage/TeamPage';
import AboutPage from './Components/WebsitePage/AboutPage';
import PricingPage from './Components/WebsitePage/PricingPage';
import FeaturesPage from './Components/WebsitePage/FeaturesPage';
// Contact Form Pages
import ContactUsPage from './Components/WebsitePage/ContactFormPages/ContactUsPage';
import BugReportPage from './Components/WebsitePage/ContactFormPages/BugReportPage';
import FeatureRequestPage from './Components/WebsitePage/ContactFormPages/FeatureRequestPage';
// Dashboard Pages
const HomePage = Lazy(() => import('./Components/HomePage'));
const CalendarPage = Lazy(() => import('./Components/CalendarPage'));
const AccountsPage = Lazy(() => import('./Components/AccountsPage'));
const SettingsPage = Lazy(() => import('./Components/SettingsPage'));
const CreatePostPage = Lazy(() => import('./Components/CreatePostPage'));
// Error Pages
const ErrorPage = Lazy(() => import('./Components/ErrorPage'));
// Admin Pages
const AdminPage = Lazy(() => import('./Components/AdminPage'));

export interface Props {
	readonly updateServiceWorker: (registration: ServiceWorkerRegistration) => void;
}

export class App extends Component<Props> {

	componentDidMount() {
		document.addEventListener('updateServiceWorker', this.updateServiceWorker);
	}
	componentWillUnmount() {
		document.removeEventListener('updateServiceWorker', this.updateServiceWorker);
	}

	updateServiceWorker = ($event: Event) => {
		Modal.confirm({
			title: 'Update available',
			content: 'We need to refresh this page to load the latest updates.',
			centered: true,
			closable: false,
			maskClosable: false,
			keyboard: false,
			onOk: () => {
				const $customEvent = $event as CustomEvent<{
					registration: ServiceWorkerRegistration;
				}>;
				if ($customEvent?.detail?.registration) {
					this.props.updateServiceWorker($customEvent.detail.registration);
				}
			}
		});
	}

	render() {
		return (
			<Provider store={ReduxStore}>
				<PersistGate loading={<Loader />} persistor={Persistor}>
					<BrowserRouter>
						<AppBoundary>
							<Suspense fallback={<Loader />}>
								<Switch>
									{/* Static Website Pages */}
									<DefaultRoute path={AppRoutes.Website} exact={true} component={WebsitePage} />
									<DefaultRoute path={AppRoutes.Privacy} exact={true} component={PrivacyPolicyPage} />
									<DefaultRoute path={AppRoutes.TermsConditions} exact={true} component={TermsConditionsPage} />
									<DefaultRoute path={AppRoutes.Cookie} exact={true} component={CookiePolicyPage} />
									<DefaultRoute path={AppRoutes.Disclaimer} exact={true} component={DisclaimerPage} />
									<DefaultRoute path={AppRoutes.Team} exact={true} component={TeamPage} />
									<DefaultRoute path={AppRoutes.About} exact={true} component={AboutPage} />
									<DefaultRoute path={AppRoutes.Pricing} exact={true} component={PricingPage} />
									<DefaultRoute path={AppRoutes.Features} exact={true} component={FeaturesPage} />
									{/* Contact Form Pages */}
									<DefaultRoute path={AppRoutes.ContactUs} exact={true} component={ContactUsPage} />
									<DefaultRoute path={AppRoutes.BugReport} exact={true} component={BugReportPage} />
									<DefaultRoute path={AppRoutes.FeatureRequest} exact={true} component={FeatureRequestPage} />
									{/* Error Pages */}
									<DefaultRoute path={AppRoutes.Error} exact={true} component={ErrorPage} />
									{/* Login Pages */}
									<AuthenticationRoute path={AppRoutes.Login} exact={true} component={LoginPage} />
									<AuthenticationRoute path={AppRoutes.Signup} exact={true} component={SignupPage} />
									<AuthenticationRoute path={AppRoutes.ForgotPassword} exact={true} component={ForgotPasswordPage} />
									{/* Dashboard Pages */}
									<PrivateRoute path={AppRoutes.Home} exact={true} component={HomePage} />
									<PrivateRoute path={AppRoutes.Calendar} exact={true} component={CalendarPage} />
									<PrivateRoute path={AppRoutes.Accounts} exact={true} component={AccountsPage} />
									<PrivateRoute path={AppRoutes.Settings} exact={true} component={SettingsPage} />
									<PrivateRoute path={AppRoutes.CreatePost} exact={true} component={CreatePostPage} />
									{/* Admin Pages */}
									<PrivateRoute path={AppRoutes.Admin} exact={true} component={AdminPage} />
									<Redirect to={AppRoutes.Website} />
								</Switch>
							</Suspense>
						</AppBoundary>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		);
	}

}

export default App;
