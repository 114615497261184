import axios, { AxiosResponse } from 'axios';

import { getRequest, postRequest } from '../Backend';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getFirestore as firebaseGetFirestore, collection as firestoreCollection, query as firebaseQuery, doc as firestoreDocument, getDoc as firestoreGetDocument, getDocs as firestoreGetDocuments, setDoc as firestoreSetDocument, where as firestoreWhere, orderBy as firestoreOrderBy, limit as firestoreLimit, startAfter as firestoreStartAfter, endBefore as firestoreEndBefore, limitToLast as firestoreLimitToLast, QueryConstraint as FirestoreQueryConstraint } from 'firebase/firestore/lite';

import { DefaultErrorMessage, BillingPlanData, UserDeviceInformation } from '../../Contracts';
import { FireStoreCollections } from '../../Contracts/Util/Firebase';
import { UserConverter, UserAggregatesUnionConverter, InvoiceCountAggregatesConverter, BillingPlanConverter, ChargebeeSubscriptionConverter, ChargebeeInvoiceConverter } from '../../Contracts/Util/Converter';
import { UserData, UserAggregates, SocialPlatformAggregates, SocialPlatformPostStatusAggregates, InvoiceCountAggregates, TransactionCountAggregates, EmptyUserAggregates, ChargebeeHostedPage, UserBillingPlanData, ChargebeeHostedPageAcknowledgementContent, ChargebeeInvoice, ChargeebeeInvoiceRequest, ChargebeeDownloadInvoiceResponse } from '../../Contracts/User';

const getUserDeviceInformationUrl = '/user/device/information';
const getBillingPlansUrl = '/staticWebsite/pricing/plans';
const getChargebeeHostedPageUrl = '/chargebee/subscription/check';
const getChargebeeCardUpdateUrl = '/chargebee/subscription/card/update';
const downloadChargebeeInvoiceUrl = '/chargebee/invoice/download?invoice_id={invoice_id}';

export const getUserDeviceInformation = async (userAgents: string[]): Promise<UserDeviceInformation[]> => {
	try {
		if (!userAgents || userAgents?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const getUserDeviceInformationResponse: AxiosResponse<UserDeviceInformation[]> = await axios(await postRequest(getUserDeviceInformationUrl, { userAgents }, true, false, false, false, {}));
		return getUserDeviceInformationResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getUser = async (userId: string): Promise<UserData> => {
	try {
		if (!userId || userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Users, userId).withConverter(UserConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as UserData;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getUserAggregates = async (userId: string): Promise<UserAggregates> => {
	try {
		if (!userId || userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.Users, userId, FireStoreCollections.Aggregates).withConverter(UserAggregatesUnionConverter)));
		if (!firebaseResponse || !firebaseResponse.docs) {
			return Promise.reject(DefaultErrorMessage);
		}
		let userAggregates: UserAggregates = { ...EmptyUserAggregates };
		firebaseResponse.docs.forEach((firebaseDocument) => {
			if (firebaseDocument.exists()) {
				if (firebaseDocument.id === FireStoreCollections.Accounts) {
					userAggregates = {
						...userAggregates,
						[FireStoreCollections.Accounts]: firebaseDocument.data() as SocialPlatformAggregates
					};
				} else if (firebaseDocument.id === FireStoreCollections.Posts) {
					userAggregates = {
						...userAggregates,
						[FireStoreCollections.Posts]: firebaseDocument.data() as SocialPlatformPostStatusAggregates
					};
				} else if (firebaseDocument.id === FireStoreCollections.Invoices) {
					userAggregates = {
						...userAggregates,
						[FireStoreCollections.Invoices]: firebaseDocument.data() as InvoiceCountAggregates
					};
				} else if (firebaseDocument.id === FireStoreCollections.Transactions) {
					userAggregates = {
						...userAggregates,
						[FireStoreCollections.Transactions]: firebaseDocument.data() as TransactionCountAggregates
					};
				}
			}
		});
		return userAggregates;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const editUser = async (editUserRequest: UserData): Promise<UserData> => {
	try {
		if (!editUserRequest?.id || editUserRequest?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		await firestoreSetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Users, editUserRequest.id).withConverter(UserConverter), editUserRequest, { merge: true });
		return await getUser(editUserRequest.id);
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getBillingPlans = async (fetchViaClientSdk: boolean): Promise<BillingPlanData[]> => {
	try {
		const billingPlanList = new Array<BillingPlanData>();
		if (fetchViaClientSdk) {
			const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
			const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.BillingPlans).withConverter(BillingPlanConverter), firestoreOrderBy('monthly_price', 'asc'), firestoreLimit(4)));
			if (!firebaseResponse || !firebaseResponse.docs) {
				return Promise.reject(DefaultErrorMessage);
			}
			if (!firebaseResponse.empty) {
				firebaseResponse.docs.forEach((firebaseDocument) => {
					if (firebaseDocument.exists()) {
						billingPlanList.push(firebaseDocument.data());
					}
				});
			}
		} else {
			const getBillingPlansResponse: AxiosResponse<BillingPlanData[]> = await axios(await getRequest(getBillingPlansUrl, null, true, false, false, false, {}));
			return getBillingPlansResponse.data;
		}
		return billingPlanList;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getChargebeeSubscription = async (userId: string): Promise<ChargebeeHostedPageAcknowledgementContent> => {
	try {
		if (!userId || userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Subscriptions, userId).withConverter(ChargebeeSubscriptionConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as ChargebeeHostedPageAcknowledgementContent;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getTotalInvoices = async (userId: string): Promise<InvoiceCountAggregates> => {
	try {
		if (!userId || userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Users, userId, FireStoreCollections.Aggregates, FireStoreCollections.Invoices).withConverter(InvoiceCountAggregatesConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as InvoiceCountAggregates;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getChargebeeInvoices = async (chargebeeInvoicesRequest: ChargeebeeInvoiceRequest): Promise<ChargebeeInvoice[]> => {
	try {
		if (!chargebeeInvoicesRequest?.userId || chargebeeInvoicesRequest?.userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firestoreConstraints: FirestoreQueryConstraint[] = new Array<FirestoreQueryConstraint>(firestoreWhere('customer_id', '==', chargebeeInvoicesRequest.userId), firestoreOrderBy('date', 'desc'), firestoreLimit(chargebeeInvoicesRequest.limit));
		if (chargebeeInvoicesRequest.firebaseFilters && chargebeeInvoicesRequest.firebaseFilters?.length > 0) {
			chargebeeInvoicesRequest.firebaseFilters?.forEach((firebaseFilter) => {
				if ((typeof firebaseFilter.value === 'string' && firebaseFilter.value?.length > 0) || (typeof firebaseFilter.value === 'object' && firebaseFilter.value?.length > 0) || (typeof firebaseFilter.value === 'number' && !isNaN(firebaseFilter.value))) {
					firestoreConstraints.push(firestoreWhere(firebaseFilter.fieldPath, firebaseFilter.opStr, firebaseFilter.value));
				}
			});
		}
		if (chargebeeInvoicesRequest.lastEntity) {
			if (chargebeeInvoicesRequest.navigation === 'next') {
				firestoreConstraints.push(firestoreStartAfter(chargebeeInvoicesRequest.lastEntity?.date));
			} else if (chargebeeInvoicesRequest.navigation === 'back') {
				firestoreConstraints.push(firestoreEndBefore(chargebeeInvoicesRequest.lastEntity?.date));
				firestoreConstraints.push(firestoreLimitToLast(chargebeeInvoicesRequest.limit));
			}
		}
		const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.Invoices).withConverter(ChargebeeInvoiceConverter), ...firestoreConstraints));
		if (!firebaseResponse || !firebaseResponse.docs) {
			return Promise.reject(DefaultErrorMessage);
		}
		const chargebeeInvoicesList = new Array<ChargebeeInvoice>();
		if (!firebaseResponse.empty) {
			firebaseResponse.docs.forEach((firebaseDocument) => {
				if (firebaseDocument.exists()) {
					chargebeeInvoicesList.push(firebaseDocument.data());
				}
			});
		}
		return chargebeeInvoicesList;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const downloadChargebeeInvoice = async (invoiceId: string): Promise<ChargebeeDownloadInvoiceResponse> => {
	try {
		if (!invoiceId || invoiceId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const downloadChargebeeInvoiceUrlResponse: AxiosResponse<ChargebeeDownloadInvoiceResponse> = await axios(await getRequest(downloadChargebeeInvoiceUrl.replace('{invoice_id}', invoiceId), null, true, false, false, false, {}));
		return downloadChargebeeInvoiceUrlResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getChargebeeHostedPage = async (userBillingPlan: UserBillingPlanData): Promise<ChargebeeHostedPage> => {
	try {
		if (!userBillingPlan?.id || userBillingPlan?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const chargebeeHostedPageResponse: AxiosResponse<ChargebeeHostedPage> = await axios(await postRequest(getChargebeeHostedPageUrl, userBillingPlan, true, false, false, false, {}));
		return chargebeeHostedPageResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getChargebeeUpdateCardPage = async (): Promise<ChargebeeHostedPage> => {
	try {
		const chargebeeHostedPageResponse: AxiosResponse<ChargebeeHostedPage> = await axios(await getRequest(getChargebeeCardUpdateUrl, null, true, false, false, false, {}));
		return chargebeeHostedPageResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};
