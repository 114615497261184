import { ReportHandler } from 'web-vitals';

const LogTag: string = 'Report Web Vitals';

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		try {
			import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
				getCLS(onPerfEntry);
				getFID(onPerfEntry);
				getFCP(onPerfEntry);
				getLCP(onPerfEntry);
				getTTFB(onPerfEntry);
			});
		} catch (error: any) {
			console.error(LogTag, 'ReportWebVitals', error);
		}
	}
};

export default reportWebVitals;
