import { Reducer } from 'redux';

import { LoginActionTypes, LoginActions } from '../../Actions/Login';

export interface LoginState {
	readonly isLoading: boolean;
	readonly isLoggedIn: boolean;
}

const initialState: LoginState = {
	isLoading: false,
	isLoggedIn: false
};

export const LoginReducer: Reducer<LoginState, LoginActions> = (
	state: LoginState = initialState,
	action: LoginActions
): LoginState => {
	switch (action.type) {
		case LoginActionTypes.SHOW_LOGIN_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case LoginActionTypes.HIDE_LOGIN_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case LoginActionTypes.LOGIN_REQUESTED: {
			return state;
		}
		case LoginActionTypes.LOGIN_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: true
			};
		}
		case LoginActionTypes.LOGIN_FAILED: {
			return {
				...state,
				isLoggedIn: false
			};
		}
		case LoginActionTypes.LOGOUT_SUCCESSFUL: {
			return {
				...state,
				isLoggedIn: false
			};
		}
		case LoginActionTypes.SIGNUP_REQUESTED: {
			return state;
		}
		case LoginActionTypes.SIGNUP_SUCCESSFUL: {
			return state;
		}
		case LoginActionTypes.SIGNUP_FAILED: {
			return state;
		}
		case LoginActionTypes.FORGOT_PASSWORD_REQUESTED: {
			return state;
		}
		case LoginActionTypes.FORGOT_PASSWORD_SUCCESSFUL: {
			return state;
		}
		case LoginActionTypes.FORGOT_PASSWORD_FAILED: {
			return state;
		}
		case LoginActionTypes.VERIFY_EMAIL_REQUESTED: {
			return state;
		}
		case LoginActionTypes.VERIFY_EMAIL_SUCCESSFUL: {
			return state;
		}
		case LoginActionTypes.VERIFY_EMAIL_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default LoginReducer;
