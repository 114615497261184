import { Reducer } from 'redux';

import { SettingsActiveTab } from '../../Contracts/Settings';

import { SettingsActionTypes, SettingsActions } from '../../Actions/Settings';

export interface SettingsState {
	readonly currentActiveTab: SettingsActiveTab;
}

const initialState: SettingsState = {
	currentActiveTab: SettingsActiveTab.Profile
};

export const SettingsReducer: Reducer<SettingsState, SettingsActions> = (
	state: SettingsState = initialState,
	action: SettingsActions
): SettingsState => {
	switch (action.type) {
		case SettingsActionTypes.CURRENT_ACTIVE_TAB_CHANGED: {
			return {
				...state,
				currentActiveTab: action.currentActiveTab
			};
		}
		default: {
			return state;
		}
	}
};

export default SettingsReducer;
