import { AxiosRequestConfig } from 'axios';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getAuth as firebaseGetAuth } from 'firebase/auth';

const LogTag: string = 'BackendService';

export const baseAppUrl: string | undefined = process.env.REACT_APP_API_HOST;

export const setHeaders = async (urlEncodedForm: boolean, multipartFormUpload: boolean, useToken: boolean, extraRequestHeaders: Record<string, string>) => {
	const firebaseUser = firebaseGetAuth(firebaseGetApp()).currentUser;
	let additionalHeaders: Record<string, string> = {};
	if (firebaseUser?.uid && useToken) {
		try {
			const token = await firebaseUser.getIdToken();
			additionalHeaders['Authorization'] = 'Bearer ' + token;
		} catch (error: any) {
			console.error(LogTag, 'SetHeaders', error);
		}
	}
	if (urlEncodedForm) {
		additionalHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
	} else if (multipartFormUpload) {
		additionalHeaders['Content-Type'] = 'multipart/form-data';
	} else {
		additionalHeaders['Content-Type'] = 'application/json';
		additionalHeaders['Accept'] = 'application/json';
	}
	additionalHeaders['Cache-Control'] = 'no-cache';
	if (extraRequestHeaders && Object.keys(extraRequestHeaders).length > 0) {
		additionalHeaders = {
			...additionalHeaders,
			...extraRequestHeaders
		};
	}
	return additionalHeaders;
};

export const getRequest = async (url: string, data: object | null, useToken: boolean, urlEncoded: boolean, isAbsoluteUrl: boolean, isFileDownload: boolean, extraRequestHeaders: Record<string, string>): Promise<AxiosRequestConfig> => {
	const options: AxiosRequestConfig = {
		method: 'GET',
		baseURL: isAbsoluteUrl ? '' : baseAppUrl,
		url: url
	};
	if (data) {
		options.data = data;
	}
	if (isFileDownload) {
		options.responseType = 'blob';
	}
	options.headers = await setHeaders(urlEncoded, false, useToken, extraRequestHeaders);
	return options;
};

export const postRequest = async (url: string, data: object | null, useToken: boolean, urlEncoded: boolean, isAbsoluteUrl: boolean, multipartFormUpload: boolean, extraRequestHeaders: Record<string, string>): Promise<AxiosRequestConfig> => {
	const options: AxiosRequestConfig = {
		method: 'POST',
		baseURL: isAbsoluteUrl ? '' : baseAppUrl,
		url: url
	};
	if (data) {
		options.data = data;
	}
	options.headers = await setHeaders(urlEncoded, multipartFormUpload, useToken, extraRequestHeaders);
	return options;
};

export const putRequest = async (url: string, data: object | null, useToken: boolean, urlEncoded: boolean, isAbsoluteUrl: boolean, multipartFormUpload: boolean, extraRequestHeaders: Record<string, string>): Promise<AxiosRequestConfig> => {
	const options: AxiosRequestConfig = {
		method: 'PUT',
		baseURL: isAbsoluteUrl ? '' : baseAppUrl,
		url: url
	};
	if (data) {
		options.data = data;
	}
	options.headers = await setHeaders(urlEncoded, multipartFormUpload, useToken, extraRequestHeaders);
	return options;
};

export const deleteRequest = async (url: string, data: object | null, useToken: boolean, urlEncoded: boolean, isAbsoluteUrl: boolean, extraRequestHeaders: Record<string, string>): Promise<AxiosRequestConfig> => {
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		baseURL: isAbsoluteUrl ? '' : baseAppUrl,
		url: url
	};
	if (data) {
		options.data = data;
	}
	options.headers = await setHeaders(urlEncoded, false, useToken, extraRequestHeaders);
	return options;
};

export const patchRequest = async (url: string, data: object | null, useToken: boolean, urlEncoded: boolean, isAbsoluteUrl: boolean, multipartFormUpload: boolean, extraRequestHeaders: Record<string, string>): Promise<AxiosRequestConfig> => {
	const options: AxiosRequestConfig = {
		method: 'PATCH',
		baseURL: isAbsoluteUrl ? '' : baseAppUrl,
		url: url
	};
	if (data) {
		options.data = data;
	}
	options.headers = await setHeaders(urlEncoded, multipartFormUpload, useToken, extraRequestHeaders);
	return options;
};
