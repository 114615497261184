import { Component, createRef as CreateRef } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Form, Typography, Row, Col, Input, Button, Spin, message as Message } from 'antd';
import { FormInstance } from 'antd/lib/form';

import ReCaptcha from 'react-google-recaptcha';

import './ContactFormPage.scss';

import { DefaultErrorMessage, GlobalState } from '../../../Contracts';
import { getServerErrorMessage } from '../../../Contracts/Util';
import { BugReportFormValues, BugReport, ContactFormType, ContactFormStatus } from '../../../Contracts/StaticWebsite';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

const LogTag: string = 'BugReportPage Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isStaticWebsiteLoading: state.staticWebsite.isLoading,
		userData: state.user.userData,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.showStaticWebsiteLoader()),
		hideStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.hideStaticWebsiteLoader()),
		bugReport: (bugReportRequest: BugReport) => dispatch(StaticWebsiteActionCreators.bugReport(bugReportRequest)),
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly formSubmitted: boolean;
}

export class BugReportPage extends Component<Props, State> {

	state: State = {
		formSubmitted: false
	};

	formRef = CreateRef<FormInstance>();
	reCaptchaRef = CreateRef<ReCaptcha>();

	bugReport = async (bugReportFormValues: BugReportFormValues) => {
		if (this.state.formSubmitted) {
			Message.info('We have received your request. Please give us some time to work on it before submitting another request', 3);
		} else {
			this.props.showStaticWebsiteLoader();
			try {
				const googleRecaptchaToken = await this.reCaptchaRef.current?.executeAsync();
				if (googleRecaptchaToken && googleRecaptchaToken?.length > 0) {
					const bugReportRequest: BugReport = {
						...bugReportFormValues,
						userId: this.props.userData?.id,
						type: ContactFormType.BugReport,
						status: ContactFormStatus.Open,
						googleRecaptchaToken
					};
					await this.props.bugReport(bugReportRequest);
					Message.success('Your request has been sent successfully', 3);
					this.props.hideStaticWebsiteLoader();
					this.formRef.current?.resetFields();
					this.setState({ formSubmitted: true });
				} else {
					Message.error(DefaultErrorMessage, 3);
				}
			} catch (error: any) {
				console.error(LogTag, 'BugReport', error);
				Message.error(getServerErrorMessage(error), 3);
			} finally {
				this.props.hideStaticWebsiteLoader();
			}
		}
	}

	render() {
		return (
			<Spin spinning={this.props.isStaticWebsiteLoading}>
				<Helmet>
					<title>Bug Report</title>
				</Helmet>
				<Form ref={this.formRef} layout="vertical" onFinish={(bugReportFormValues) => this.bugReport(bugReportFormValues as BugReportFormValues)} className="bug-report-form">
					<Typography>
						<Typography.Title level={1} className="contact-form-title">Bug report</Typography.Title>
						<Typography.Paragraph>You weren't expecting an error while creating a post, or getting stuck every time you submit a form? Please let us know using the contact form below. Or you can also write to us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
						<Typography.Paragraph>It could be an annoying bug sucking the lives of our customers or an issue with your individual account. We will look into the problem within a couple of days and let you know the truth.</Typography.Paragraph>
					</Typography>
					<Form.Item label="Name" name="name" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your name' }
					]}>
						<Input id="name" name="name" type="text" placeholder="Enter name" disabled={this.props.isStaticWebsiteLoading} autoComplete="name" />
					</Form.Item>
					<Form.Item label="Email" name="email" hasFeedback={true} rules={[
						{ required: true, type: 'email', whitespace: true, message: 'Please enter your email' }
					]}>
						<Input id="email" name="email" type="email" placeholder="Enter email" disabled={this.props.isStaticWebsiteLoading} autoComplete="email" />
					</Form.Item>
					<Form.Item label="Subject" name="subject" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter subject' }
					]}>
						<Input id="subject" name="subject" type="text" placeholder="Enter subject" disabled={this.props.isStaticWebsiteLoading} />
					</Form.Item>
					<Form.Item label="Message" name="message" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your message' }
					]}>
						<Input.TextArea id="message" name="message" placeholder="Enter message" disabled={this.props.isStaticWebsiteLoading} autoSize={{ minRows: 3 }} />
					</Form.Item>
					<div className="display-none">
						<ReCaptcha ref={this.reCaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string} theme={this.props.colorScheme} />
					</div>
					<div className="contact-form-button-container margin-top-32px">
						<Row justify="center" align="middle">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<Button type="primary" htmlType="submit" disabled={this.props.isStaticWebsiteLoading} loading={this.props.isStaticWebsiteLoading} className="contact-form-button">Submit</Button>
							</Col>
						</Row>
					</div>
				</Form>
			</Spin>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BugReportPage);
