import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import CookieConsent from 'react-cookie-consent';

import { Row, Col, Layout, Divider, Button } from 'antd';

import SocialIcon from '../UI/SocialIcon';

import { GlobalState, SocialPlatform, AppRoutes } from '../../../Contracts';
import { isLocalhost } from '../../../Contracts/Util';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapStateToProps = (state: GlobalState) => {
	return {
		userData: state.user.userData
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export class Footer extends Component<Props> {

	render() {
		return (
			<Layout.Footer className="static-website-page-footer">
				<Row justify="space-between" gutter={[24, 48]} className="align-left">
					<Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} className="footer-title-container">
						<div className="footer-title margin-bottom-36px">{process.env.REACT_APP_COMPANY_NAME}</div>
						<div className="footer-social-links-container margin-bottom-48px">
							<a href={process.env.REACT_APP_COMPANY_FACEBOOK_PROFILE} target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
								<SocialIcon platform={SocialPlatform.Facebook} className="margin-right-24px" />
							</a>
							<a href={process.env.REACT_APP_COMPANY_TWITTER_PROFILE} target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
								<SocialIcon platform={SocialPlatform.Twitter} className="margin-right-24px" />
							</a>
							<a href={process.env.REACT_APP_COMPANY_LINKEDIN_PROFILE} target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
								<SocialIcon platform={SocialPlatform.LinkedIn} className="margin-right-24px" />
							</a>
							<a href={process.env.REACT_APP_COMPANY_INSTAGRAM_PROFILE} target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
								<SocialIcon platform={SocialPlatform.Instagram} className="margin-right-24px" />
							</a>
						</div>
						<div className="footer-subtitle margin-bottom-12px">{process.env.REACT_APP_COMPANY_LEGAL_NAME} &copy; {(new Date().getFullYear())}. All rights reserved.</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
						<Row justify="space-between" gutter={[24, 48]}>
							<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
								<div className="footer-links-container">
									<div className="footer-link-title">
										Product
									</div>
									<Divider />
									<div className="footer-link">
										<Link to={AppRoutes.Login} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Login)}>Sign Up</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.Pricing} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Pricing)}>Pricing</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.Features} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Features)}>Features</Link>
									</div>
								</div>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
								<div className="footer-links-container">
									<div className="footer-link-title">
										Company
									</div>
									<Divider />
									<div className="footer-link">
										<Link to={AppRoutes.About} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.About)}>About Us</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.Team} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Team)}>Our Team</Link>
									</div>
								</div>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
								<div className="footer-links-container">
									<div className="footer-link-title">
										Support
									</div>
									<Divider />
									<div className="footer-link">
										<Link to={AppRoutes.ContactUs} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.ContactUs)}>Contact Us</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.BugReport} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.BugReport)}>Bug Report</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.FeatureRequest} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.FeatureRequest)}>Feature Request</Link>
									</div>
								</div>
							</Col>
							<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
								<div className="footer-links-container">
									<div className="footer-link-title">
										Legal
									</div>
									<Divider />
									<div className="footer-link">
										<Link to={AppRoutes.Disclaimer} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Disclaimer)}>Disclaimer</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.Privacy} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Privacy)}>Privacy Policy</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.TermsConditions} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.TermsConditions)}>Terms</Link>
									</div>
									<div className="footer-link">
										<Link to={AppRoutes.Cookie} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Cookie)}>Cookie Policy</Link>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				{(!isLocalhost(this.props.userData?.email)) && (
					<CookieConsent location="bottom" overlay={false} hideOnAccept={true} buttonText="Accept" cookieName={`${process.env.REACT_APP_COMPANY_NAME?.toLowerCase()}-cookie`} expires={365} sameSite="strict" cookieSecurity={true} enableDeclineButton={false} disableStyles={true} ButtonComponent={Button} containerClasses="cookie-consent-container" contentClasses="cookie-consent-content-container" buttonWrapperClasses="cookie-consent-button-container" buttonClasses="cookie-consent-button ant-btn-primary" debug={false} ariaAcceptLabel="Accept Cookies">This website uses cookies to enhance the user experience. By using {process.env.REACT_APP_COMPANY_NAME}, you accept our <Link to={AppRoutes.Cookie}>use of cookies</Link></CookieConsent>
				)}
			</Layout.Footer>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
