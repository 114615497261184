import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Row, Col, Card, Button, Radio, Divider, Typography, message as Message } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@ant-design/icons';

import './WebsitePage.scss';

import Image from '../Util/UI/Image';
import PricingTable from '../Util/UI/Tables/Pricing';

import { GlobalState, BillingPlanCycle, AppRoutes } from '../../Contracts';
import { capitalizeFirstLetter, getServerErrorMessage } from '../../Contracts/Util';

import UserActionCreators from '../../Actions/User';
import StaticWebsiteActionCreators from '../../Actions/StaticWebsite';

const LogTag: string = 'WebsitePage Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		billingPlanList: state.user.billingPlanList,
		browserWindowWidth: state.util.browserWindowWidth,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		getBillingPlans: (fetchViaClientSdk: boolean) => dispatch(UserActionCreators.getBillingPlans(fetchViaClientSdk)),
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageSection: (sectionName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageSection(sectionName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly billingCycle: BillingPlanCycle;
}

export class WebsitePage extends Component<Props, State> {

	state: State = {
		billingCycle: BillingPlanCycle.Monthly
	};

	componentDidMount() {
		this.props.loadStaticWebsitePageLink(window.location.pathname + window.location.search);
		this.getBillingPlans(true);
	}

	getBillingPlans = async (fetchViaClientSdk: boolean) => {
		try {
			await this.props.getBillingPlans(fetchViaClientSdk);
		} catch (error: any) {
			console.error(LogTag, 'GetBillingPlans', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	onBillingCycleChanged = ($event: RadioChangeEvent) => {
		this.setState({ billingCycle: $event.target.value as BillingPlanCycle });
	}

	render() {
		return (
			<div className="static-website-home-page-container">
				<Helmet>
					<title>{process.env.REACT_APP_COMPANY_NAME}</title>
				</Helmet>
				<section className="website-section website-section-banner-container">
					<div className="website-section-banner-overlay" />
					{(this.props.browserWindowWidth >= 768) && (
						<div className="animated-icons">
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon1.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon1.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon2.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon2.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon3.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon3.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon4.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon4.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon5.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon5.png`]} alt="animated-icon" />
							</div>
						</div>
					)}
					<Row justify="space-between" align="middle" className="website-section-banner">
						<Col xs={24} sm={24} md={12} lg={{ span: 10, offset: 2 }} xl={{ span: 10, offset: 3 }} xxl={{ span: 8, offset: 6 }} className="website-section-banner-column">
							<div className="website-section-banner-title-container">
								<Typography.Title level={1} className="website-section-banner-title">{process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
								<Divider className="website-section-banner-title-divider" />
								<Typography.Title level={2} className="website-section-banner-subtitle">{process.env.REACT_APP_COMPANY_TAGLINE}</Typography.Title>
								<Typography.Title level={3} className="website-section-banner-description">{process.env.REACT_APP_COMPANY_DESCRIPTION}</Typography.Title>
							</div>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={11} xxl={10} className="website-section-banner-column">
							<div className="website-section-banner-image-container">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/WebsiteBanner.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/WebsiteBanner.png`]} alt="banner" className="website-section-banner-image responsive-image" />
							</div>
						</Col>
					</Row>
				</section>
				<Divider className="section-divider" />
				<section className="website-section website-section-learn-container">
					<Row justify="center" align="middle" className="website-section-learn">
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="website-section-learn-column">
							<div className="website-section-learn-title-container">
								<Typography.Title level={2} className="website-section-learn-title">{process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
								<Typography.Paragraph className="website-section-learn-subtitle">{process.env.REACT_APP_COMPANY_BIO}</Typography.Paragraph>
								<Button type="primary" htmlType="button">
									<Link to={AppRoutes.Login} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Login)}>Sign Up</Link>
								</Button>
							</div>
						</Col>
					</Row>
				</section>
				<Divider className="section-divider" />
				<section className="website-section website-section-feature-container" id="features">
					<div className="left-shape" />
					<Row justify="space-between" align="middle" className="website-section-feature">
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-column">
							<div className="website-section-feature-image-container">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner2.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner2.png`]} alt="feature" className="website-section-feature-image responsive-image" />
							</div>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-column">
							<div className="website-section-feature-title-container">
								<Typography.Title level={2} className="website-section-feature-title">No more spreadsheets.</Typography.Title>
								<div className="website-section-feature-subtitle">Now, with {process.env.REACT_APP_COMPANY_NAME}, you can manage all your social media organic posts from one platform.
									<ul className="margin-top-24px">
										<li>List View &amp; Calendar View</li>
										<li>Post Overview</li>
										<li>Automated publishing for Facebook, Twitter, &amp; LinkedIn</li>
										<li>Notifications (email, push)</li>
									</ul>
								</div>
							</div>
						</Col>
					</Row>
				</section>
				<Divider className="section-divider" />
				<section className="website-section website-section-feature-container">
					<div className="right-shape" />
					<Row justify="space-between" align="middle" className="website-section-feature">
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="website-section-feature-column">
							<div className="website-section-feature-image-container">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner1.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner1.png`]} alt="feature" className="website-section-feature-image responsive-image" />
							</div>
						</Col>
						<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="website-section-feature-column">
							<div className="website-section-feature-title-container">
								<Typography.Title level={2} className="website-section-feature-title">Create beautiful content.</Typography.Title>
								<div className="website-section-feature-subtitle">{process.env.REACT_APP_COMPANY_NAME} helps you draft amazing posts.
									<ul className="margin-top-24px">
										<li>Connect multiple accounts</li>
										<li>Posts with photos, videos, gifs, and links</li>
										<li>Rich text editor</li>
										<li>Post preview</li>
									</ul>
								</div>
							</div>
						</Col>
						<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-column">
							<div className="website-section-feature-title-container">
								<Typography.Title level={2} className="website-section-feature-title">Create beautiful content.</Typography.Title>
								<div className="website-section-feature-subtitle">{process.env.REACT_APP_COMPANY_NAME} helps you draft amazing posts.
									<ul className="margin-top-24px">
										<li>Connect multiple accounts</li>
										<li>Posts with photos, videos, gifs, and links</li>
										<li>Rich text editor</li>
										<li>Post preview</li>
									</ul>
								</div>
							</div>
						</Col>
						<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-column">
							<div className="website-section-feature-image-container">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner1.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner1.png`]} alt="feature" className="website-section-feature-image responsive-image" />
							</div>
						</Col>
					</Row>
				</section>
				<Divider className="section-divider" />
				<section className="website-section website-section-pricing-container" id="pricing">
					<div className="website-section-pricing-overlay" />
					{(this.props.browserWindowWidth >= 768) && (
						<div className="animated-icons">
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon1.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon1.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon2.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon2.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon3.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon3.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon4.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon4.png`]} alt="animated-icon" />
							</div>
							<div className="icon">
								<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon5.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/AnimatedIcon5.png`]} alt="animated-icon" />
							</div>
						</div>
					)}
					<div className="website-section-pricing">
						<div className="website-section-pricing-card-container">
							<Row justify="center" align="middle" className="website-section-pricing-card-row">
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="website-section-pricing-card-column margin-bottom-36px">
									<div className="website-section-pricing-title-container">
										<h2 className="website-section-pricing-title">Pricing</h2>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="website-section-pricing-card-column margin-bottom-24px">
									<div className="website-section-pricing-radio-container">
										<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/website/SaveYearlyRibbon.svg`} alt="save-yearly" className="save-yearly" />
										<Radio.Group value={this.state.billingCycle} onChange={this.onBillingCycleChanged}>
											<Radio.Button value={BillingPlanCycle.Yearly}>{capitalizeFirstLetter(BillingPlanCycle.Yearly)}</Radio.Button>
											<Radio.Button value={BillingPlanCycle.Monthly}>{capitalizeFirstLetter(BillingPlanCycle.Monthly)}</Radio.Button>
										</Radio.Group>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
									<Row justify="space-around" align="middle" gutter={[36, 36]}>
										{(this.props.billingPlanList?.length > 0) && (
											this.props.billingPlanList.map((billingPlan, billingPlanIndex) => {
												return (
													<Col key={billingPlanIndex} xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} className="website-section-pricing-card-column">
														<Card bordered={false} className="website-section-pricing-card">
															<Card.Meta title={
																<Fragment>
																	<div className="website-section-pricing-card-title">{billingPlan.plan_name}</div>
																	<div className="website-section-pricing-card-icon">
																		<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${billingPlan?.id}.svg`} alt="billing-plan" />
																	</div>
																	<div className="website-section-pricing-card-price">
																		<span className="website-section-pricing-card-amount">$ {this.state.billingCycle === BillingPlanCycle.Yearly ? billingPlan.yearly_price : billingPlan.monthly_price}</span>
																		<span className="website-section-pricing-card-unit"> / {this.state.billingCycle === BillingPlanCycle.Yearly ? 'year' : 'month'}</span>
																	</div>
																	<Divider />
																</Fragment>
															} description={
																<div className="margin-top-48px">
																	{(billingPlan.features?.length > 0) && (
																		billingPlan.features.map((feature, featureIndex) => {
																			return (
																				<div key={featureIndex} className={featureIndex === 0 ? '' : 'margin-top-12px'}>
																					<CheckCircleOutlinedIcon className="check-icon margin-right-8px" />
																					<span>{feature}</span>
																				</div>
																			);
																		})
																	)}
																</div>
															} />
														</Card>
													</Col>
												);
											})
										)}
									</Row>
								</Col>
								<Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
									<PricingTable billingPlanList={this.props.billingPlanList} billingCycle={this.state.billingCycle} />
								</Col>
							</Row>
						</div>
					</div>
				</section>
				<Divider className="section-divider" />
				<section className="website-section website-section-feature-card-container">
					<div className="website-section-feature-card-overlay" />
					<div className="website-section-feature-card">
						<Row gutter={[36, 36]}>
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="website-section-feature-card-column">
								<div className="website-section-feature-card-title-container">
									<h2 className="website-section-feature-card-title">Features</h2>
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-card-column">
								{(this.props.browserWindowWidth >= 768) && (
									<Card
										bordered={false}
										className="website-section-feature-card-image-container"
										cover={
											<Fragment>
												<div>Automated posts</div>
												<Divider />
											</Fragment>
										}
									>
										<Card.Meta title="Connect your favourite social media accounts, and start scheduling content right away." />
									</Card>
								)}
								{(this.props.browserWindowWidth < 768) && (
									<Card bordered={false} className="website-section-feature-card-image-container">
										<Card.Meta title={
											<Fragment>
												<div>Automated posts</div>
												<Divider />
											</Fragment>
										} description="Connect your favourite social media accounts, and start scheduling content right away." />
									</Card>
								)}
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-card-column">
								{(this.props.browserWindowWidth >= 768) && (
									<Card
										bordered={false}
										className="website-section-feature-card-image-container"
										cover={
											<Fragment>
												<div>Plan your calendar</div>
												<Divider />
											</Fragment>
										}
									>
										<Card.Meta title="See all your social media posts across all your platforms in one convenient calendar view." />
									</Card>
								)}
								{(this.props.browserWindowWidth < 768) && (
									<Card bordered={false} className="website-section-feature-card-image-container">
										<Card.Meta title={
											<Fragment>
												<div>Plan your calendar</div>
												<Divider />
											</Fragment>
										} description="See all your social media posts across all your platforms in one convenient calendar view." />
									</Card>
								)}
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-card-column">
								{(this.props.browserWindowWidth >= 768) && (
									<Card
										bordered={false}
										className="website-section-feature-card-image-container"
											cover={
											<Fragment>
												<div>Post previews</div>
												<Divider />
											</Fragment>
										}
									>
										<Card.Meta title="You can see exactly how your published post would look like, from the schedule page itself." />
									</Card>
								)}
								{(this.props.browserWindowWidth < 768) && (
									<Card bordered={false} className="website-section-feature-card-image-container">
										<Card.Meta title={
											<Fragment>
												<div>Post previews</div>
												<Divider />
											</Fragment>
										} description="You can see exactly how your published post would look like, from the schedule page itself." />
									</Card>
								)}
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="website-section-feature-card-column">
								{(this.props.browserWindowWidth >= 768) && (
									<Card
										bordered={false}
										className="website-section-feature-card-image-container"
										cover={
											<Fragment>
												<div>Measure your success</div>
												<Divider />
											</Fragment>
										}
									>
										<Card.Meta title="Be notified when your content is published, and use analytics to see what's working." />
									</Card>
								)}
								{(this.props.browserWindowWidth < 768) && (
									<Card bordered={false} className="website-section-feature-card-image-container">
										<Card.Meta title={
											<Fragment>
												<div>Measure your success</div>
												<Divider />
											</Fragment>
										} description="Be notified when your content is published, and use analytics to see what's working." />
									</Card>
								)}
							</Col>
						</Row>
					</div>
				</section>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WebsitePage);
