import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Row, Col, Card, Button, Radio, Divider, Typography, message as Message } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@ant-design/icons';

import './PricingPage.scss';

import Image from '../../Util/UI/Image';
import PricingTable from '../../Util/UI/Tables/Pricing';

import { GlobalState, AppRoutes, BillingPlanCycle } from '../../../Contracts';
import { capitalizeFirstLetter, getServerErrorMessage } from '../../../Contracts/Util';

import UserActionCreators from '../../../Actions/User';
import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

const LogTag: string = 'PricingPage Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		billingPlanList: state.user.billingPlanList
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		getBillingPlans: (fetchViaClientSdk: boolean) => dispatch(UserActionCreators.getBillingPlans(fetchViaClientSdk)),
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly billingCycle: BillingPlanCycle;
}

export class PricingPage extends Component<Props, State> {

	state: State = {
		billingCycle: BillingPlanCycle.Monthly
	};

	componentDidMount() {
		this.props.loadStaticWebsitePageLink(window.location.pathname + window.location.search);
		this.getBillingPlans(true);
	}

	getBillingPlans = async (fetchViaClientSdk: boolean) => {
		try {
			await this.props.getBillingPlans(fetchViaClientSdk);
		} catch (error: any) {
			console.error(LogTag, 'GetBillingPlans', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	onBillingCycleChanged = ($event: RadioChangeEvent) => {
		this.setState({ billingCycle: $event.target.value as BillingPlanCycle });
	}

	render() {
		return (
			<div className="static-website-pricing-page-container">
				<Helmet>
					<title>Pricing</title>
				</Helmet>
				<Typography.Title level={1} className="pricing-page-title">Pricing</Typography.Title>
				<Typography.Title level={2} className="pricing-page-subtitle">Amazing features, at an affordable price</Typography.Title>
				<div className="pricing-table-container">
					<div className="pricing-radio-container">
						<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/website/SaveYearlyRibbon.svg`} alt="save-yearly" className="save-yearly" />
						<Radio.Group value={this.state.billingCycle} onChange={this.onBillingCycleChanged}>
							<Radio.Button value={BillingPlanCycle.Yearly}>{capitalizeFirstLetter(BillingPlanCycle.Yearly)}</Radio.Button>
							<Radio.Button value={BillingPlanCycle.Monthly}>{capitalizeFirstLetter(BillingPlanCycle.Monthly)}</Radio.Button>
						</Radio.Group>
					</div>
					<Row justify="center" align="middle">
						<Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
							<Row justify="center" align="middle" gutter={[36, 36]} className="pricing-card-container">
								{(this.props.billingPlanList?.length > 0) && (
									this.props.billingPlanList.map((billingPlan, billingPlanIndex) => {
										return (
											<Col key={billingPlanIndex} xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} className="pricing-card-column">
												<Card bordered={false} className="pricing-card">
													<Card.Meta title={
														<Fragment>
															<Typography.Text className="pricing-card-title">{billingPlan.plan_name}</Typography.Text>
															<div className="pricing-card-icon">
																<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${billingPlan?.id}.svg`} alt="billing-plan" />
															</div>
															<div className="pricing-card-price">
																<Typography.Text className="pricing-card-amount">$ {this.state.billingCycle === BillingPlanCycle.Yearly ? billingPlan.yearly_price : billingPlan.monthly_price}</Typography.Text>
																<Typography.Text className="pricing-card-unit"> / {this.state.billingCycle === BillingPlanCycle.Yearly ? 'year' : 'month'}</Typography.Text>
															</div>
															<Divider />
														</Fragment>
													} description={
														<div className="margin-top-48px">
															{(billingPlan.features?.length > 0) && (
																billingPlan.features.map((feature, featureIndex) => {
																	return (
																		<div key={featureIndex} className={featureIndex === 0 ? '' : 'margin-top-12px'}>
																			<CheckCircleOutlinedIcon className="check-icon margin-right-8px" />
																			<Typography.Text>{feature}</Typography.Text>
																		</div>
																	);
																})
															)}
														</div>
													} />
												</Card>
											</Col>
										);
									})
								)}
							</Row>
						</Col>
						<Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
							<PricingTable billingPlanList={this.props.billingPlanList} billingCycle={this.state.billingCycle} />
						</Col>
					</Row>
					<Row justify="space-between" gutter={[72, 48]} className="actions-row">
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="actions-column">
							<div className="action-title-container">
								<Typography.Paragraph className="action-title">Like what you see?</Typography.Paragraph>
								<Typography.Paragraph className="action-subtitle">Now, with {process.env.REACT_APP_COMPANY_NAME}, you can manage all your social media organic posts from one platform</Typography.Paragraph>
							</div>
							<div className="action-button-container">
								<Button type="primary" htmlType="button">
									<Link to={AppRoutes.Login} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Login)}>Create Account</Link>
								</Button>
							</div>
							<Divider type="vertical" className="actions-divider" />
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="actions-column">
							<div className="action-title-container">
								<Typography.Paragraph className="action-title">Want to know more?</Typography.Paragraph>
								<Typography.Paragraph className="action-subtitle">{process.env.REACT_APP_COMPANY_NAME} has manu amazing features to help you build your brand and social media presence</Typography.Paragraph>
							</div>
							<div className="action-button-container">
								<Button type="default" htmlType="button">
									<Link to={AppRoutes.Features} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Features)}>Explore Features</Link>
								</Button>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
