import { Component, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Layout, Dropdown, Menu } from 'antd';

import { MenuOutlined as MenuOutlinedIcon } from '@ant-design/icons';

import Image from '../../Util/UI/Image';

import { GlobalState, AppRoutes, StaticWebsiteRoutes, AppRouteParams, AppRouteState } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState> { }

export const mapStateToProps = (state: GlobalState) => {
	return {
		browserWindowWidth: state.util.browserWindowWidth,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		navigateStaticWebsitePageSection: (sectionName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageSection(sectionName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly menuItem?: string;
}

export class Header extends Component<Props, State> {

	state: State = {
		menuItem: undefined
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (StaticWebsiteRoutes.includes(this.props.location.pathname as AppRoutes)) {
				this.scrollTop('smooth', null);
			}
			if (this.props.location.pathname !== AppRoutes.Website) {
				this.setState({ menuItem: undefined });
			} else {
				if (this.state.menuItem) {
					this.onMenuItemChanged(this.state.menuItem, null);
				}
			}
		}
	}

	scrollTop = (scrollBehaviour: 'smooth' | 'auto', redirectRoute: AppRoutes | null) => {
		window.scroll({
			top: 0,
			behavior: scrollBehaviour
		});
		if (redirectRoute && this.props.location.pathname !== redirectRoute) {
			this.props.history.push(redirectRoute);
		}
	}

	onMenuItemChanged = (menuItem: string, redirectRoute: AppRoutes | null) => {
		this.setState({ menuItem });
		const anchor = document.getElementById(menuItem);
		if (menuItem !== 'account' && anchor && this.props.location.pathname === AppRoutes.Website) {
			window.scroll({
				top: (anchor.offsetTop) - 64,
				behavior: 'smooth'
			});
			this.props.navigateStaticWebsitePageSection(menuItem);
		}
		if (redirectRoute && this.props.location.pathname !== redirectRoute) {
			this.props.history.push(redirectRoute);
		}
	}

	renderHeaderNavigationMenu = (mode?: 'horizontal' | 'vertical' | 'inline'): ReactElement => {
		return (
			<Menu theme={this.props.colorScheme} mode={mode} selectedKeys={this.state.menuItem ? [this.state.menuItem] : undefined}>
				{(this.props.location.pathname !== AppRoutes.Pricing) && (
					<Menu.Item key="pricing" onClick={() => this.onMenuItemChanged('pricing', AppRoutes.Website)}>Pricing</Menu.Item>
				)}
				{(this.props.location.pathname !== AppRoutes.Features) && (
					<Menu.Item key="features" onClick={() => this.onMenuItemChanged('features', AppRoutes.Website)}>Features</Menu.Item>
				)}
				<Menu.Item key="account" onClick={() => this.onMenuItemChanged('account', AppRoutes.Login)}>My Account</Menu.Item>
			</Menu>
		);
	}

	render() {
		return (
			<Layout.Header className="static-website-page-header">
				<div className="logo-container" onClick={() => this.scrollTop('smooth', AppRoutes.Website)}>
					<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/logo512.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/logo512.png`]} alt="logo" className="logo" />
					{process.env.REACT_APP_COMPANY_NAME}
				</div>
				{this.props.location.pathname !== AppRoutes.Error && (
					<div className="menu-container">
						{(this.props.browserWindowWidth >= 576) && (
							this.renderHeaderNavigationMenu('horizontal')
						)}
						{(this.props.browserWindowWidth < 576) && (
							<Dropdown trigger={['click', 'contextMenu']} getPopupContainer={(trigger) => trigger.parentNode as HTMLElement} overlayClassName="menu-dropdown" overlay={
								this.renderHeaderNavigationMenu('vertical')
							}>
								<MenuOutlinedIcon className="trigger" />
							</Dropdown>
						)}
					</div>
				)}
			</Layout.Header>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
