import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState } from '../../Contracts';
import { UserAggregates, UserCountAggregates, ChargebeeDownloadInvoiceResponse, ChargebeeInvoice, ChargebeeHostedPageAcknowledgementContent, ChargeebeeInvoiceRequest } from '../../Contracts/User';
import { UsersRequest, AdminUser } from '../../Contracts/Admin';

import { getUserAggregates, getChargebeeSubscription, getChargebeeInvoices, downloadChargebeeInvoice } from '../../Services/User';
import { getUsers, getTotalUsers } from '../../Services/Admin';

export enum AdminActionTypes {
	SHOW_ADMIN_LOADER = 'SHOW_ADMIN_LOADER',
	HIDE_ADMIN_LOADER = 'HIDE_ADMIN_LOADER',
	TOTAL_USERS_REQUESTED = 'TOTAL_USERS_REQUESTED',
	TOTAL_USERS_SUCCESSFUL = 'TOTAL_USERS_SUCCESSFUL',
	TOTAL_USERS_FAILED = 'TOTAL_USERS_FAILED',
	USERS_REQUESTED = 'USERS_REQUESTED',
	USERS_SUCCESSFUL = 'USERS_SUCCESSFUL',
	USERS_FAILED = 'USERS_FAILED',
	USER_AGGREGATES_REQUESTED = 'USER_AGGREGATES_REQUESTED',
	USER_AGGREGATES_SUCCESSFUL = 'USER_AGGREGATES_SUCCESSFUL',
	USER_AGGREGATES_FAILED = 'USER_AGGREGATES_FAILED',
	CHARGEBEE_SUBSCRIPTION_REQUESTED = 'CHARGEBEE_SUBSCRIPTION_REQUESTED',
	CHARGEBEE_SUBSCRIPTION_SUCCESSFUL = 'CHARGEBEE_SUBSCRIPTION_SUCCESSFUL',
	CHARGEBEE_SUBSCRIPTION_FAILED = 'CHARGEBEE_SUBSCRIPTION_FAILED',
	CHARGEBEE_INVOICES_REQUESTED = 'CHARGEBEE_INVOICES_REQUESTED',
	CHARGEBEE_INVOICES_SUCCESSFUL = 'CHARGEBEE_INVOICES_SUCCESSFUL',
	CHARGEBEE_INVOICES_FAILED = 'CHARGEBEE_INVOICES_FAILED',
	CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED = 'CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED',
	CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL = 'CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL',
	CHARGEBEE_INVOICE_DOWNLOAD_FAILED = 'CHARGEBEE_INVOICE_DOWNLOAD_FAILED'
}

export interface ShowAdminLoaderAction extends Action<AdminActionTypes.SHOW_ADMIN_LOADER> { }
export interface HideAdminLoaderAction extends Action<AdminActionTypes.HIDE_ADMIN_LOADER> { }
export interface TotalUsersRequestedAction extends Action<AdminActionTypes.TOTAL_USERS_REQUESTED> { }
export interface TotalUsersSuccessfulAction extends Action<AdminActionTypes.TOTAL_USERS_SUCCESSFUL> {
	readonly totalUsers: UserCountAggregates;
}
export interface TotalUsersFailedAction extends Action<AdminActionTypes.TOTAL_USERS_FAILED> { }
export interface UsersRequestedAction extends Action<AdminActionTypes.USERS_REQUESTED> { }
export interface UsersSuccessfulAction extends Action<AdminActionTypes.USERS_SUCCESSFUL> {
	readonly usersList: AdminUser[];
}
export interface UsersFailedAction extends Action<AdminActionTypes.USERS_FAILED> { }
export interface UserAggregatesRequestedAction extends Action<AdminActionTypes.USER_AGGREGATES_REQUESTED> { }
export interface UserAggregatesSuccessfulAction extends Action<AdminActionTypes.USER_AGGREGATES_SUCCESSFUL> {
	readonly userAggregates: UserAggregates;
	readonly userId: string;
}
export interface UserAggregatesFailedAction extends Action<AdminActionTypes.USER_AGGREGATES_FAILED> { }
export interface ChargebeeSubscriptionRequestedAction extends Action<AdminActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED> { }
export interface ChargebeeSubscriptionSuccessfulAction extends Action<AdminActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL> {
	readonly chargebeeSubscription: ChargebeeHostedPageAcknowledgementContent;
	readonly userId: string;
}
export interface ChargebeeSubscriptionFailedAction extends Action<AdminActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED> { }
export interface ChargebeeInvoicesRequestedAction extends Action<AdminActionTypes.CHARGEBEE_INVOICES_REQUESTED> { }
export interface ChargebeeInvoicesSuccessfulAction extends Action<AdminActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL> {
	readonly chargebeeInvoicesList: ChargebeeInvoice[];
	readonly userId: string;
}
export interface ChargebeeInvoicesFailedAction extends Action<AdminActionTypes.CHARGEBEE_INVOICES_FAILED> { }
export interface ChargebeeInvoiceDownloadRequestedAction extends Action<AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED> { }
export interface ChargebeeInvoiceDownloadSuccessfulAction extends Action<AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL> {
	readonly chargebeeInvoiceDownload: ChargebeeDownloadInvoiceResponse;
}
export interface ChargebeeInvoiceDownloadFailedAction extends Action<AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED> { }

export type AdminActions =
	| ShowAdminLoaderAction
	| HideAdminLoaderAction
	| TotalUsersRequestedAction
	| TotalUsersSuccessfulAction
	| TotalUsersFailedAction
	| UsersRequestedAction
	| UsersSuccessfulAction
	| UsersFailedAction
	| UserAggregatesRequestedAction
	| UserAggregatesSuccessfulAction
	| UserAggregatesFailedAction
	| ChargebeeSubscriptionRequestedAction
	| ChargebeeSubscriptionSuccessfulAction
	| ChargebeeSubscriptionFailedAction
	| ChargebeeInvoicesRequestedAction
	| ChargebeeInvoicesSuccessfulAction
	| ChargebeeInvoicesFailedAction
	| ChargebeeInvoiceDownloadRequestedAction
	| ChargebeeInvoiceDownloadSuccessfulAction
	| ChargebeeInvoiceDownloadFailedAction;

export interface AdminDispatch {
	readonly showAdminLoader: ActionCreator<ThunkAction<ShowAdminLoaderAction, GlobalState, null, ShowAdminLoaderAction>>;
	readonly hideAdminLoader: ActionCreator<ThunkAction<HideAdminLoaderAction, GlobalState, null, HideAdminLoaderAction>>;
	readonly getTotalUsers: ActionCreator<ThunkAction<Promise<TotalUsersSuccessfulAction>, GlobalState, null, TotalUsersSuccessfulAction>>;
	readonly getUsers: ActionCreator<ThunkAction<Promise<UsersSuccessfulAction>, GlobalState, UsersRequest, UsersSuccessfulAction>>;
	readonly getUserAggregates: ActionCreator<ThunkAction<Promise<UserAggregatesSuccessfulAction>, GlobalState, string, UserAggregatesSuccessfulAction>>;
	readonly getChargebeeSubscription: ActionCreator<ThunkAction<Promise<ChargebeeSubscriptionSuccessfulAction>, GlobalState, string, ChargebeeSubscriptionSuccessfulAction>>;
	readonly getChargebeeInvoices: ActionCreator<ThunkAction<Promise<ChargebeeInvoicesSuccessfulAction>, GlobalState, ChargeebeeInvoiceRequest, ChargebeeInvoicesSuccessfulAction>>;
	readonly downloadChargebeeInvoice: ActionCreator<ThunkAction<Promise<ChargebeeInvoiceDownloadSuccessfulAction>, GlobalState, string, ChargebeeInvoiceDownloadSuccessfulAction>>;
}

export const AdminActionCreators: AdminDispatch = {
	showAdminLoader: () => {
		return (dispatch: Dispatch): ShowAdminLoaderAction => {
			const showAdminLoaderAction: ShowAdminLoaderAction = {
				type: AdminActionTypes.SHOW_ADMIN_LOADER
			};
			return dispatch(showAdminLoaderAction);
		};
	},
	hideAdminLoader: () => {
		return (dispatch: Dispatch): HideAdminLoaderAction => {
			const hideAdminLoaderAction: HideAdminLoaderAction = {
				type: AdminActionTypes.HIDE_ADMIN_LOADER
			};
			return dispatch(hideAdminLoaderAction);
		};
	},
	getTotalUsers: () => {
		return async (dispatch: Dispatch): Promise<TotalUsersSuccessfulAction> => {
			const totalUsersRequestedAction: TotalUsersRequestedAction = {
				type: AdminActionTypes.TOTAL_USERS_REQUESTED
			};
			dispatch(totalUsersRequestedAction);
			try {
				const totalUsersResponse = await getTotalUsers();
				const totalUsersSuccessfulAction: TotalUsersSuccessfulAction = {
					type: AdminActionTypes.TOTAL_USERS_SUCCESSFUL,
					totalUsers: totalUsersResponse
				};
				return dispatch(totalUsersSuccessfulAction);
			} catch (error: any) {
				const totalUsersFailedAction: TotalUsersFailedAction = {
					type: AdminActionTypes.TOTAL_USERS_FAILED
				};
				dispatch(totalUsersFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getUsers: (usersRequest: UsersRequest) => {
		return async (dispatch: Dispatch): Promise<UsersSuccessfulAction> => {
			const usersRequestedAction: UsersRequestedAction = {
				type: AdminActionTypes.USERS_REQUESTED
			};
			dispatch(usersRequestedAction);
			try {
				const usersResponse = await getUsers(usersRequest);
				const usersSuccessfulAction: UsersSuccessfulAction = {
					type: AdminActionTypes.USERS_SUCCESSFUL,
					usersList: usersResponse
				};
				return dispatch(usersSuccessfulAction);
			} catch (error: any) {
				const usersFailedAction: UsersFailedAction = {
					type: AdminActionTypes.USERS_FAILED
				};
				dispatch(usersFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getUserAggregates: (userId: string) => {
		return async (dispatch: Dispatch): Promise<UserAggregatesSuccessfulAction> => {
			const userAggregatesRequestedAction: UserAggregatesRequestedAction = {
				type: AdminActionTypes.USER_AGGREGATES_REQUESTED
			};
			dispatch(userAggregatesRequestedAction);
			try {
				const userAggregatesResponse = await getUserAggregates(userId);
				const userAggregatesSuccessfulAction: UserAggregatesSuccessfulAction = {
					type: AdminActionTypes.USER_AGGREGATES_SUCCESSFUL,
					userAggregates: userAggregatesResponse,
					userId
				};
				return dispatch(userAggregatesSuccessfulAction);
			} catch (error: any) {
				const userAggregatesFailedAction: UserAggregatesFailedAction = {
					type: AdminActionTypes.USER_AGGREGATES_FAILED
				};
				dispatch(userAggregatesFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeSubscription: (userId: string) => {
		return async (dispatch: Dispatch): Promise<ChargebeeSubscriptionSuccessfulAction> => {
			const chargebeeSubscriptionRequestedAction: ChargebeeSubscriptionRequestedAction = {
				type: AdminActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED
			};
			dispatch(chargebeeSubscriptionRequestedAction);
			try {
				const chargebeeSubscriptionResponse = await getChargebeeSubscription(userId);
				const chargebeeSubscriptionSuccessfulAction: ChargebeeSubscriptionSuccessfulAction = {
					type: AdminActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL,
					chargebeeSubscription: chargebeeSubscriptionResponse,
					userId
				};
				return dispatch(chargebeeSubscriptionSuccessfulAction);
			} catch (error: any) {
				const chargebeeSubscriptionFailedAction: ChargebeeSubscriptionFailedAction = {
					type: AdminActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED
				};
				dispatch(chargebeeSubscriptionFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeInvoices: (chargebeeInvoicesRequest: ChargeebeeInvoiceRequest) => {
		return async (dispatch: Dispatch): Promise<ChargebeeInvoicesSuccessfulAction> => {
			const chargebeeInvoicesRequestedAction: ChargebeeInvoicesRequestedAction = {
				type: AdminActionTypes.CHARGEBEE_INVOICES_REQUESTED
			};
			dispatch(chargebeeInvoicesRequestedAction);
			try {
				const chargebeeInvoicesResponse = await getChargebeeInvoices(chargebeeInvoicesRequest);
				const chargebeeInvoicesSuccessfulAction: ChargebeeInvoicesSuccessfulAction = {
					type: AdminActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL,
					chargebeeInvoicesList: chargebeeInvoicesResponse,
					userId: chargebeeInvoicesRequest.userId
				};
				return dispatch(chargebeeInvoicesSuccessfulAction);
			} catch (error: any) {
				const chargebeeInvoicesFailedAction: ChargebeeInvoicesFailedAction = {
					type: AdminActionTypes.CHARGEBEE_INVOICES_FAILED
				};
				dispatch(chargebeeInvoicesFailedAction);
				return Promise.reject(error);
			}
		};
	},
	downloadChargebeeInvoice: (invoiceId: string) => {
		return async (dispatch: Dispatch): Promise<ChargebeeInvoiceDownloadSuccessfulAction> => {
			const chargebeeInvoiceDownloadRequestedAction: ChargebeeInvoiceDownloadRequestedAction = {
				type: AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED
			};
			dispatch(chargebeeInvoiceDownloadRequestedAction);
			try {
				const chargebeeInvoiceDownloadResponse = await downloadChargebeeInvoice(invoiceId);
				const chargebeeInvoiceDownloadSuccessfulAction: ChargebeeInvoiceDownloadSuccessfulAction = {
					type: AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL,
					chargebeeInvoiceDownload: chargebeeInvoiceDownloadResponse
				};
				return dispatch(chargebeeInvoiceDownloadSuccessfulAction);
			} catch (error: any) {
				const chargebeeInvoiceDownloadFailedAction: ChargebeeInvoiceDownloadFailedAction = {
					type: AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED
				};
				dispatch(chargebeeInvoiceDownloadFailedAction);
				return Promise.reject(error);
			}
		};
	}
};

export default AdminActionCreators;
