import { getApp as firebaseGetApp } from 'firebase/app';
import { getFirestore as firebaseGetFirestore, collection as firestoreCollection, query as firebaseQuery, doc as firestoreDocument, getDoc as firestoreGetDocument, getDocs as firestoreGetDocuments, where as firestoreWhere, orderBy as firestoreOrderBy, limit as firestoreLimit, startAfter as firestoreStartAfter, endBefore as firestoreEndBefore, limitToLast as firestoreLimitToLast, QueryConstraint as FirestoreQueryConstraint } from 'firebase/firestore/lite';

import { DefaultErrorMessage } from '../../Contracts';
import { FireStoreCollections } from '../../Contracts/Util/Firebase';
import { UserConverter, UserCountAggregatesConverter } from '../../Contracts/Util/Converter';
import { UserData, UserCountAggregates } from '../../Contracts/User';
import { UsersRequest, AdminUser } from '../../Contracts/Admin';

export const getTotalUsers = async (): Promise<UserCountAggregates> => {
	try {
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Aggregates, FireStoreCollections.Users).withConverter(UserCountAggregatesConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as UserCountAggregates;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getUsers = async (usersRequest: UsersRequest): Promise<AdminUser[]> => {
	try {
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firestoreConstraints: FirestoreQueryConstraint[] = new Array<FirestoreQueryConstraint>();
		if (usersRequest.firebaseFilters && usersRequest.firebaseFilters?.length > 0) {
			usersRequest.firebaseFilters?.forEach((firebaseFilter) => {
				if ((typeof firebaseFilter.value === 'string' && firebaseFilter.value?.length > 0) || (typeof firebaseFilter.value === 'object' && firebaseFilter.value?.length > 0) || (typeof firebaseFilter.value === 'number' && !isNaN(firebaseFilter.value))) {
					firestoreConstraints.push(firestoreWhere(firebaseFilter.fieldPath, firebaseFilter.opStr, firebaseFilter.value));
				}
			});
		}
		if (usersRequest.lastEntity) {
			if (usersRequest.navigation === 'next') {
				firestoreConstraints.push(firestoreStartAfter(usersRequest.lastEntity?.id));
			} else if (usersRequest.navigation === 'back') {
				firestoreConstraints.push(firestoreEndBefore(usersRequest.lastEntity?.id));
				firestoreConstraints.push(firestoreLimitToLast(usersRequest.limit));
			}
		}
		const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.Users).withConverter(UserConverter), ...firestoreConstraints, firestoreOrderBy('id', 'desc'), firestoreLimit(usersRequest.limit)));
		if (!firebaseResponse || !firebaseResponse.docs) {
			return Promise.reject(DefaultErrorMessage);
		}
		const usersList = new Array<AdminUser>();
		if (!firebaseResponse.empty) {
			firebaseResponse.docs.forEach((firebaseDocument) => {
				if (firebaseDocument.exists()) {
					const userData = firebaseDocument.data() as UserData;
					usersList.push(userData);
				}
			});
		}
		return usersList;
	} catch (error: any) {
		return Promise.reject(error);
	}
};
