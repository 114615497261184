import { FirestoreDataConverter, DocumentData as FirebaseFirestoreDocumentData, QueryDocumentSnapshot as FirebaseFirestoreQueryDocumentSnapshot, Timestamp as FirebaseFirestoreTimestamp } from 'firebase/firestore/lite';

import { BillingPlanData } from '../';
import { UserData, UserCountAggregates, SocialPlatformAggregates, SocialPlatformPostStatusAggregates, InvoiceCountAggregates, TransactionCountAggregates, UserAggregatesUnion, ChargebeeHostedPageAcknowledgementContent, ChargebeeInvoice, ChargebeeTransaction } from '../User';
import { Post } from '../Post';
import { OAuthAccount } from '../Accounts';
import { ContactUs, BugReport, FeatureRequest } from '../StaticWebsite';

export const UserConverter: FirestoreDataConverter<UserData> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as UserData;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): UserData {
		return snapshot.data() as UserData;
	}
};

export const UserCountAggregatesConverter: FirestoreDataConverter<UserCountAggregates> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as UserCountAggregates;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): UserCountAggregates {
		return snapshot.data() as UserCountAggregates;
	}
};

export const SocialPlatformAggregatesConverter: FirestoreDataConverter<SocialPlatformAggregates> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as SocialPlatformAggregates;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): SocialPlatformAggregates {
		return snapshot.data() as SocialPlatformAggregates;
	}
};

export const SocialPlatformPostStatusAggregatesConverter: FirestoreDataConverter<SocialPlatformPostStatusAggregates> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as SocialPlatformPostStatusAggregates;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): SocialPlatformPostStatusAggregates {
		return snapshot.data() as SocialPlatformPostStatusAggregates;
	}
};

export const InvoiceCountAggregatesConverter: FirestoreDataConverter<InvoiceCountAggregates> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as InvoiceCountAggregates;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): InvoiceCountAggregates {
		return snapshot.data() as InvoiceCountAggregates;
	}
};

export const TransactionCountAggregatesConverter: FirestoreDataConverter<TransactionCountAggregates> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as TransactionCountAggregates;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): TransactionCountAggregates {
		return snapshot.data() as TransactionCountAggregates;
	}
};

export const UserAggregatesUnionConverter: FirestoreDataConverter<UserAggregatesUnion> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as UserAggregatesUnion;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): UserAggregatesUnion {
		return snapshot.data() as UserAggregatesUnion;
	}
};

export const BillingPlanConverter: FirestoreDataConverter<BillingPlanData> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as BillingPlanData;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): BillingPlanData {
		return snapshot.data() as BillingPlanData;
	}
};

export const ChargebeeSubscriptionConverter: FirestoreDataConverter<ChargebeeHostedPageAcknowledgementContent> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as ChargebeeHostedPageAcknowledgementContent;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): ChargebeeHostedPageAcknowledgementContent {
		return snapshot.data() as ChargebeeHostedPageAcknowledgementContent;
	}
};

export const ChargebeeInvoiceConverter: FirestoreDataConverter<ChargebeeInvoice> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as ChargebeeInvoice;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): ChargebeeInvoice {
		return snapshot.data() as ChargebeeInvoice;
	}
};

export const ChargebeeTransactionConverter: FirestoreDataConverter<ChargebeeTransaction> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as ChargebeeTransaction;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): ChargebeeTransaction {
		return snapshot.data() as ChargebeeTransaction;
	}
};

export const ContactUsConverter: FirestoreDataConverter<ContactUs> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as ContactUs;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): ContactUs {
		return snapshot.data() as ContactUs;
	}
};

export const BugReportConverter: FirestoreDataConverter<BugReport> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		return data as BugReport;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): BugReport {
		return snapshot.data() as BugReport;
	}
};

export const FeatureRequestConverter: FirestoreDataConverter<FeatureRequest> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		if (data) {
			if (data.googleRecaptchaTokenResponse?.timestamp?.getTime) {
				data.googleRecaptchaTokenResponse.timestamp = FirebaseFirestoreTimestamp.fromDate(new Date(data.googleRecaptchaTokenResponse.timestamp));
			}
		}
		return data as FeatureRequest;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): FeatureRequest {
		const data = snapshot.data();
		if (data) {
			if (data.googleRecaptchaTokenResponse?.timestamp?.toDate) {
				data.googleRecaptchaTokenResponse.timestamp = data.googleRecaptchaTokenResponse.timestamp?.toDate();
			}
		}
		return data as FeatureRequest;
	}
};

export const PostConverter: FirestoreDataConverter<Post> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		if (data) {
			if (data.dateTime?.getTime) {
				data.dateTime = FirebaseFirestoreTimestamp.fromDate(data.dateTime);
			}
			if (data.publishSuccessfulDateTime?.getTime) {
				data.publishSuccessfulDateTime = FirebaseFirestoreTimestamp.fromDate(data.publishSuccessfulDateTime);
			}
			if (data.publishFailedDateTime?.getTime) {
				data.publishFailedDateTime = FirebaseFirestoreTimestamp.fromDate(data.publishFailedDateTime);
			}
			if (data.files?.length > 0) {
				data.files.forEach((file: FirebaseFirestoreDocumentData) => {
					if (file.lastModifiedDate?.getTime) {
						file.lastModifiedDate = FirebaseFirestoreTimestamp.fromDate(file.lastModifiedDate);
					}
				});
			}
		}
		return data as Post;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): Post {
		const data = snapshot.data();
		if (data) {
			if (data.dateTime?.toDate) {
				data.dateTime = data.dateTime?.toDate();
			}
			if (data.publishSuccessfulDateTime?.toDate) {
				data.publishSuccessfulDateTime = data.publishSuccessfulDateTime?.toDate();
			}
			if (data.publishFailedDateTime?.toDate) {
				data.publishFailedDateTime = data.publishFailedDateTime?.toDate();
			}
			if (data.files?.length > 0) {
				data.files.forEach((file: FirebaseFirestoreDocumentData) => {
					if (file.lastModifiedDate?.toDate) {
						file.lastModifiedDate = file.lastModifiedDate?.toDate();
					}
				});
			}
		}
		return data as Post;
	}
};

export const AccountConverter: FirestoreDataConverter<OAuthAccount> = {
	toFirestore(data: FirebaseFirestoreDocumentData): FirebaseFirestoreDocumentData {
		if (data) {
			if (data.token_expires_at?.getTime) {
				data.token_expires_at = FirebaseFirestoreTimestamp.fromDate(data.token_expires_at);
			}
			if (data.connect_timestamp?.getTime) {
				data.connect_timestamp = FirebaseFirestoreTimestamp.fromDate(data.connect_timestamp);
			}
		}
		return data as OAuthAccount;
	},
	fromFirestore(snapshot: FirebaseFirestoreQueryDocumentSnapshot): OAuthAccount {
		const data = snapshot.data();
		if (data) {
			if (data.token_expires_at?.toDate) {
				data.token_expires_at = data.token_expires_at?.toDate();
			}
			if (data.connect_timestamp?.toDate) {
				data.connect_timestamp = data.connect_timestamp?.toDate();
			}
		}
		return data as OAuthAccount;
	}
};
