import { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

import { Row, Col, Divider, Typography } from 'antd';

import { LockOutlined as LockOutlinedIcon } from '@ant-design/icons';

import '../AuthenticationPage.scss';

import Loader from '../../Util/UI/Loader';
import Image from '../../Util/UI/Image';
import ForgotPasswordForm from '../../Util/UI/Forms/Login/ForgotPasswordForm';

import { GlobalState, DefaultPrivateRoute, AppRoutes, AppRouteParams, AppRouteState } from '../../../Contracts';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isLoginLoading: state.login.isLoading
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapStateToProps> { }

export class ForgotPasswordPage extends Component<Props> {

	showLoginPage = () => {
		this.props.history.push(AppRoutes.Login);
	}

	onForgotPassword = () => {
		this.props.history.push((this.props.location.state?.from?.length > 0) ? this.props.location.state?.from : DefaultPrivateRoute, { from: this.props.location.pathname as AppRoutes });
	}

	render() {
		return (
			<div className="forgot-password-page-container">
				<Helmet>
					<title>Forgot Password</title>
				</Helmet>
				{this.props.isLoginLoading && <Loader />}
				<Row className="full-height">
					<Col xs={0} sm={0} md={14} lg={16} xl={18} xxl={18} className="forgot-password-banner-container">
						<div className="forgot-password-banner-title-container">
							<div className="forgot-password-banner" />
							<div className="right-shape" />
							<Typography.Title level={1} className="forgot-password-banner-title">{process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
							<Divider className="forgot-password-banner-title-divider" />
							<Typography.Text className="forgot-password-banner-subtitle">{process.env.REACT_APP_COMPANY_BIO}</Typography.Text>
							<Typography.Text className="forgot-password-banner-description">{process.env.REACT_APP_COMPANY_DESCRIPTION}</Typography.Text>
						</div>
					</Col>
					<Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={6} className="forgot-password-form-container">
						<Link to={AppRoutes.Website} className="forgot-password-form-logo margin-bottom-48px">
							<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/logo512.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/logo512.png`]} alt="logo" className="logo" />
							<Typography.Title level={2} className="text">{process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
						</Link>
						<div className="forgot-password-form-icon margin-bottom-32px">
							<LockOutlinedIcon />
						</div>
						<Typography.Text className="margin-bottom-24px forgot-password-form-header">Forgot Password</Typography.Text>
						<ForgotPasswordForm afterSubmit={this.showLoginPage} showLoginPage={this.showLoginPage} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default connect(mapStateToProps, {})(withRouter(ForgotPasswordPage));
