import { ReactNode } from 'react';

import { UploadFile } from 'antd/lib/upload/interface';

import { RootReducer } from '../ReduxStore';

import { PostFileResponse } from './Post';
import { SettingsActiveTab } from './Settings';

const LogTag: string = 'Contracts';

export interface AppWindow extends Window {
	readonly __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
	[key: string]: any;
	[key: number]: any;
}

interface SessionStorage extends Storage {
	readonly get: (key: string, parseJson: boolean) => any;
	readonly save: (key: string, data: any) => void;
	readonly deleteItem: (key: string) => void;
	readonly deleteAll: () => void;
}

class SessionStorage implements SessionStorage {
	readonly get = (key: string, parseJson: boolean): any => {
		let data: any = sessionStorage.getItem(key) || null;
		if (data && parseJson) {
			try {
				data = JSON.parse(data);
			} catch (error: any) {
				console.error(LogTag, 'SessionStorage Get', error);
				throw error;
			}
		}
		return data;
	}
	readonly save = (key: string, data: any) => {
		sessionStorage.setItem(key, JSON.stringify(data));
	}
	readonly deleteItem = (key: string) => {
		sessionStorage.removeItem(key);
	}
	readonly deleteAll = () => {
		sessionStorage.clear();
	}
}

export const AppSessionStorage: SessionStorage = new SessionStorage();

interface LocalStorage extends Storage {
	readonly get: (key: string, parseJson: boolean) => any;
	readonly save: (key: string, data: any) => void;
	readonly deleteItem: (key: string) => void;
	readonly deleteAll: () => void;
}

class LocalStorage implements LocalStorage {
	readonly get = (key: string, parseJson: boolean): any => {
		let data: any = localStorage.getItem(key) || null;
		if (data && parseJson) {
			try {
				data = JSON.parse(data);
			} catch (error: any) {
				console.error(LogTag, 'LocalStorage Get', error);
				throw error;
			}
		}
		return data;
	}
	readonly save = (key: string, data: any) => {
		localStorage.setItem(key, JSON.stringify(data));
	}
	readonly deleteItem = (key: string) => {
		localStorage.removeItem(key);
	}
	readonly deleteAll = () => {
		localStorage.clear();
	}
}

export const AppLocalStorage: LocalStorage = new LocalStorage();

export type GlobalState = ReturnType<typeof RootReducer>;

export const PasswordRegex: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;

export const AlphabetsOnlyRegex: RegExp = /^[a-zA-Z]+$/;

export const AlphaNumericWithDashesRegex: RegExp = /^[A-Za-z0-9_-]*$/;

export const DefaultErrorMessage = 'Something went wrong';

export const DefaultPaginationPageSize = 50;

export enum ColorScheme {
	Light = 'light',
	Dark = 'dark'
}

export enum AppRoutes {
	// Authentication
	Login = '/login',
	Signup = '/signup',
	ForgotPassword = '/forgot-password',
	// Dashboard
	Home = '/home',
	Calendar = '/calendar',
	Accounts = '/accounts',
	Settings = '/settings',
	CreatePost = '/post/create',
	// Error
	Error = '/error',
	// Static Website
	Website = '/',
	Disclaimer = '/disclaimer',
	Privacy = '/privacy',
	TermsConditions = '/terms',
	Cookie = '/cookie',
	Team = '/team',
	About = '/about',
	Pricing = '/pricing',
	Features = '/features',
	// Contact Form
	ContactUs = '/contact-us',
	BugReport = '/bug-report',
	FeatureRequest = '/feature-request',
	// Admin
	Admin = '/admin'
}

export const StaticWebsiteRoutes = [
	AppRoutes.Team,
	AppRoutes.About,
	AppRoutes.Pricing,
	AppRoutes.Features,
	AppRoutes.Disclaimer,
	AppRoutes.Privacy,
	AppRoutes.TermsConditions,
	AppRoutes.Cookie,
	AppRoutes.ContactUs,
	AppRoutes.BugReport,
	AppRoutes.FeatureRequest
];

export const DefaultPrivateRoute = AppRoutes.Home;

export interface AppRouteParams {
	readonly id: string;
}

export interface AppRouteState {
	readonly from: AppRoutes;
}

export enum AppStorageKeys {
	Token = 'token',
	FirebasePushNotificationsPrompt = 'firebase_push_notifications_prompt'
}

export interface AppErrorResponse extends Error {
	readonly error?: string;
}

export interface UploadFileRequest {
	readonly firebaseFilePath: string;
	readonly uploadFile: UploadFile<PostFileResponse>;
	readonly userId: string;
	readonly socialPlatform?: SocialPlatform;
}

export interface DeleteFileRequest extends Omit<UploadFileRequest, 'uploadFile'> {}

export interface Name {
	readonly first_name: string;
	readonly last_name: string;
	readonly full_name: string;
}

export interface Address {
	readonly address: string;
	readonly city: string;
	readonly country: string;
	readonly pincode: string;
	readonly state: string;
}

export enum SocialPlatform {
	Facebook = 'facebook',
	Twitter = 'twitter',
	Instagram = 'instagram',
	LinkedIn = 'linkedIn'
}

export class AppRouteHash {
	static readonly [SocialPlatform.Facebook] = SocialPlatform.Facebook;
	static readonly [SocialPlatform.Twitter] = SocialPlatform.Twitter;
	static readonly [SocialPlatform.Instagram] = SocialPlatform.Instagram;
	static readonly [SocialPlatform.LinkedIn] = SocialPlatform.LinkedIn;
	static readonly [SettingsActiveTab.Profile] = SettingsActiveTab.Profile;
	static readonly [SettingsActiveTab.Billing] = SettingsActiveTab.Billing;
	static readonly [SettingsActiveTab.Notification] = SettingsActiveTab.Notification;
	static readonly ChargebeeCheckoutPayment = 'checkout_payment';
	static readonly ChargebeeCheckoutCard = 'checkout_card';
	static readonly CreatePostDrawer = 'create_post';
	static readonly EditPostDrawer = 'edit_post';
	static readonly BillingPlanDrawer = 'billing_plan';
	static readonly FreshchatWidget = 'chat';
	static readonly DashboardSiderMenu = 'menu';
}

export enum BreakPoint {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
	xxl = 'xxl'
}

export enum CalendarView {
	Month = 'month-view',
	Agenda = 'agenda-view',
	MonthAgenda = 'month-agenda-view'
}

export enum BillingPlanCycle {
	Monthly = 'monthly',
	Yearly = 'yearly'
}

export enum BillingPlanIdentifier {
	FreePlan = 'w295ONhSlcdIZPBeJ9GI',
	BasicPlan = 'ufHAq4KRyrIoqLuFReI1',
	ProPlan = 'eldJl3tDnZd8l2YaY7TZ',
	AdvancedPlan = 'I8DayCmQUzA6sOx2oMqb'
}

export interface BillingPlanFormValues {
	readonly id: string;
	readonly billing_cycle: BillingPlanCycle;
}

export interface BillingPlanData {
	readonly id: string;
	readonly plan_name: string;
	readonly plan_description: string;
	readonly features: string[];
	readonly monthly_price: number;
	readonly yearly_price: number;
	readonly max_scheduled_posts: number;
	readonly max_social_accounts: number;
}

export interface BillingPlanTable {
	readonly key: string;
	readonly feature: ReactNode;
	readonly column1: ReactNode;
	readonly column2: ReactNode;
	readonly column3: ReactNode;
	readonly column4: ReactNode;
}

export interface UserDeviceInformation {
	readonly userAgent: string;
	readonly browser: string;
	readonly platform: string;
}

export interface FCMPushNotificationData extends Record<string, string | undefined> {
	readonly badge?: string;
	readonly icon?: string;
	readonly image?: string;
	readonly title?: string;
	readonly body?: string;
}

export interface FormFieldData {
	readonly name: string | number | (string | number)[];
	readonly value?: any;
	readonly touched?: boolean;
	readonly validating?: boolean;
	readonly errors?: string[];
}

export interface LinkPreview {
	'og:site_name'?: string;
	'og:updated_time'?: string;
	'og:title'?: string;
	'og:type'?: string;
	'og:description'?: string;
	'og:image'?: string;
	'twitter:card'?: string;
	'twitter:creator'?: string;
	'twitter:title'?: string;
	'twitter:description'?: string;
	'twitter:image:src'?: string;
	title?: string;
	description?: string;
	image?: string;
	favicon?: string;
	hostname?: string;
	port?: string;
	search?: string;
	pathname?: string;
	protocol?: string;
}

export interface BufferUrlScraperResponse {
	readonly title: string;
	readonly description: string;
	readonly twitterAttribution: string;
	readonly canonicalUrl: string;
	readonly images: {
		readonly url: string;
		readonly width: number;
		readonly height: number;
		readonly type: string;
		readonly opengraph?: boolean;
	}[];
}

export interface CheckDisposableEmailResponse {
	readonly status: number;
	readonly domain: string;
	readonly temporary: boolean;
	readonly dns: boolean;
}

export interface IpStackGeoLocationResponse {
	readonly ip: string;
	readonly type: string;
	readonly continent_code: string;
	readonly continent_name: string;
	readonly country_code: string;
	readonly country_name: string;
	readonly region_code: string;
	readonly region_name: string;
	readonly city: string;
	readonly zip: string;
	readonly latitude: number;
	readonly longitude: number;
	readonly location: {
		readonly geoname_id: number;
		readonly capital: string;
		readonly languages: [
			{
				readonly code: string;
				readonly name: string;
				readonly native: string;
			},
			{
				readonly code: string;
				readonly name: string;
				readonly native: string;
			}
		],
		readonly country_flag: string;
		readonly country_flag_emoji: string;
		readonly country_flag_emoji_unicode: string;
		readonly calling_code: string;
		readonly is_eu: false
	};
}
