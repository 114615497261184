import { LazyExoticComponent } from 'react';
import { ConnectedComponent } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getAuth as firebaseGetAuth } from 'firebase/auth';

import { AppRoutes, DefaultPrivateRoute, AppLocalStorage, AppStorageKeys, AppRouteState } from '../../Contracts';
import { AuthToken } from '../../Contracts/Login';

import StaticWebsite from './StaticWebsite';
import Dashboard from './Dashboard';


export interface ComponentRouteProps extends RouteProps {
	readonly component: LazyExoticComponent<any> | ConnectedComponent<any, any>;
}

export const PrivateRoute = ({ component: PrivateComponent, ...routeProps }: ComponentRouteProps) => {
	const authToken = AppLocalStorage.get(AppStorageKeys.Token, true) as AuthToken;
	const isLoggedIn = firebaseGetAuth(firebaseGetApp()).currentUser || authToken?.idToken?.length > 0;
	return (
		<Route {...routeProps} render={(props) => {
			return (isLoggedIn) ? (<Dashboard><PrivateComponent {...props} /></Dashboard>) : (<Redirect to={{ pathname: AppRoutes.Login, state: { from: props.location?.pathname } }} />);
		}} />
	);
};

export const AuthenticationRoute = ({ component: LoginComponent, ...routeProps }: ComponentRouteProps) => {
	const authToken = AppLocalStorage.get(AppStorageKeys.Token, true) as AuthToken;
	const isLoggedOut = !firebaseGetAuth(firebaseGetApp()).currentUser && (!authToken || !authToken.idToken || authToken.idToken.length === 0);
	return (
		<Route {...routeProps} render={(props) => {
			const state = props.location?.state as AppRouteState | undefined;
			return (isLoggedOut) ? (<LoginComponent {...props} />) : (<Redirect to={{ pathname: (state && state.from && state.from.length > 0 && state.from !== AppRoutes.Login && state.from !== AppRoutes.Error) ? state.from : DefaultPrivateRoute, state: { from: AppRoutes.Login } }} />);
		}} />
	);
};

export const DefaultRoute = ({ component: DefaultComponent, ...routeProps }: ComponentRouteProps) => {
	return (
		<Route {...routeProps} render={(props) => {
			return (<StaticWebsite><DefaultComponent {...props} /></StaticWebsite>);
		}} />
	);
};
