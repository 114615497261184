import { Reducer } from 'redux';

import { SocialPlatform } from '../../Contracts';
import { OAuthAccount } from '../../Contracts/Accounts';

import { AccountsActionTypes, AccountsActions } from '../../Actions/Accounts';

export interface AccountsState {
	readonly isLoading: boolean;
	readonly accountsList: OAuthAccount[];
	readonly currentActiveTab: SocialPlatform;
}

const initialState: AccountsState = {
	isLoading: false,
	accountsList: new Array<OAuthAccount>(),
	currentActiveTab: SocialPlatform.Facebook
};

export const AccountsReducer: Reducer<AccountsState, AccountsActions> = (
	state: AccountsState = initialState,
	action: AccountsActions
): AccountsState => {
	switch (action.type) {
		case AccountsActionTypes.SHOW_ACCOUNTS_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case AccountsActionTypes.HIDE_ACCOUNTS_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case AccountsActionTypes.CURRENT_ACTIVE_TAB_CHANGED: {
			return {
				...state,
				currentActiveTab: action.currentActiveTab
			};
		}
		case AccountsActionTypes.ACCOUNTS_REQUESTED: {
			return state;
		}
		case AccountsActionTypes.ACCOUNTS_SUCCESSFUL: {
			return {
				...state,
				accountsList: [ ...action.accountsList ]
			};
		}
		case AccountsActionTypes.ACCOUNTS_FAILED: {
			return state;
		}
		case AccountsActionTypes.ACCOUNT_REQUESTED: {
			return state;
		}
		case AccountsActionTypes.ACCOUNT_SUCCESSFUL: {
			const accountsList = state.accountsList.filter((account) => account.id !== action.account.id);
			const updateAccountIndex = state.accountsList.findIndex((account) => account.id === action.account.id);
			if (updateAccountIndex > -1) {
				accountsList.splice(updateAccountIndex, 0, action.account);
			}
			return {
				...state,
				accountsList
			};
		}
		case AccountsActionTypes.ACCOUNT_FAILED: {
			return state;
		}
		case AccountsActionTypes.DELETE_ACCOUNT_REQUESTED: {
			return state;
		}
		case AccountsActionTypes.DELETE_ACCOUNT_SUCCESSFUL: {
			return {
				...state,
				accountsList: state.accountsList.filter((account) => account.id !== action.deleteAccountRequest.id)
			};
		}
		case AccountsActionTypes.DELETE_ACCOUNT_FAILED: {
			return state;
		}
		case AccountsActionTypes.ACCOUNTS_RESET: {
			return {
				...state,
				accountsList: new Array<OAuthAccount>()
			};
		}
		default: {
			return state;
		}
	}
};

export default AccountsReducer;
