import { Component } from 'react';

import { Result, Button } from 'antd';

import { FrownOutlined as FrownOutlinedIcon } from '@ant-design/icons';

export class InternetError extends Component {
	render() {
		return (
			<Result
				status="error"
				icon={<FrownOutlinedIcon />}
				title="Looks like you are not connected to the internet"
				extra={<Button type="primary" htmlType="button" onClick={() => window.location.reload()}>Reload page</Button>}
				className="internet-connected-result"
			/>
		);
	}
}

export default InternetError;
