import { getApp as firebaseGetApp } from 'firebase/app';
import { getFirestore as firebaseGetFirestore, collection as firestoreCollection, query as firebaseQuery, doc as firestoreDocument, getDoc as firestoreGetDocument, getDocs as firestoreGetDocuments, deleteDoc as firestoreDeleteDocument, where as firestoreWhere, orderBy as firestoreOrderBy, limit as firestoreLimit } from 'firebase/firestore/lite';

import { DefaultErrorMessage } from '../../Contracts';
import { FireStoreCollections } from '../../Contracts/Util/Firebase';
import { AccountConverter } from '../../Contracts/Util/Converter';
import { OAuthAccount, AccountsRequest, DeleteAccountRequest } from '../../Contracts/Accounts';

export const getAccounts = async (accountsRequest: AccountsRequest): Promise<OAuthAccount[]> => {
	try {
		if (!accountsRequest?.userId || accountsRequest?.userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.Accounts).withConverter(AccountConverter), firestoreWhere('userId', '==', accountsRequest.userId), firestoreOrderBy('connect_timestamp', 'desc'), firestoreLimit(accountsRequest.limit)));
		if (!firebaseResponse || !firebaseResponse.docs) {
			return Promise.reject(DefaultErrorMessage);
		}
		const userAccountsList = new Array<OAuthAccount>();
		if (!firebaseResponse.empty) {
			firebaseResponse.docs.forEach((firebaseDocument) => {
				if (firebaseDocument.exists()) {
					userAccountsList.push(firebaseDocument.data());
				}
			});
		}
		return userAccountsList;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getAccount = async (accountId: string): Promise<OAuthAccount> => {
	try {
		if (!accountId || accountId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Accounts, accountId).withConverter(AccountConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as OAuthAccount;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const deleteAccount = async (deleteAccountRequest: DeleteAccountRequest): Promise<void> => {
	try {
		if (!deleteAccountRequest?.account?.id || deleteAccountRequest?.account?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		await firestoreDeleteDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Accounts, deleteAccountRequest.account.id).withConverter(AccountConverter));
	} catch (error: any) {
		return Promise.reject(error);
	}
};
