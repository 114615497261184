import { Component } from 'react';

import { Result, Typography } from 'antd';

import { CloseCircleOutlined as CloseCircleOutlinedIcon } from '@ant-design/icons';

import './AppError.scss';

export class AppError extends Component {
	render() {
		return (
			<Result
				status="error"
				title="Something went wrong"
				subTitle="Your browser failed to load this page correctly"
				className="app-error-result"
			>
				<Typography.Paragraph>
					<Typography.Text strong={true}>Your browser prevented this page from loading due to the following errors:</Typography.Text>
				</Typography.Paragraph>
				<Typography.Paragraph>
					<CloseCircleOutlinedIcon className="error-icon margin-right-12px" />Your browser might have JavaScript disabled
				</Typography.Paragraph>
				<Typography.Paragraph>
					<CloseCircleOutlinedIcon className="error-icon margin-right-12px" />Your browser might have Storage/Cookies disabled
				</Typography.Paragraph>
				<Typography.Paragraph>Please enable your browser's JavaScript and Storage settings and reload this page</Typography.Paragraph>
			</Result>
		);
	}
}

export default AppError;
