import axios from 'axios';

import { postRequest } from '../Backend';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getAuth as firebaseGetAuth, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword, signInWithCredential as firebaseSignInWithCredential, signOut as firebaseSignOut, sendPasswordResetEmail as firebaseSendPasswordResetEmail, sendEmailVerification as firebaseSendEmailVerification, EmailAuthProvider as FirebaseEmailAuthProvider, User as FirebaseAuthUser } from 'firebase/auth';

import { LoginRequest, SignupRequest, ForgotPasswordRequest, AuthToken } from '../../Contracts/Login';
import { UserData } from '../../Contracts/User';
import { IANATimeZone } from '../../Contracts/Util/TimeZone';
import { AppLocalStorage, AppRoutes, AppStorageKeys } from '../../Contracts';

const signupUrl = '/auth/user/signup';

export const login = async (loginRequest: LoginRequest): Promise<void> => {
	try {
		const firebaseUserCredential = await firebaseSignInWithEmailAndPassword(firebaseGetAuth(firebaseGetApp()), loginRequest.email, loginRequest.password);
		if (firebaseUserCredential.user) {
			const token = await firebaseUserCredential.user.getIdToken();
			AppLocalStorage.save(AppStorageKeys.Token, new AuthToken(firebaseUserCredential.user.refreshToken, token, firebaseUserCredential.user.uid));
		}
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const signup = async (signupRequest: SignupRequest): Promise<void> => {
	try {
		const email = signupRequest.email?.toLowerCase();
		const password = signupRequest.password;
		const userDataRequest: Partial<UserData> & SignupRequest = {
			email,
			password,
			first_name: signupRequest.first_name,
			last_name: signupRequest.last_name,
			iana_timezone: (Intl.DateTimeFormat().resolvedOptions().timeZone as IANATimeZone) ?? null,
			googleRecaptchaToken: signupRequest.googleRecaptchaToken
		};
		await axios(await postRequest(signupUrl, userDataRequest, true, false, false, false, {}));
		const firebaseUserCredential = await firebaseSignInWithCredential(firebaseGetAuth(firebaseGetApp()), FirebaseEmailAuthProvider.credential(email, password));
		if (firebaseUserCredential.user?.uid) {
			if (!firebaseUserCredential.user?.emailVerified) {
				await verifyEmail(firebaseUserCredential.user);
			}
			const token = await firebaseUserCredential.user.getIdToken();
			AppLocalStorage.save(AppStorageKeys.Token, new AuthToken(firebaseUserCredential.user.refreshToken, token, firebaseUserCredential.user.uid));
		}
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const logout = async (): Promise<void> => {
	try {
		await firebaseSignOut(firebaseGetAuth(firebaseGetApp()));
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const forgotPassword = async (forgotPasswordRequest: ForgotPasswordRequest): Promise<void> => {
	try {
		await firebaseSendPasswordResetEmail(firebaseGetAuth(firebaseGetApp()), forgotPasswordRequest.email, { url: `${process.env.REACT_APP_WEBSITE_HOST}${AppRoutes.Login}` });
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const verifyEmail = async (verifyEmailFirebaseUser: FirebaseAuthUser): Promise<void> => {
	try {
		if (!verifyEmailFirebaseUser.emailVerified) {
			await firebaseSendEmailVerification(verifyEmailFirebaseUser, { url: `${process.env.REACT_APP_WEBSITE_HOST}${AppRoutes.Home}` });
		}
	} catch (error: any) {
		return Promise.reject(error);
	}
};
