import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { User as FirebaseAuthUser } from 'firebase/auth';

import { GlobalState, AppLocalStorage, AppWindow } from '../../Contracts';
import { LoginRequest, SignupRequest, ForgotPasswordRequest } from '../../Contracts/Login';

import { login, signup, logout, forgotPassword, verifyEmail } from '../../Services/Login';

declare const window: AppWindow;

export enum LoginActionTypes {
	SHOW_LOGIN_LOADER = 'SHOW_LOGIN_LOADER',
	HIDE_LOGIN_LOADER = 'HIDE_LOGIN_LOADER',
	LOGIN_REQUESTED = 'LOGIN_REQUESTED',
	LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
	LOGIN_FAILED = 'LOGIN_FAILED',
	LOGOUT_REQUESTED = 'LOGOUT_REQUESTED',
	LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL',
	LOGOUT_FAILED = 'LOGOUT_FAILED',
	SIGNUP_REQUESTED = 'SIGNUP_REQUESTED',
	SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL',
	SIGNUP_FAILED = 'SIGNUP_FAILED',
	FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED',
	FORGOT_PASSWORD_SUCCESSFUL = 'FORGOT_PASSWORD_SUCCESSFUL',
	FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
	VERIFY_EMAIL_REQUESTED = 'VERIFY_EMAIL_REQUESTED',
	VERIFY_EMAIL_SUCCESSFUL = 'VERIFY_EMAIL_SUCCESSFUL',
	VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED'
}

export interface ShowLoginLoaderAction extends Action<LoginActionTypes.SHOW_LOGIN_LOADER> { }
export interface HideLoginLoaderAction extends Action<LoginActionTypes.HIDE_LOGIN_LOADER> { }
export interface LoginRequestedAction extends Action<LoginActionTypes.LOGIN_REQUESTED> { }
export interface LoginSuccessfulAction extends Action<LoginActionTypes.LOGIN_SUCCESSFUL> { }
export interface LoginFailedAction extends Action<LoginActionTypes.LOGIN_FAILED> { }
export interface LogoutRequestedAction extends Action<LoginActionTypes.LOGOUT_REQUESTED> { }
export interface LogoutSuccessfulAction extends Action<LoginActionTypes.LOGOUT_SUCCESSFUL> { }
export interface LogoutFailedAction extends Action<LoginActionTypes.LOGOUT_FAILED> { }
export interface SignupRequestedAction extends Action<LoginActionTypes.SIGNUP_REQUESTED> { }
export interface SignupSuccessfulAction extends Action<LoginActionTypes.SIGNUP_SUCCESSFUL> { }
export interface SignupFailedAction extends Action<LoginActionTypes.SIGNUP_FAILED> { }
export interface ForgotPasswordRequestedAction extends Action<LoginActionTypes.FORGOT_PASSWORD_REQUESTED> { }
export interface ForgotPasswordSuccessfulAction extends Action<LoginActionTypes.FORGOT_PASSWORD_SUCCESSFUL> { }
export interface ForgotPasswordFailedAction extends Action<LoginActionTypes.FORGOT_PASSWORD_FAILED> { }
export interface VerifyEmailRequestedAction extends Action<LoginActionTypes.VERIFY_EMAIL_REQUESTED> { }
export interface VerifyEmailSuccessfulAction extends Action<LoginActionTypes.VERIFY_EMAIL_SUCCESSFUL> { }
export interface VerifyEmailFailedAction extends Action<LoginActionTypes.VERIFY_EMAIL_FAILED> { }

export type LoginActions =
	| ShowLoginLoaderAction
	| HideLoginLoaderAction
	| LoginRequestedAction
	| LoginSuccessfulAction
	| LoginFailedAction
	| LogoutRequestedAction
	| LogoutSuccessfulAction
	| LogoutFailedAction
	| SignupRequestedAction
	| SignupSuccessfulAction
	| SignupFailedAction
	| ForgotPasswordRequestedAction
	| ForgotPasswordSuccessfulAction
	| ForgotPasswordFailedAction
	| VerifyEmailRequestedAction
	| VerifyEmailSuccessfulAction
	| VerifyEmailFailedAction;

export interface LoginDispatch {
	readonly showLoginLoader: ActionCreator<ThunkAction<ShowLoginLoaderAction, GlobalState, null, ShowLoginLoaderAction>>;
	readonly hideLoginLoader: ActionCreator<ThunkAction<HideLoginLoaderAction, GlobalState, null, HideLoginLoaderAction>>;
	readonly login: ActionCreator<ThunkAction<Promise<LoginSuccessfulAction>, GlobalState, LoginRequest, LoginSuccessfulAction>>;
	readonly loginFromSession: ActionCreator<ThunkAction<LoginSuccessfulAction, GlobalState, null, LoginSuccessfulAction>>;
	readonly logout: ActionCreator<ThunkAction<Promise<LogoutSuccessfulAction>, GlobalState, null, LogoutSuccessfulAction>>;
	readonly signup: ActionCreator<ThunkAction<Promise<SignupSuccessfulAction>, GlobalState, SignupRequest, SignupSuccessfulAction>>;
	readonly forgotPassword: ActionCreator<ThunkAction<Promise<ForgotPasswordSuccessfulAction>, GlobalState, ForgotPasswordRequest, ForgotPasswordSuccessfulAction>>;
	readonly verifyEmail: ActionCreator<ThunkAction<Promise<VerifyEmailSuccessfulAction>, GlobalState, FirebaseAuthUser, VerifyEmailSuccessfulAction>>;
}

export const LoginActionCreators: LoginDispatch = {
	showLoginLoader: () => {
		return (dispatch: Dispatch): ShowLoginLoaderAction => {
			const showLoginLoaderAction: ShowLoginLoaderAction = {
				type: LoginActionTypes.SHOW_LOGIN_LOADER
			};
			return dispatch(showLoginLoaderAction);
		};
	},
	hideLoginLoader: () => {
		return (dispatch: Dispatch): HideLoginLoaderAction => {
			const hideLoginLoaderAction: HideLoginLoaderAction = {
				type: LoginActionTypes.HIDE_LOGIN_LOADER
			};
			return dispatch(hideLoginLoaderAction);
		};
	},
	login: (loginRequest: LoginRequest) => {
		return async (dispatch: Dispatch): Promise<LoginSuccessfulAction> => {
			const loginRequestedAction: LoginRequestedAction = {
				type: LoginActionTypes.LOGIN_REQUESTED
			};
			dispatch(loginRequestedAction);
			try {
				await login(loginRequest);
				const loginSuccessfulAction: LoginSuccessfulAction = {
					type: LoginActionTypes.LOGIN_SUCCESSFUL
				};
				return dispatch(loginSuccessfulAction);
			} catch (error: any) {
				const loginFailedAction: LoginFailedAction = {
					type: LoginActionTypes.LOGIN_FAILED
				};
				dispatch(loginFailedAction);
				return Promise.reject(error);
			}
		};
	},
	loginFromSession: () => {
		return (dispatch: Dispatch): LoginSuccessfulAction => {
			const loginSuccessfulAction: LoginSuccessfulAction = {
				type: LoginActionTypes.LOGIN_SUCCESSFUL
			};
			return dispatch(loginSuccessfulAction);
		};
	},
	logout: () => {
		return async (dispatch: Dispatch): Promise<LogoutSuccessfulAction> => {
			try {
				await logout();
				AppLocalStorage.deleteAll();
				const logoutSuccessfulAction: LogoutSuccessfulAction = {
					type: LoginActionTypes.LOGOUT_SUCCESSFUL
				};
				if (window.fcWidget?.destroy) {
					window.fcWidget.destroy();
				}
				return dispatch(logoutSuccessfulAction);
			} catch (error: any) {
				const logoutFailedAction: LogoutFailedAction = {
					type: LoginActionTypes.LOGOUT_FAILED
				};
				dispatch(logoutFailedAction);
				return Promise.reject(error);
			}
		};
	},
	signup: (signupRequest: SignupRequest) => {
		return async (dispatch: Dispatch): Promise<SignupSuccessfulAction> => {
			const signupRequestedAction: SignupRequestedAction = {
				type: LoginActionTypes.SIGNUP_REQUESTED
			};
			dispatch(signupRequestedAction);
			try {
				await signup(signupRequest);
				const signupSuccessfulAction: SignupSuccessfulAction = {
					type: LoginActionTypes.SIGNUP_SUCCESSFUL
				};
				return dispatch(signupSuccessfulAction);
			} catch (error: any) {
				const signupFailedAction: SignupFailedAction = {
					type: LoginActionTypes.SIGNUP_FAILED
				};
				dispatch(signupFailedAction);
				return Promise.reject(error);
			}
		};
	},
	forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) => {
		return async (dispatch: Dispatch): Promise<ForgotPasswordSuccessfulAction> => {
			const forgotPasswordRequestedAction: ForgotPasswordRequestedAction = {
				type: LoginActionTypes.FORGOT_PASSWORD_REQUESTED
			};
			dispatch(forgotPasswordRequestedAction);
			try {
				await forgotPassword(forgotPasswordRequest);
				const forgotPasswordSuccessfulAction: ForgotPasswordSuccessfulAction = {
					type: LoginActionTypes.FORGOT_PASSWORD_SUCCESSFUL
				};
				return dispatch(forgotPasswordSuccessfulAction);
			} catch (error: any) {
				const forgotPasswordFailedAction: ForgotPasswordFailedAction = {
					type: LoginActionTypes.FORGOT_PASSWORD_FAILED
				};
				dispatch(forgotPasswordFailedAction);
				return Promise.reject(error);
			}
		};
	},
	verifyEmail: (firebaseUser: FirebaseAuthUser) => {
		return async (dispatch: Dispatch): Promise<VerifyEmailSuccessfulAction> => {
			const verifyEmailRequestedAction: VerifyEmailRequestedAction = {
				type: LoginActionTypes.VERIFY_EMAIL_REQUESTED
			};
			dispatch(verifyEmailRequestedAction);
			try {
				await verifyEmail(firebaseUser);
				const verifyEmailSuccessfulAction: VerifyEmailSuccessfulAction = {
					type: LoginActionTypes.VERIFY_EMAIL_SUCCESSFUL
				};
				return dispatch(verifyEmailSuccessfulAction);
			} catch (error: any) {
				const verifyEmailFailedAction: VerifyEmailFailedAction = {
					type: LoginActionTypes.VERIFY_EMAIL_FAILED
				};
				dispatch(verifyEmailFailedAction);
				return Promise.reject(error);
			}
		};
	}
};

export default LoginActionCreators;
