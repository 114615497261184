import ReactDOM from 'react-dom';

import { initializeApp as firebaseInitializeApp, getApps as firebaseGetApps, getApp as firebaseGetApp } from 'firebase/app';
import { initializeFirestore as firebaseInitializeFirestore } from 'firebase/firestore/lite';
import { isSupported as firebaseAnalyticsIsSupported, initializeAnalytics as firebaseInitializeAnalytics, getAnalytics as firebaseGetAnalytics, logEvent as firebaseAnalyticsLogEvent } from 'firebase/analytics';
import { initializePerformance as firebaseInitializePerformance } from 'firebase/performance';

import { message as Message } from 'antd';

import { register as serviceWorkerRegister } from './serviceWorkerRegistration';
import { reportWebVitals } from './reportWebVitals';

import './index.scss';

import App from './App';
import AppError from './AppError';

import { getServerErrorMessage, isKnownWebCrawler } from './Contracts/Util';

const LogTag: string = 'Index Component';

Message.config({ maxCount: 1 });

const updateServiceWorker = (registration: ServiceWorkerRegistration) => {
	if (registration.waiting) {
		registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		navigator.serviceWorker.oncontrollerchange = () => {
			window.location.reload();
		};
	}
};

(async () => {
	try {
		if (firebaseGetApps().length === 0) {
			const firebaseApp = firebaseInitializeApp({
				apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
				authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
				databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
				projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
				storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
				messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
				appId: process.env.REACT_APP_FIREBASE_APP_ID,
				measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
			});
			try {
				if (!isKnownWebCrawler(navigator.userAgent)) {
					if (await firebaseAnalyticsIsSupported()) {
						firebaseInitializeAnalytics(firebaseApp);
					}
					firebaseInitializePerformance(firebaseApp);
				}
				firebaseInitializeFirestore(firebaseGetApp(), {});
			} catch (error: any) {
				// Ignore this error
			}
		}
		ReactDOM.render(<App updateServiceWorker={updateServiceWorker} />, document.getElementById('root'));
	} catch (error: any) {
		console.error(LogTag, 'RenderApp', error);
		Message.error(getServerErrorMessage(error), 3);
		ReactDOM.render(<AppError />, document.getElementById('root'));
	}
})();

serviceWorkerRegister({
	onUpdate: (registration) => {
		const $customEvent = new CustomEvent('updateServiceWorker', {
			detail: { registration }
		});
		document.dispatchEvent($customEvent);
	}
});

reportWebVitals(async ({ name, value, delta, id }) => {
	try {
		if (firebaseGetApps().length > 0 && !isKnownWebCrawler(navigator.userAgent) && await firebaseAnalyticsIsSupported()) {
			firebaseAnalyticsLogEvent(firebaseGetAnalytics(firebaseGetApp()), name, {
				event_category: 'Web Vitals',
				event_action: name,
				event_value: Math.round(name === 'CLS' ? delta * 1000 : value),
				event_label: id,
				non_interaction: true
			});
		}
	} catch (error: any) {
		console.error(LogTag, 'ReportWebVitals', error);
	}
});
