import { Reducer } from 'redux';

import { BillingPlanCycle, BillingPlanData, BillingPlanIdentifier, UserDeviceInformation } from '../../Contracts';
import { IANATimeZone } from '../../Contracts/Util/TimeZone';
import { UserData, UserAggregates, InvoiceCountAggregates, EmptyUserAggregates, ChargebeeHostedPageAcknowledgementContent, ChargebeeInvoice, UserNotificationToken, UserRole } from '../../Contracts/User';

import { UserActionTypes, UserActions } from '../../Actions/User';

export interface UserState {
	readonly isLoading: boolean;
	readonly userData: UserData;
	readonly userAggregates: UserAggregates;
	readonly billingPlanList: BillingPlanData[];
	readonly totalInvoices: InvoiceCountAggregates;
	readonly chargebeeSubscription: ChargebeeHostedPageAcknowledgementContent | null;
	readonly chargebeeInvoicesList: ChargebeeInvoice[];
	readonly userDeviceInformation: UserDeviceInformation[];
	readonly updateBillingPlanDrawerOpen: boolean;
}

const initialUserData: UserData = {
	id: '',
	role: UserRole.Primary,
	email: '',
	name: { first_name: '', last_name: '', full_name: '' },
	phone: '',
	country_code: '',
	photo: '',
	billing_plan: { id: BillingPlanIdentifier.FreePlan, billing_cycle: BillingPlanCycle.Monthly },
	company_name: '',
	job_title: '',
	iana_timezone: (Intl.DateTimeFormat().resolvedOptions().timeZone as IANATimeZone),
	address: { address: '', city: '', country: '', pincode: '', state: '' },
	freshchat_restore_id: null,
	ipAddress: null,
	notification_token: new Array<UserNotificationToken>(),
	notification_preferences: { promotional: true, monthly_digest: true, newsletters: true, push: true }
};

const initialState: UserState = {
	isLoading: false,
	userData: { ...initialUserData },
	userAggregates: { ...EmptyUserAggregates },
	billingPlanList: new Array<BillingPlanData>(),
	totalInvoices: { count: 0 },
	chargebeeSubscription: null,
	chargebeeInvoicesList: new Array<ChargebeeInvoice>(),
	userDeviceInformation: new Array<UserDeviceInformation>(),
	updateBillingPlanDrawerOpen: false
};

export const UserReducer: Reducer<UserState, UserActions> = (
	state: UserState = initialState,
	action: UserActions
): UserState => {
	switch (action.type) {
		case UserActionTypes.SHOW_USER_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case UserActionTypes.HIDE_USER_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case UserActionTypes.USER_DEVICE_INFORMATION_REQUESTED: {
			return state;
		}
		case UserActionTypes.USER_DEVICE_INFORMATION_SUCCESSFUL: {
			return {
				...state,
				userDeviceInformation: action.userDeviceInformation
			};
		}
		case UserActionTypes.USER_DEVICE_INFORMATION_FAILED: {
			return state;
		}
		case UserActionTypes.USER_REQUESTED: {
			return state;
		}
		case UserActionTypes.USER_SUCCESSFUL: {
			return {
				...state,
				userData: { ...action.userData }
			};
		}
		case UserActionTypes.USER_FAILED: {
			return state;
		}
		case UserActionTypes.USER_AGGREGATES_REQUESTED: {
			return state;
		}
		case UserActionTypes.USER_AGGREGATES_SUCCESSFUL: {
			return {
				...state,
				userAggregates: { ...action.userAggregates }
			};
		}
		case UserActionTypes.USER_AGGREGATES_FAILED: {
			return state;
		}
		case UserActionTypes.EDIT_USER_REQUESTED: {
			return state;
		}
		case UserActionTypes.EDIT_USER_SUCCESSFUL: {
			return {
				...state,
				userData: { ...action.userData }
			};
		}
		case UserActionTypes.EDIT_USER_FAILED: {
			return state;
		}
		case UserActionTypes.RESET_USER: {
			return {
				...state,
				userData: { ...initialUserData },
				userAggregates: { ...EmptyUserAggregates }
			};
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_STARTED_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_SUCCESSFUL_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_FAILED_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_ABORTED_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_STARTED_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_SUCCESSFUL_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_FAILED_ACTION: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_ABORTED_ACTION: {
			return state;
		}
		case UserActionTypes.BILLING_PLANS_REQUESTED: {
			return state;
		}
		case UserActionTypes.BILLING_PLANS_SUCCESSFUL: {
			return {
				...state,
				billingPlanList: [ ...action.billingPlanList ]
			};
		}
		case UserActionTypes.BILLING_PLANS_FAILED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL: {
			return {
				...state,
				chargebeeSubscription: action.chargebeeSubscription
			};
		}
		case UserActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED: {
			return state;
		}
		case UserActionTypes.TOTAL_INVOICES_REQUESTED: {
			return state;
		}
		case UserActionTypes.TOTAL_INVOICES_SUCCESSFUL: {
			return {
				...state,
				totalInvoices: { ...action.totalInvoices }
			};
		}
		case UserActionTypes.TOTAL_INVOICES_FAILED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_INVOICES_REQUESTED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL: {
			return {
				...state,
				chargebeeInvoicesList: [ ...action.chargebeeInvoicesList ]
			};
		}
		case UserActionTypes.CHARGEBEE_INVOICES_FAILED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_HOSTED_PAGE_REQUESTED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_HOSTED_PAGE_SUCCESSFUL: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_HOSTED_PAGE_FAILED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_REQUESTED: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_SUCCESSFUL: {
			return state;
		}
		case UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_FAILED: {
			return state;
		}
		case UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_OPENED: {
			return {
				...state,
				updateBillingPlanDrawerOpen: true
			};
		}
		case UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_CLOSED: {
			return {
				...state,
				updateBillingPlanDrawerOpen: false
			};
		}
		default: {
			return state;
		}
	}
};

export default UserReducer;
