import { ReactText } from 'react';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getStorage as firebaseGetStorage, ref as firebaseStorageRef, getDownloadURL as firebaseStorageGetDownloadURL, uploadBytes as firebaseStorageUploadBytes, uploadBytesResumable as firebaseStorageUploadBytesResumable, deleteObject as firebaseStorageDeleteObject, UploadResult as FirebaseStorageUploadResult, UploadTaskSnapshot as FirebaseStorageUploadTaskSnapshot } from 'firebase/storage';

import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState, ColorScheme, UploadFileRequest, DeleteFileRequest, DefaultErrorMessage, AppRouteHash } from '../../Contracts';
import { resetBrowserUrlHash } from '../../Contracts/Util';

export enum UtilActionTypes {
	TOGGLE_COLOR_SCHEME = 'TOGGLE_COLOR_SCHEME',
	TOGGLE_INTERNET_CONNECTED = 'TOGGLE_INTERNET_CONNECTED',
	BROWSER_LOCATION_HASH_CHANGED = 'BROWSER_LOCATION_HASH_CHANGED',
	BROWSER_WINDOW_HEIGHT_RESIZED = 'BROWSER_WINDOW_HEIGHT_RESIZED',
	BROWSER_WINDOW_WIDTH_RESIZED = 'BROWSER_WINDOW_WIDTH_RESIZED',
	TOGGLE_DASHBOARD_SIDER_COLLAPSED = 'TOGGLE_DASHBOARD_SIDER_COLLAPSED',
	DASHBOARD_SIDER_WIDTH_CHANGED = 'DASHBOARD_SIDER_WIDTH_CHANGED',
	DASHBOARD_SIDER_COLLAPSED_WIDTH_CHANGED = 'DASHBOARD_SIDER_COLLAPSED_WIDTH_CHANGED',
	FILE_UPLOAD_ASYNC = 'FILE_UPLOAD_ASYNC',
	FILE_UPLOAD_SYNC = 'FILE_UPLOAD_SYNC',
	FILE_DELETE_REQUESTED = 'FILE_DELETE_REQUESTED',
	FILE_DELETE_SUCCESSFUL = 'FILE_DELETE_SUCCESSFUL',
	FILE_DELETE_FAILED = 'FILE_DELETE_FAILED'
}

export interface ToggleColorSchemeAction extends Action<UtilActionTypes.TOGGLE_COLOR_SCHEME> {
	readonly colorScheme: ColorScheme;
}
export interface ToggleInternetConnectedAction extends Action<UtilActionTypes.TOGGLE_INTERNET_CONNECTED> {
	readonly internetConnected: boolean;
}
export interface BrowserLocationHashChangedAction extends Action<UtilActionTypes.BROWSER_LOCATION_HASH_CHANGED> {
	readonly browserLocationHash: string;
}
export interface BrowserWindowHeightResizedAction extends Action<UtilActionTypes.BROWSER_WINDOW_HEIGHT_RESIZED> {
	readonly browserWindowHeight: number;
}
export interface BrowserWindowWidthResizedAction extends Action<UtilActionTypes.BROWSER_WINDOW_WIDTH_RESIZED> {
	readonly browserWindowWidth: number;
}
export interface ToggleDashboardSiderCollapsedAction extends Action<UtilActionTypes.TOGGLE_DASHBOARD_SIDER_COLLAPSED> {
	readonly dashboardSiderCollapsed: boolean;
}
export interface DashboardSiderWidthChangedAction extends Action<UtilActionTypes.DASHBOARD_SIDER_WIDTH_CHANGED> {
	readonly dashboardSiderWidth: ReactText;
}
export interface DashboardSiderCollapsedWidthChangedAction extends Action<UtilActionTypes.DASHBOARD_SIDER_COLLAPSED_WIDTH_CHANGED> {
	readonly dashboardSiderCollapsedWidth: ReactText;
}
export interface FileUploadAsyncAction extends Action<UtilActionTypes.FILE_UPLOAD_ASYNC> {
	readonly uploadFileSnapshot: FirebaseStorageUploadTaskSnapshot;
}
export interface FileUploadSyncAction extends Action<UtilActionTypes.FILE_UPLOAD_SYNC> {
	readonly uploadFileResult: FirebaseStorageUploadResult;
}
export interface FileDeleteRequestedAction extends Action<UtilActionTypes.FILE_DELETE_REQUESTED> { }
export interface FileDeleteSuccessfulAction extends Action<UtilActionTypes.FILE_DELETE_SUCCESSFUL> { }
export interface FileDeleteFailedAction extends Action<UtilActionTypes.FILE_DELETE_FAILED> { }

export type UtilActions =
	| ToggleColorSchemeAction
	| ToggleInternetConnectedAction
	| BrowserLocationHashChangedAction
	| BrowserWindowHeightResizedAction
	| BrowserWindowWidthResizedAction
	| ToggleDashboardSiderCollapsedAction
	| DashboardSiderWidthChangedAction
	| DashboardSiderCollapsedWidthChangedAction
	| FileUploadAsyncAction
	| FileUploadSyncAction
	| FileDeleteRequestedAction
	| FileDeleteSuccessfulAction
	| FileDeleteFailedAction;

export interface UtilDispatch {
	readonly toggleInternetConnected: ActionCreator<ThunkAction<ToggleInternetConnectedAction, GlobalState, boolean, ToggleInternetConnectedAction>>;
	readonly toggleColorScheme: ActionCreator<ThunkAction<ToggleColorSchemeAction, GlobalState, ColorScheme, ToggleColorSchemeAction>>;
	readonly browserLocationHashChanged: ActionCreator<ThunkAction<BrowserLocationHashChangedAction, GlobalState, string, BrowserLocationHashChangedAction>>;
	readonly browserWindowHeightResized: ActionCreator<ThunkAction<BrowserWindowHeightResizedAction, GlobalState, number, BrowserWindowHeightResizedAction>>;
	readonly browserWindowWidthResized: ActionCreator<ThunkAction<BrowserWindowWidthResizedAction, GlobalState, number, BrowserWindowWidthResizedAction>>;
	readonly toggleDashboardSiderCollapsed: ActionCreator<ThunkAction<ToggleDashboardSiderCollapsedAction, GlobalState, boolean, ToggleDashboardSiderCollapsedAction>>;
	readonly dashboardSiderWidthChanged: ActionCreator<ThunkAction<DashboardSiderWidthChangedAction, GlobalState, ReactText, DashboardSiderWidthChangedAction>>;
	readonly dashboardSiderCollapsedWidthChanged: ActionCreator<ThunkAction<DashboardSiderCollapsedWidthChangedAction, GlobalState, ReactText, DashboardSiderCollapsedWidthChangedAction>>;
	readonly uploadFileAsync: ActionCreator<ThunkAction<Promise<FileUploadAsyncAction>, GlobalState, UploadFileRequest, FileUploadAsyncAction>>;
	readonly uploadFileSync: ActionCreator<ThunkAction<Promise<FileUploadSyncAction>, GlobalState, UploadFileRequest, FileUploadSyncAction>>;
	readonly deleteFile: ActionCreator<ThunkAction<Promise<FileDeleteSuccessfulAction>, GlobalState, DeleteFileRequest, FileDeleteSuccessfulAction>>;
}

export const UtilActionCreators: UtilDispatch = {
	toggleColorScheme: (colorScheme: ColorScheme) => {
		return (dispatch: Dispatch): ToggleColorSchemeAction => {
			const toggleColorSchemeAction: ToggleColorSchemeAction = {
				type: UtilActionTypes.TOGGLE_COLOR_SCHEME,
				colorScheme
			};
			return dispatch(toggleColorSchemeAction);
		};
	},
	toggleInternetConnected: (internetConnected: boolean) => {
		return (dispatch: Dispatch): ToggleInternetConnectedAction => {
			const toggleInternetConnectedAction: ToggleInternetConnectedAction = {
				type: UtilActionTypes.TOGGLE_INTERNET_CONNECTED,
				internetConnected
			};
			return dispatch(toggleInternetConnectedAction);
		};
	},
	browserLocationHashChanged: (browserLocationHash: string) => {
		return (dispatch: Dispatch): BrowserLocationHashChangedAction => {
			const browserLocationHashChangedAction: BrowserLocationHashChangedAction = {
				type: UtilActionTypes.BROWSER_LOCATION_HASH_CHANGED,
				browserLocationHash
			};
			return dispatch(browserLocationHashChangedAction);
		};
	},
	browserWindowHeightResized: (browserWindowHeight: number) => {
		return (dispatch: Dispatch): BrowserWindowHeightResizedAction => {
			const browserWindowHeightResizedAction: BrowserWindowHeightResizedAction = {
				type: UtilActionTypes.BROWSER_WINDOW_HEIGHT_RESIZED,
				browserWindowHeight
			};
			return dispatch(browserWindowHeightResizedAction);
		};
	},
	browserWindowWidthResized: (browserWindowWidth: number) => {
		return (dispatch: Dispatch): BrowserWindowWidthResizedAction => {
			const browserWindowWidthResizedAction: BrowserWindowWidthResizedAction = {
				type: UtilActionTypes.BROWSER_WINDOW_WIDTH_RESIZED,
				browserWindowWidth
			};
			return dispatch(browserWindowWidthResizedAction);
		};
	},
	toggleDashboardSiderCollapsed: (dashboardSiderCollapsed: boolean) => {
		return (dispatch: Dispatch): ToggleDashboardSiderCollapsedAction => {
			const dashboardSiderCollapsedAction: ToggleDashboardSiderCollapsedAction = {
				type: UtilActionTypes.TOGGLE_DASHBOARD_SIDER_COLLAPSED,
				dashboardSiderCollapsed
			};
			if (dashboardSiderCollapsed) {
				resetBrowserUrlHash();
			} else {
				window.location.hash = AppRouteHash.DashboardSiderMenu;
			}
			return dispatch(dashboardSiderCollapsedAction);
		};
	},
	dashboardSiderWidthChanged: (dashboardSiderWidth: ReactText) => {
		return (dispatch: Dispatch): DashboardSiderWidthChangedAction => {
			const dashboardSiderWidthChangedAction: DashboardSiderWidthChangedAction = {
				type: UtilActionTypes.DASHBOARD_SIDER_WIDTH_CHANGED,
				dashboardSiderWidth
			};
			return dispatch(dashboardSiderWidthChangedAction);
		};
	},
	dashboardSiderCollapsedWidthChanged: (dashboardSiderCollapsedWidth: ReactText) => {
		return (dispatch: Dispatch): DashboardSiderCollapsedWidthChangedAction => {
			const dashboardSiderCollapsedWidthChangedAction: DashboardSiderCollapsedWidthChangedAction = {
				type: UtilActionTypes.DASHBOARD_SIDER_COLLAPSED_WIDTH_CHANGED,
				dashboardSiderCollapsedWidth
			};
			return dispatch(dashboardSiderCollapsedWidthChangedAction);
		};
	},
	uploadFileAsync: (uploadFileAsyncRequest: UploadFileRequest) => {
		return async (dispatch: Dispatch): Promise<FileUploadAsyncAction> => {
			try {
				const firebaseStorage = firebaseGetStorage(firebaseGetApp());
				const firebaseFileReference = firebaseStorageRef(firebaseStorage, uploadFileAsyncRequest.firebaseFilePath);
				const uploadFileSnapshot = await firebaseStorageUploadBytesResumable(firebaseFileReference, uploadFileAsyncRequest.uploadFile.originFileObj as File, { contentType: uploadFileAsyncRequest.uploadFile.type });
				const fileUploadAsyncAction: FileUploadAsyncAction = {
					type: UtilActionTypes.FILE_UPLOAD_ASYNC,
					uploadFileSnapshot
				};
				return dispatch(fileUploadAsyncAction);
			} catch (error: any) {
				return Promise.reject(error);
			}
		};
	},
	uploadFileSync: (uploadFileSyncRequest: UploadFileRequest) => {
		return async (dispatch: Dispatch): Promise<FileUploadSyncAction> => {
			try {
				const firebaseStorage = firebaseGetStorage(firebaseGetApp());
				const firebaseFileReference = firebaseStorageRef(firebaseStorage, uploadFileSyncRequest.firebaseFilePath);
				const uploadFileResult = await firebaseStorageUploadBytes(firebaseFileReference, uploadFileSyncRequest.uploadFile.originFileObj as File, { contentType: uploadFileSyncRequest.uploadFile.type });
				const fileUploadSyncAction: FileUploadSyncAction = {
					type: UtilActionTypes.FILE_UPLOAD_SYNC,
					uploadFileResult
				};
				return dispatch(fileUploadSyncAction);
			} catch (error: any) {
				return Promise.reject(error);
			}
		};
	},
	deleteFile: (deleteFileRequest: DeleteFileRequest) => {
		return async (dispatch: Dispatch): Promise<FileDeleteSuccessfulAction> => {
			try {
				const fileDeleteRequestedAction: FileDeleteRequestedAction = {
					type: UtilActionTypes.FILE_DELETE_REQUESTED
				};
				dispatch(fileDeleteRequestedAction);
				const firebaseStorage = firebaseGetStorage(firebaseGetApp());
				const fileReference = firebaseStorageRef(firebaseStorage, deleteFileRequest.firebaseFilePath);
				const downloadURL = await firebaseStorageGetDownloadURL(fileReference);
				if (downloadURL?.length > 0) {
					await firebaseStorageDeleteObject(fileReference);
					const fileDeleteSuccessfulAction: FileDeleteSuccessfulAction = {
						type: UtilActionTypes.FILE_DELETE_SUCCESSFUL
					};
					return dispatch(fileDeleteSuccessfulAction);
				} else {
					const fileDeleteFailedAction: FileDeleteFailedAction = {
						type: UtilActionTypes.FILE_DELETE_FAILED
					};
					dispatch(fileDeleteFailedAction);
					return Promise.reject(DefaultErrorMessage);
				}
			} catch (error: any) {
				const fileDeleteFailedAction: FileDeleteFailedAction = {
					type: UtilActionTypes.FILE_DELETE_FAILED
				};
				dispatch(fileDeleteFailedAction);
				return Promise.reject(error);
			}
		};
	}
};

export default UtilActionCreators;
