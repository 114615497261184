export class AuthToken {
	readonly userToken: string = '';
	readonly idToken: string = '';
	readonly userId: string = '';
	constructor (userToken: string, idToken: string, userId: string) {
		this.userToken = userToken;
		this.idToken = idToken;
		this.userId = userId;
	}
}

export interface LoginRequest {
	readonly email: string;
	readonly password: string;
}

export interface LoginFormValues {
	readonly email: string;
	readonly password: string;
}

export interface SignupRequest extends LoginRequest {
	readonly first_name: string;
	readonly last_name: string;
	readonly googleRecaptchaToken: string;
}

export interface ForgotPasswordRequest {
	readonly email: string;
}

export interface ForgotPasswordFormValues {
	readonly email: string;
}

export interface SignupFormValues extends LoginFormValues {
	readonly first_name: string;
	readonly last_name: string;
}
