import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { UploadFile } from 'antd/lib/upload/interface';

import { GlobalState, SocialPlatform, AppRouteHash } from '../../Contracts';
import { resetBrowserUrlHash } from '../../Contracts/Util';
import { GetPostsRequest, CreatePostRequest, Post, SuggestPostMediaRequest, PostFileResponse } from '../../Contracts/Post';

import { getPost, getPosts, createPost, editPost, deletePost, publishPost, suggestPostMedia } from '../../Services/Post';

export enum PostActionTypes {
	SHOW_POST_LOADER = 'SHOW_POST_LOADER',
	HIDE_POST_LOADER = 'HIDE_POST_LOADER',
	SHOW_SUGGESTED_POST_MEDIA_LOADER = 'SHOW_SUGGESTED_POST_MEDIA_LOADER',
	HIDE_SUGGESTED_POST_MEDIA_LOADER = 'HIDE_SUGGESTED_POST_MEDIA_LOADER',
	CURRENT_ACTIVE_TAB_CHANGED = 'POST_CURRENT_ACTIVE_TAB_CHANGED',
	POST_REQUESTED = 'POST_REQUESTED',
	POST_SUCCESSFUL = 'POST_SUCCESSFUL',
	POST_FAILED = 'POST_FAILED',
	POSTS_REQUESTED = 'POSTS_REQUESTED',
	POSTS_SUCCESSFUL = 'POSTS_SUCCESSFUL',
	POSTS_FAILED = 'POSTS_FAILED',
	POSTS_RESET = 'POSTS_RESET',
	CREATE_POST_REQUESTED = 'CREATE_POST_REQUESTED',
	CREATE_POST_SUCCESSFUL = 'CREATE_POST_SUCCESSFUL',
	CREATE_POST_FAILED = 'CREATE_POST_FAILED',
	EDIT_POST_REQUESTED = 'EDIT_POST_REQUESTED',
	EDIT_POST_SUCCESSFUL = 'EDIT_POST_SUCCESSFUL',
	EDIT_POST_FAILED = 'EDIT_POST_FAILED',
	DELETE_POST_REQUESTED = 'DELETE_POST_REQUESTED',
	DELETE_POST_SUCCESSFUL = 'DELETE_POST_SUCCESSFUL',
	DELETE_POST_FAILED = 'DELETE_POST_FAILED',
	PUBLISH_POST_REQUESTED = 'PUBLISH_POST_REQUESTED',
	PUBLISH_POST_SUCCESSFUL = 'PUBLISH_POST_SUCCESSFUL',
	PUBLISH_POST_FAILED = 'PUBLISH_POST_FAILED',
	SUGGEST_POST_MEDIA_REQUESTED = 'SUGGEST_POST_MEDIA_REQUESTED',
	SUGGEST_POST_MEDIA_SUCCESSFUL = 'SUGGEST_POST_MEDIA_SUCCESSFUL',
	SUGGEST_POST_MEDIA_RESET = 'SUGGEST_POST_MEDIA_RESET',
	SUGGEST_POST_MEDIA_FAILED = 'SUGGEST_POST_MEDIA_FAILED',
	CREATE_POST_DRAWER_OPENED = 'CREATE_POST_DRAWER_OPENED',
	CREATE_POST_DRAWER_CLOSED = 'CREATE_POST_DRAWER_CLOSED',
	EDIT_POST_DRAWER_OPENED = 'EDIT_POST_DRAWER_OPENED',
	EDIT_POST_DRAWER_CLOSED = 'EDIT_POST_DRAWER_CLOSED'
}

export interface ShowPostLoaderAction extends Action<PostActionTypes.SHOW_POST_LOADER> { }
export interface HidePostLoaderAction extends Action<PostActionTypes.HIDE_POST_LOADER> { }
export interface ShowSuggestedPostMediaLoaderAction extends Action<PostActionTypes.SHOW_SUGGESTED_POST_MEDIA_LOADER> { }
export interface HideSuggestedPostMediaLoaderAction extends Action<PostActionTypes.HIDE_SUGGESTED_POST_MEDIA_LOADER> { }
export interface CurrentActiveTabChangedAction extends Action<PostActionTypes.CURRENT_ACTIVE_TAB_CHANGED> {
	readonly currentActiveTab: SocialPlatform;
}
export interface PostRequestedAction extends Action<PostActionTypes.POST_REQUESTED> { }
export interface PostSuccessfulAction extends Action<PostActionTypes.POST_SUCCESSFUL> {
	readonly post: Post;
}
export interface PostFailedAction extends Action<PostActionTypes.POST_FAILED> { }
export interface PostsRequestedAction extends Action<PostActionTypes.POSTS_REQUESTED> { }
export interface PostsSuccessfulAction extends Action<PostActionTypes.POSTS_SUCCESSFUL> {
	readonly postsList: Post[];
}
export interface PostsFailedAction extends Action<PostActionTypes.POSTS_FAILED> { }
export interface PostsResetAction extends Action<PostActionTypes.POSTS_RESET> { }
export interface CreatePostRequestedAction extends Action<PostActionTypes.CREATE_POST_REQUESTED> { }
export interface CreatePostSuccessfulAction extends Action<PostActionTypes.CREATE_POST_SUCCESSFUL> {
	readonly createPostRequest: Post;
}
export interface CreatePostFailedAction extends Action<PostActionTypes.CREATE_POST_FAILED> { }
export interface EditPostRequestedAction extends Action<PostActionTypes.EDIT_POST_REQUESTED> { }
export interface EditPostSuccessfulAction extends Action<PostActionTypes.EDIT_POST_SUCCESSFUL> {
	readonly editPostRequest: Post;
}
export interface EditPostFailedAction extends Action<PostActionTypes.EDIT_POST_FAILED> { }
export interface DeletePostRequestedAction extends Action<PostActionTypes.DELETE_POST_REQUESTED> { }
export interface DeletePostSuccessfulAction extends Action<PostActionTypes.DELETE_POST_SUCCESSFUL> {
	readonly deletePostRequest: Post;
}
export interface DeletePostFailedAction extends Action<PostActionTypes.DELETE_POST_FAILED> { }
export interface PublishPostRequestedAction extends Action<PostActionTypes.PUBLISH_POST_REQUESTED> { }
export interface PublishPostSuccessfulAction extends Action<PostActionTypes.PUBLISH_POST_SUCCESSFUL> {
	readonly editPostRequest: Post;
}
export interface PublishPostFailedAction extends Action<PostActionTypes.PUBLISH_POST_FAILED> { }
export interface SuggestPostMediaRequestedAction extends Action<PostActionTypes.SUGGEST_POST_MEDIA_REQUESTED> { }
export interface SuggestPostMediaSuccessfulAction extends Action<PostActionTypes.SUGGEST_POST_MEDIA_SUCCESSFUL> {
	readonly suggestedPostMediaList: UploadFile<PostFileResponse>[];
}
export interface SuggestPostMediaResetAction extends Action<PostActionTypes.SUGGEST_POST_MEDIA_RESET> { }
export interface SuggestPostMediaFailedAction extends Action<PostActionTypes.SUGGEST_POST_MEDIA_FAILED> { }
export interface CreatePostDrawerOpenedAction extends Action<PostActionTypes.CREATE_POST_DRAWER_OPENED> { }
export interface CreatePostDrawerClosedAction extends Action<PostActionTypes.CREATE_POST_DRAWER_CLOSED> { }
export interface EditPostDrawerOpenedAction extends Action<PostActionTypes.EDIT_POST_DRAWER_OPENED> { }
export interface EditPostDrawerClosedAction extends Action<PostActionTypes.EDIT_POST_DRAWER_CLOSED> { }

export type PostActions =
	| ShowPostLoaderAction
	| HidePostLoaderAction
	| ShowSuggestedPostMediaLoaderAction
	| HideSuggestedPostMediaLoaderAction
	| CurrentActiveTabChangedAction
	| PostRequestedAction
	| PostSuccessfulAction
	| PostFailedAction
	| PostsRequestedAction
	| PostsSuccessfulAction
	| PostsFailedAction
	| PostsResetAction
	| CreatePostRequestedAction
	| CreatePostSuccessfulAction
	| CreatePostFailedAction
	| EditPostRequestedAction
	| EditPostSuccessfulAction
	| EditPostFailedAction
	| DeletePostRequestedAction
	| DeletePostSuccessfulAction
	| DeletePostFailedAction
	| PublishPostRequestedAction
	| PublishPostSuccessfulAction
	| PublishPostFailedAction
	| SuggestPostMediaRequestedAction
	| SuggestPostMediaSuccessfulAction
	| SuggestPostMediaFailedAction
	| SuggestPostMediaResetAction
	| CreatePostDrawerOpenedAction
	| CreatePostDrawerClosedAction
	| EditPostDrawerOpenedAction
	| EditPostDrawerClosedAction;

export interface PostDispatch {
	readonly showPostLoader: ActionCreator<ThunkAction<ShowPostLoaderAction, GlobalState, null, ShowPostLoaderAction>>;
	readonly hidePostLoader: ActionCreator<ThunkAction<HidePostLoaderAction, GlobalState, null, HidePostLoaderAction>>;
	readonly showSuggestedPostMediaLoader: ActionCreator<ThunkAction<ShowSuggestedPostMediaLoaderAction, GlobalState, null, ShowSuggestedPostMediaLoaderAction>>;
	readonly hideSuggestedPostMediaLoader: ActionCreator<ThunkAction<HideSuggestedPostMediaLoaderAction, GlobalState, null, HideSuggestedPostMediaLoaderAction>>;
	readonly currentActiveTabChanged: ActionCreator<ThunkAction<CurrentActiveTabChangedAction, GlobalState, SocialPlatform, CurrentActiveTabChangedAction>>;
	readonly getPost: ActionCreator<ThunkAction<Promise<PostSuccessfulAction>, GlobalState, string, PostSuccessfulAction>>;
	readonly getPosts: ActionCreator<ThunkAction<Promise<PostsSuccessfulAction>, GlobalState, GetPostsRequest, PostsSuccessfulAction>>;
	readonly resetPosts: ActionCreator<ThunkAction<PostsResetAction, GlobalState, GetPostsRequest, PostsResetAction>>;
	readonly createPost: ActionCreator<ThunkAction<Promise<CreatePostSuccessfulAction>, GlobalState, CreatePostRequest, CreatePostSuccessfulAction>>;
	readonly editPost: ActionCreator<ThunkAction<Promise<EditPostSuccessfulAction>, GlobalState, Post, EditPostSuccessfulAction>>;
	readonly deletePost: ActionCreator<ThunkAction<Promise<DeletePostSuccessfulAction>, GlobalState, Post, DeletePostSuccessfulAction>>;
	readonly publishPost: ActionCreator<ThunkAction<Promise<PublishPostSuccessfulAction>, GlobalState, Post, PublishPostSuccessfulAction>>;
	readonly suggestPostMedia: ActionCreator<ThunkAction<Promise<SuggestPostMediaSuccessfulAction>, GlobalState, SuggestPostMediaRequest, SuggestPostMediaSuccessfulAction>>;
	readonly resetSuggestedPostMedia: ActionCreator<ThunkAction<SuggestPostMediaResetAction, GlobalState, null, SuggestPostMediaResetAction>>;
	readonly createPostDrawerOpened: ActionCreator<ThunkAction<CreatePostDrawerOpenedAction, GlobalState, SocialPlatform, CreatePostDrawerOpenedAction>>;
	readonly createPostDrawerClosed: ActionCreator<ThunkAction<CreatePostDrawerClosedAction, GlobalState, null, CreatePostDrawerClosedAction>>;
	readonly editPostDrawerOpened: ActionCreator<ThunkAction<EditPostDrawerOpenedAction, GlobalState, Post, EditPostDrawerOpenedAction>>;
	readonly editPostDrawerClosed: ActionCreator<ThunkAction<EditPostDrawerClosedAction, GlobalState, null, EditPostDrawerClosedAction>>;
}

export const PostActionCreators: PostDispatch = {
	showPostLoader: () => {
		return (dispatch: Dispatch): ShowPostLoaderAction => {
			const showPostLoaderAction: ShowPostLoaderAction = {
				type: PostActionTypes.SHOW_POST_LOADER
			};
			return dispatch(showPostLoaderAction);
		};
	},
	hidePostLoader: () => {
		return (dispatch: Dispatch): HidePostLoaderAction => {
			const hidePostLoaderAction: HidePostLoaderAction = {
				type: PostActionTypes.HIDE_POST_LOADER
			};
			return dispatch(hidePostLoaderAction);
		};
	},
	showSuggestedPostMediaLoader: () => {
		return (dispatch: Dispatch): ShowSuggestedPostMediaLoaderAction => {
			const hhowSuggestedPostMediaLoaderAction: ShowSuggestedPostMediaLoaderAction = {
				type: PostActionTypes.SHOW_SUGGESTED_POST_MEDIA_LOADER
			};
			return dispatch(hhowSuggestedPostMediaLoaderAction);
		};
	},
	hideSuggestedPostMediaLoader: () => {
		return (dispatch: Dispatch): HideSuggestedPostMediaLoaderAction => {
			const hideSuggestedPostMediaLoaderAction: HideSuggestedPostMediaLoaderAction = {
				type: PostActionTypes.HIDE_SUGGESTED_POST_MEDIA_LOADER
			};
			return dispatch(hideSuggestedPostMediaLoaderAction);
		};
	},
	currentActiveTabChanged: (currentActiveTab: SocialPlatform) => {
		return (dispatch: Dispatch): CurrentActiveTabChangedAction => {
			const currentActiveTabChangedAction: CurrentActiveTabChangedAction = {
				type: PostActionTypes.CURRENT_ACTIVE_TAB_CHANGED,
				currentActiveTab
			};
			window.location.hash = currentActiveTab;
			return dispatch(currentActiveTabChangedAction);
		};
	},
	getPost: (postId: string) => {
		return async (dispatch: Dispatch): Promise<PostSuccessfulAction> => {
			const postRequestedAction: PostRequestedAction = {
				type: PostActionTypes.POST_REQUESTED
			};
			dispatch(postRequestedAction);
			try {
				const getPostResponse = await getPost(postId);
				const postSuccessfulAction: PostSuccessfulAction = {
					type: PostActionTypes.POST_SUCCESSFUL,
					post: getPostResponse
				};
				return dispatch(postSuccessfulAction);
			} catch (error: any) {
				const postFailedAction: PostFailedAction = {
					type: PostActionTypes.POST_FAILED
				};
				dispatch(postFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getPosts: (getPostsRequest: GetPostsRequest) => {
		return async (dispatch: Dispatch): Promise<PostsSuccessfulAction> => {
			const postsRequestedAction: PostsRequestedAction = {
				type: PostActionTypes.POSTS_REQUESTED
			};
			dispatch(postsRequestedAction);
			try {
				const getPostsResponse = await getPosts(getPostsRequest);
				const postsSuccessfulAction: PostsSuccessfulAction = {
					type: PostActionTypes.POSTS_SUCCESSFUL,
					postsList: getPostsResponse
				};
				return dispatch(postsSuccessfulAction);
			} catch (error: any) {
				const postsFailedAction: PostsFailedAction = {
					type: PostActionTypes.POSTS_FAILED
				};
				dispatch(postsFailedAction);
				return Promise.reject(error);
			}
		};
	},
	resetPosts: () => {
		return (dispatch: Dispatch): PostsResetAction => {
			const postsResetAction: PostsResetAction = {
				type: PostActionTypes.POSTS_RESET
			};
			return dispatch(postsResetAction);
		};
	},
	createPost: (createPostRequest: CreatePostRequest) => {
		return async (dispatch: Dispatch): Promise<CreatePostSuccessfulAction> => {
			const createPostRequestedAction: CreatePostRequestedAction = {
				type: PostActionTypes.CREATE_POST_REQUESTED
			};
			dispatch(createPostRequestedAction);
			try {
				const createPostResponse = await createPost(createPostRequest);
				const createPostSuccessfulAction: CreatePostSuccessfulAction = {
					type: PostActionTypes.CREATE_POST_SUCCESSFUL,
					createPostRequest: createPostResponse
				};
				return dispatch(createPostSuccessfulAction);
			} catch (error: any) {
				const createPostFailedAction: CreatePostFailedAction = {
					type: PostActionTypes.CREATE_POST_FAILED
				};
				dispatch(createPostFailedAction);
				return Promise.reject(error);
			}
		};
	},
	editPost: (editPostRequest: Post) => {
		return async (dispatch: Dispatch): Promise<EditPostSuccessfulAction> => {
			const editPostRequestedAction: EditPostRequestedAction = {
				type: PostActionTypes.EDIT_POST_REQUESTED
			};
			dispatch(editPostRequestedAction);
			try {
				const editPostResponse = await editPost(editPostRequest);
				const editPostSuccessfulAction: EditPostSuccessfulAction = {
					type: PostActionTypes.EDIT_POST_SUCCESSFUL,
					editPostRequest: editPostResponse
				};
				return dispatch(editPostSuccessfulAction);
			} catch (error: any) {
				const editPostFailedAction: EditPostFailedAction = {
					type: PostActionTypes.EDIT_POST_FAILED
				};
				dispatch(editPostFailedAction);
				return Promise.reject(error);
			}
		};
	},
	deletePost: (deletePostRequest: Post) => {
		return async (dispatch: Dispatch): Promise<DeletePostSuccessfulAction> => {
			const deletePostRequestedAction: DeletePostRequestedAction = {
				type: PostActionTypes.DELETE_POST_REQUESTED
			};
			dispatch(deletePostRequestedAction);
			try {
				await deletePost(deletePostRequest);
				const deletePostSuccessfulAction: DeletePostSuccessfulAction = {
					type: PostActionTypes.DELETE_POST_SUCCESSFUL,
					deletePostRequest
				};
				return dispatch(deletePostSuccessfulAction);
			} catch (error: any) {
				const deletePostFailedAction: DeletePostFailedAction = {
					type: PostActionTypes.DELETE_POST_FAILED
				};
				dispatch(deletePostFailedAction);
				return Promise.reject(error);
			}
		};
	},
	publishPost: (publishPostRequest: Post) => {
		return async (dispatch: Dispatch): Promise<PublishPostSuccessfulAction> => {
			const publishPostRequestedAction: PublishPostRequestedAction = {
				type: PostActionTypes.PUBLISH_POST_REQUESTED
			};
			dispatch(publishPostRequestedAction);
			try {
				const publishPostResponse = await publishPost(publishPostRequest);
				const publishPostSuccessfulAction: PublishPostSuccessfulAction = {
					type: PostActionTypes.PUBLISH_POST_SUCCESSFUL,
					editPostRequest: publishPostResponse
				};
				return dispatch(publishPostSuccessfulAction);
			} catch (error: any) {
				const publishPostFailedAction: PublishPostFailedAction = {
					type: PostActionTypes.PUBLISH_POST_FAILED
				};
				dispatch(publishPostFailedAction);
				return Promise.reject(error);
			}
		};
	},
	suggestPostMedia: (suggestPostMediaRequest: SuggestPostMediaRequest) => {
		return async (dispatch: Dispatch): Promise<SuggestPostMediaSuccessfulAction> => {
			const suggestPostMediaRequestedAction: SuggestPostMediaRequestedAction = {
				type: PostActionTypes.SUGGEST_POST_MEDIA_REQUESTED
			};
			dispatch(suggestPostMediaRequestedAction);
			try {
				const suggestPostMediaResponse = await suggestPostMedia(suggestPostMediaRequest.url, suggestPostMediaRequest.socialPlatform);
				const suggestPostMediaSuccessfulAction: SuggestPostMediaSuccessfulAction = {
					type: PostActionTypes.SUGGEST_POST_MEDIA_SUCCESSFUL,
					suggestedPostMediaList: suggestPostMediaResponse
				};
				return dispatch(suggestPostMediaSuccessfulAction);
			} catch (error: any) {
				const suggestPostMediaFailedAction: SuggestPostMediaFailedAction = {
					type: PostActionTypes.SUGGEST_POST_MEDIA_FAILED
				};
				dispatch(suggestPostMediaFailedAction);
				return Promise.reject(error);
			}
		};
	},
	resetSuggestedPostMedia: () => {
		return (dispatch: Dispatch): SuggestPostMediaResetAction => {
			const suggestPostMediaResetAction: SuggestPostMediaResetAction = {
				type: PostActionTypes.SUGGEST_POST_MEDIA_RESET
			};
			return dispatch(suggestPostMediaResetAction);
		};
	},
	createPostDrawerOpened: () => {
		return (dispatch: Dispatch): CreatePostDrawerOpenedAction => {
			const createPostDrawerOpenedAction: CreatePostDrawerOpenedAction = {
				type: PostActionTypes.CREATE_POST_DRAWER_OPENED
			};
			window.location.hash = AppRouteHash.CreatePostDrawer;
			return dispatch(createPostDrawerOpenedAction);
		};
	},
	createPostDrawerClosed: () => {
		return (dispatch: Dispatch): CreatePostDrawerClosedAction => {
			const createPostDrawerClosedAction: CreatePostDrawerClosedAction = {
				type: PostActionTypes.CREATE_POST_DRAWER_CLOSED
			};
			resetBrowserUrlHash();
			return dispatch(createPostDrawerClosedAction);
		};
	},
	editPostDrawerOpened: () => {
		return (dispatch: Dispatch): EditPostDrawerOpenedAction => {
			const editPostDrawerOpenedAction: EditPostDrawerOpenedAction = {
				type: PostActionTypes.EDIT_POST_DRAWER_OPENED
			};
			window.location.hash = AppRouteHash.EditPostDrawer;
			return dispatch(editPostDrawerOpenedAction);
		};
	},
	editPostDrawerClosed: () => {
		return (dispatch: Dispatch): EditPostDrawerClosedAction => {
			const editPostDrawerClosedAction: EditPostDrawerClosedAction = {
				type: PostActionTypes.EDIT_POST_DRAWER_CLOSED
			};
			resetBrowserUrlHash();
			return dispatch(editPostDrawerClosedAction);
		};
	}
};

export default PostActionCreators;
