import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState } from '../../Contracts';
import { ContactUs, BugReport, FeatureRequest } from '../../Contracts/StaticWebsite';

import { contactUs, bugReport, featureRequest } from '../../Services/StaticWebsite';

export enum StaticWebsiteActionTypes {
	SHOW_STATIC_WEBSITE_LOADER = 'SHOW_STATIC_WEBSITE_LOADER',
	HIDE_STATIC_WEBSITE_LOADER = 'HIDE_STATIC_WEBSITE_LOADER',
	LOAD_STATIC_WEBSITE_PAGE_LINK = 'LOAD_STATIC_WEBSITE_PAGE_LINK',
	NAVIGATE_STATIC_WEBSITE_PAGE_LINK = 'NAVIGATE_STATIC_WEBSITE_PAGE_LINK',
	NAVIGATE_STATIC_WEBSITE_PAGE_SECTION = 'NAVIGATE_STATIC_WEBSITE_PAGE_SECTION',
	CONTACT_US_REQUESTED = 'CONTACT_US_REQUESTED',
	CONTACT_US_SUCCESSFUL = 'CONTACT_US_SUCCESSFUL',
	CONTACT_US_FAILED = 'CONTACT_US_FAILED',
	BUG_REPORT_REQUESTED = 'BUG_REPORT_REQUESTED',
	BUG_REPORT_SUCCESSFUL = 'BUG_REPORT_SUCCESSFUL',
	BUG_REPORT_FAILED = 'BUG_REPORT_FAILED',
	FEATURE_REQUEST_REQUESTED = 'FEATURE_REQUEST_REQUESTED',
	FEATURE_REQUEST_SUCCESSFUL = 'FEATURE_REQUEST_SUCCESSFUL',
	FEATURE_REQUEST_FAILED = 'FEATURE_REQUEST_FAILED'
}

export interface ShowStaticWebsiteLoaderAction extends Action<StaticWebsiteActionTypes.SHOW_STATIC_WEBSITE_LOADER> { }
export interface HideStaticWebsiteLoaderAction extends Action<StaticWebsiteActionTypes.HIDE_STATIC_WEBSITE_LOADER> { }
export interface LoadStaticWebsitePageLinkAction extends Action<StaticWebsiteActionTypes.LOAD_STATIC_WEBSITE_PAGE_LINK> {
	readonly pageName: string;
}
export interface NavigateStaticWebsitePageLinkAction extends Action<StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_LINK> {
	readonly pageName: string;
}
export interface NavigateStaticWebsitePageSectionAction extends Action<StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_SECTION> {
	readonly sectionName: string;
}
export interface ContactUsRequestedAction extends Action<StaticWebsiteActionTypes.CONTACT_US_REQUESTED> { }
export interface ContactUsSuccessfulAction extends Action<StaticWebsiteActionTypes.CONTACT_US_SUCCESSFUL> { }
export interface ContactUsFailedAction extends Action<StaticWebsiteActionTypes.CONTACT_US_FAILED> { }
export interface BugReportRequestedAction extends Action<StaticWebsiteActionTypes.BUG_REPORT_REQUESTED> { }
export interface BugReportSuccessfulAction extends Action<StaticWebsiteActionTypes.BUG_REPORT_SUCCESSFUL> { }
export interface BugReportFailedAction extends Action<StaticWebsiteActionTypes.BUG_REPORT_FAILED> { }
export interface FeatureRequestRequestedAction extends Action<StaticWebsiteActionTypes.FEATURE_REQUEST_REQUESTED> { }
export interface FeatureRequestSuccessfulAction extends Action<StaticWebsiteActionTypes.FEATURE_REQUEST_SUCCESSFUL> { }
export interface FeatureRequestFailedAction extends Action<StaticWebsiteActionTypes.FEATURE_REQUEST_FAILED> { }

export type StaticWebsiteActions =
	| ShowStaticWebsiteLoaderAction
	| HideStaticWebsiteLoaderAction
	| LoadStaticWebsitePageLinkAction
	| NavigateStaticWebsitePageLinkAction
	| NavigateStaticWebsitePageSectionAction
	| ContactUsRequestedAction
	| ContactUsSuccessfulAction
	| ContactUsFailedAction
	| BugReportRequestedAction
	| BugReportSuccessfulAction
	| BugReportFailedAction
	| FeatureRequestRequestedAction
	| FeatureRequestSuccessfulAction
	| FeatureRequestFailedAction;

export interface StaticWebsiteDispatch {
	readonly showStaticWebsiteLoader: ActionCreator<ThunkAction<ShowStaticWebsiteLoaderAction, GlobalState, null, ShowStaticWebsiteLoaderAction>>;
	readonly hideStaticWebsiteLoader: ActionCreator<ThunkAction<HideStaticWebsiteLoaderAction, GlobalState, null, HideStaticWebsiteLoaderAction>>;
	readonly loadStaticWebsitePageLink: ActionCreator<ThunkAction<LoadStaticWebsitePageLinkAction, GlobalState, string, LoadStaticWebsitePageLinkAction>>;
	readonly navigateStaticWebsitePageLink: ActionCreator<ThunkAction<NavigateStaticWebsitePageLinkAction, GlobalState, string, NavigateStaticWebsitePageLinkAction>>;
	readonly navigateStaticWebsitePageSection: ActionCreator<ThunkAction<NavigateStaticWebsitePageSectionAction, GlobalState, string, NavigateStaticWebsitePageSectionAction>>;
	readonly contactUs: ActionCreator<ThunkAction<Promise<ContactUsSuccessfulAction>, GlobalState, ContactUs, ContactUsSuccessfulAction>>;
	readonly bugReport: ActionCreator<ThunkAction<Promise<BugReportSuccessfulAction>, GlobalState, BugReport, BugReportSuccessfulAction>>;
	readonly featureRequest: ActionCreator<ThunkAction<Promise<FeatureRequestSuccessfulAction>, GlobalState, FeatureRequest, FeatureRequestSuccessfulAction>>;
}

export const StaticWebsiteActionCreators: StaticWebsiteDispatch = {
	showStaticWebsiteLoader: () => {
		return (dispatch: Dispatch): ShowStaticWebsiteLoaderAction => {
			const showStaticWebsiteLoaderAction: ShowStaticWebsiteLoaderAction = {
				type: StaticWebsiteActionTypes.SHOW_STATIC_WEBSITE_LOADER
			};
			return dispatch(showStaticWebsiteLoaderAction);
		};
	},
	hideStaticWebsiteLoader: () => {
		return (dispatch: Dispatch): HideStaticWebsiteLoaderAction => {
			const hideStaticWebsiteLoader: HideStaticWebsiteLoaderAction = {
				type: StaticWebsiteActionTypes.HIDE_STATIC_WEBSITE_LOADER
			};
			return dispatch(hideStaticWebsiteLoader);
		};
	},
	loadStaticWebsitePageLink: (pageName: string) => {
		return (dispatch: Dispatch): LoadStaticWebsitePageLinkAction => {
			const loadStaticWebsitePageLinkAction: LoadStaticWebsitePageLinkAction = {
				type: StaticWebsiteActionTypes.LOAD_STATIC_WEBSITE_PAGE_LINK,
				pageName
			};
			return dispatch(loadStaticWebsitePageLinkAction);
		};
	},
	navigateStaticWebsitePageLink: (pageName: string) => {
		return (dispatch: Dispatch): NavigateStaticWebsitePageLinkAction => {
			const navigateStaticWebsitePageLinkAction: NavigateStaticWebsitePageLinkAction = {
				type: StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_LINK,
				pageName
			};
			return dispatch(navigateStaticWebsitePageLinkAction);
		};
	},
	navigateStaticWebsitePageSection: (sectionName: string) => {
		return (dispatch: Dispatch): NavigateStaticWebsitePageSectionAction => {
			const navigateStaticWebsitePageSectionAction: NavigateStaticWebsitePageSectionAction = {
				type: StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_SECTION,
				sectionName
			};
			return dispatch(navigateStaticWebsitePageSectionAction);
		};
	},
	contactUs: (contactUsRequest: ContactUs) => {
		return async (dispatch: Dispatch): Promise<ContactUsSuccessfulAction> => {
			const contactUsRequestedAction: ContactUsRequestedAction = {
				type: StaticWebsiteActionTypes.CONTACT_US_REQUESTED
			};
			dispatch(contactUsRequestedAction);
			try {
				await contactUs(contactUsRequest);
				const contactUsSuccessfulAction: ContactUsSuccessfulAction = {
					type: StaticWebsiteActionTypes.CONTACT_US_SUCCESSFUL
				};
				return dispatch(contactUsSuccessfulAction);
			} catch (error: any) {
				const contactUsFailedAction: ContactUsFailedAction = {
					type: StaticWebsiteActionTypes.CONTACT_US_FAILED
				};
				dispatch(contactUsFailedAction);
				return Promise.reject(error);
			}
		};
	},
	bugReport: (bugReportRequest: BugReport) => {
		return async (dispatch: Dispatch): Promise<BugReportSuccessfulAction> => {
			const bugReportRequestedAction: BugReportRequestedAction = {
				type: StaticWebsiteActionTypes.BUG_REPORT_REQUESTED
			};
			dispatch(bugReportRequestedAction);
			try {
				await bugReport(bugReportRequest);
				const bugReportSuccessfulAction: BugReportSuccessfulAction = {
					type: StaticWebsiteActionTypes.BUG_REPORT_SUCCESSFUL
				};
				return dispatch(bugReportSuccessfulAction);
			} catch (error: any) {
				const bugReportFailedAction: BugReportFailedAction = {
					type: StaticWebsiteActionTypes.BUG_REPORT_FAILED
				};
				dispatch(bugReportFailedAction);
				return Promise.reject(error);
			}
		};
	},
	featureRequest: (featureRequestRequest: FeatureRequest) => {
		return async (dispatch: Dispatch): Promise<FeatureRequestSuccessfulAction> => {
			const featureRequestRequestedAction: FeatureRequestRequestedAction = {
				type: StaticWebsiteActionTypes.FEATURE_REQUEST_REQUESTED
			};
			dispatch(featureRequestRequestedAction);
			try {
				await featureRequest(featureRequestRequest);
				const featureRequestSuccessfulAction: FeatureRequestSuccessfulAction = {
					type: StaticWebsiteActionTypes.FEATURE_REQUEST_SUCCESSFUL
				};
				return dispatch(featureRequestSuccessfulAction);
			} catch (error: any) {
				const featureRequestFailedAction: FeatureRequestFailedAction = {
					type: StaticWebsiteActionTypes.FEATURE_REQUEST_FAILED
				};
				dispatch(featureRequestFailedAction);
				return Promise.reject(error);
			}
		};
	}
};

export default StaticWebsiteActionCreators;
