import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState, BillingPlanData, UserDeviceInformation, AppRouteHash } from '../../Contracts';
import { resetBrowserUrlHash } from '../../Contracts/Util';
import { UserData, UserAggregates, ChargebeeHostedPage, UserBillingPlanData, InvoiceCountAggregates, ChargebeeHostedPageAcknowledgementContent, ChargebeeInvoice, ChargeebeeInvoiceRequest, ChargebeeDownloadInvoiceResponse } from '../../Contracts/User';

import { getUserDeviceInformation, getUser, getUserAggregates, editUser, getBillingPlans, getChargebeeSubscription, getTotalInvoices, getChargebeeInvoices, downloadChargebeeInvoice, getChargebeeHostedPage, getChargebeeUpdateCardPage } from '../../Services/User';

export enum UserActionTypes {
	SHOW_USER_LOADER = 'SHOW_USER_LOADER',
	HIDE_USER_LOADER = 'HIDE_USER_LOADER',
	USER_DEVICE_INFORMATION_REQUESTED = 'USER_DEVICE_INFORMATION_REQUESTED',
	USER_DEVICE_INFORMATION_SUCCESSFUL = 'USER_DEVICE_INFORMATION_SUCCESSFUL',
	USER_DEVICE_INFORMATION_FAILED = 'USER_DEVICE_INFORMATION_FAILED',
	USER_REQUESTED = 'USER_REQUESTED',
	USER_SUCCESSFUL = 'USER_SUCCESSFUL',
	USER_FAILED = 'USER_FAILED',
	USER_AGGREGATES_REQUESTED = 'USER_AGGREGATES_REQUESTED',
	USER_AGGREGATES_SUCCESSFUL = 'USER_AGGREGATES_SUCCESSFUL',
	USER_AGGREGATES_FAILED = 'USER_AGGREGATES_FAILED',
	EDIT_USER_REQUESTED = 'EDIT_USER_REQUESTED',
	EDIT_USER_SUCCESSFUL = 'EDIT_USER_SUCCESSFUL',
	EDIT_USER_FAILED = 'EDIT_USER_FAILED',
	RESET_USER = 'RESET_USER',
	CHARGEBEE_CHECKOUT_PAYMENT_STARTED_ACTION = 'CHARGEBEE_CHECKOUT_PAYMENT_STARTED_ACTION',
	CHARGEBEE_CHECKOUT_PAYMENT_SUCCESSFUL_ACTION = 'CHARGEBEE_CHECKOUT_PAYMENT_SUCCESSFUL_ACTION',
	CHARGEBEE_CHECKOUT_PAYMENT_FAILED_ACTION = 'CHARGEBEE_CHECKOUT_PAYMENT_FAILED_ACTION',
	CHARGEBEE_CHECKOUT_PAYMENT_ABORTED_ACTION = 'CHARGEBEE_CHECKOUT_PAYMENT_ABORTED_ACTION',
	CHARGEBEE_CHECKOUT_CARD_UPDATE_STARTED_ACTION = 'CHARGEBEE_CHECKOUT_CARD_UPDATE_STARTED_ACTION',
	CHARGEBEE_CHECKOUT_CARD_UPDATE_SUCCESSFUL_ACTION = 'CHARGEBEE_CHECKOUT_CARD_UPDATE_SUCCESSFUL_ACTION',
	CHARGEBEE_CHECKOUT_CARD_UPDATE_FAILED_ACTION = 'CHARGEBEE_CHECKOUT_CARD_UPDATE_FAILED_ACTION',
	CHARGEBEE_CHECKOUT_CARD_UPDATE_ABORTED_ACTION = 'CHARGEBEE_CHECKOUT_CARD_UPDATE_ABORTED_ACTION',
	BILLING_PLANS_REQUESTED = 'BILLING_PLANS_REQUESTED',
	BILLING_PLANS_SUCCESSFUL = 'BILLING_PLANS_SUCCESSFUL',
	BILLING_PLANS_FAILED = 'BILLING_PLANS_FAILED',
	CHARGEBEE_SUBSCRIPTION_REQUESTED = 'CHARGEBEE_SUBSCRIPTION_REQUESTED',
	CHARGEBEE_SUBSCRIPTION_SUCCESSFUL = 'CHARGEBEE_SUBSCRIPTION_SUCCESSFUL',
	CHARGEBEE_SUBSCRIPTION_FAILED = 'CHARGEBEE_SUBSCRIPTION_FAILED',
	TOTAL_INVOICES_REQUESTED = 'TOTAL_INVOICES_REQUESTED',
	TOTAL_INVOICES_SUCCESSFUL = 'TOTAL_INVOICES_SUCCESSFUL',
	TOTAL_INVOICES_FAILED = 'TOTAL_INVOICES_FAILED',
	CHARGEBEE_INVOICES_REQUESTED = 'CHARGEBEE_INVOICES_REQUESTED',
	CHARGEBEE_INVOICES_SUCCESSFUL = 'CHARGEBEE_INVOICES_SUCCESSFUL',
	CHARGEBEE_INVOICES_FAILED = 'CHARGEBEE_INVOICES_FAILED',
	CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED = 'CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED',
	CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL = 'CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL',
	CHARGEBEE_INVOICE_DOWNLOAD_FAILED = 'CHARGEBEE_INVOICE_DOWNLOAD_FAILED',
	CHARGEBEE_HOSTED_PAGE_REQUESTED = 'CHARGEBEE_HOSTED_PAGE_REQUESTED',
	CHARGEBEE_HOSTED_PAGE_SUCCESSFUL = 'CHARGEBEE_HOSTED_PAGE_SUCCESSFUL',
	CHARGEBEE_HOSTED_PAGE_FAILED = 'CHARGEBEE_HOSTED_PAGE_FAILED',
	CHARGEBEE_UPDATE_CARD_PAGE_REQUESTED = 'CHARGEBEE_UPDATE_CARD_PAGE_REQUESTED',
	CHARGEBEE_UPDATE_CARD_PAGE_SUCCESSFUL = 'CHARGEBEE_UPDATE_CARD_PAGE_SUCCESSFUL',
	CHARGEBEE_UPDATE_CARD_PAGE_FAILED = 'CHARGEBEE_UPDATE_CARD_PAGE_FAILED',
	UPDATE_BILLING_PLAN_DRAWER_OPENED = 'UPDATE_BILLING_PLAN_DRAWER_OPENED',
	UPDATE_BILLING_PLAN_DRAWER_CLOSED = 'UPDATE_BILLING_PLAN_DRAWER_CLOSED'
}

export interface ShowUserLoaderAction extends Action<UserActionTypes.SHOW_USER_LOADER> { }
export interface HideUserLoaderAction extends Action<UserActionTypes.HIDE_USER_LOADER> { }
export interface UserDeviceInformationRequestedAction extends Action<UserActionTypes.USER_DEVICE_INFORMATION_REQUESTED> { }
export interface UserDeviceInformationSuccessfulAction extends Action<UserActionTypes.USER_DEVICE_INFORMATION_SUCCESSFUL> {
	userDeviceInformation: UserDeviceInformation[];
}
export interface UserDeviceInformationFailedAction extends Action<UserActionTypes.USER_DEVICE_INFORMATION_FAILED> { }
export interface UserRequestedAction extends Action<UserActionTypes.USER_REQUESTED> { }
export interface UserSuccessfulAction extends Action<UserActionTypes.USER_SUCCESSFUL> {
	readonly userData: UserData;
}
export interface UserFailedAction extends Action<UserActionTypes.USER_FAILED> { }
export interface UserAggregatesRequestedAction extends Action<UserActionTypes.USER_AGGREGATES_REQUESTED> { }
export interface UserAggregatesSuccessfulAction extends Action<UserActionTypes.USER_AGGREGATES_SUCCESSFUL> {
	readonly userAggregates: UserAggregates;
}
export interface UserAggregatesFailedAction extends Action<UserActionTypes.USER_AGGREGATES_FAILED> { }
export interface EditUserRequestedAction extends Action<UserActionTypes.EDIT_USER_REQUESTED> { }
export interface EditUserSuccessfulAction extends Action<UserActionTypes.EDIT_USER_SUCCESSFUL> {
	readonly userData: UserData;
}
export interface EditUserFailedAction extends Action<UserActionTypes.EDIT_USER_FAILED> { }
export interface ResetUserAction extends Action<UserActionTypes.RESET_USER> { }
export interface ChargebeeCheckoutPaymentStartedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_STARTED_ACTION> { }
export interface ChargebeeCheckoutPaymentSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_SUCCESSFUL_ACTION> { }
export interface ChargebeeCheckoutPaymentFailedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_FAILED_ACTION> { }
export interface ChargebeeCheckoutPaymentAbortedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_ABORTED_ACTION> { }
export interface ChargebeeCheckoutCardUpdateStartedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_STARTED_ACTION> { }
export interface ChargebeeCheckoutCardUpdateSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_SUCCESSFUL_ACTION> { }
export interface ChargebeeCheckoutCardUpdateFailedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_FAILED_ACTION> { }
export interface ChargebeeCheckoutCardUpdateAbortedAction extends Action<UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_ABORTED_ACTION> { }
export interface BillingPlansRequestedAction extends Action<UserActionTypes.BILLING_PLANS_REQUESTED> { }
export interface BillingPlansSuccessfulAction extends Action<UserActionTypes.BILLING_PLANS_SUCCESSFUL> {
	readonly billingPlanList: BillingPlanData[];
}
export interface BillingPlansFailedAction extends Action<UserActionTypes.BILLING_PLANS_FAILED> { }
export interface ChargebeeSubscriptionRequestedAction extends Action<UserActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED> { }
export interface ChargebeeSubscriptionSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL> {
	readonly chargebeeSubscription: ChargebeeHostedPageAcknowledgementContent;
}
export interface ChargebeeSubscriptionFailedAction extends Action<UserActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED> { }
export interface TotalInvoicesRequestedAction extends Action<UserActionTypes.TOTAL_INVOICES_REQUESTED> { }
export interface TotalInvoicesSuccessfulAction extends Action<UserActionTypes.TOTAL_INVOICES_SUCCESSFUL> {
	readonly totalInvoices: InvoiceCountAggregates;
}
export interface TotalInvoicesFailedAction extends Action<UserActionTypes.TOTAL_INVOICES_FAILED> { }
export interface ChargebeeInvoicesRequestedAction extends Action<UserActionTypes.CHARGEBEE_INVOICES_REQUESTED> { }
export interface ChargebeeInvoicesSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL> {
	readonly chargebeeInvoicesList: ChargebeeInvoice[];
}
export interface ChargebeeInvoicesFailedAction extends Action<UserActionTypes.CHARGEBEE_INVOICES_FAILED> { }
export interface ChargebeeInvoiceDownloadRequestedAction extends Action<UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED> { }
export interface ChargebeeInvoiceDownloadSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL> {
	readonly chargebeeInvoiceDownload: ChargebeeDownloadInvoiceResponse;
}
export interface ChargebeeInvoiceDownloadFailedAction extends Action<UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED> { }
export interface ChargebeeHostedPageRequestedAction extends Action<UserActionTypes.CHARGEBEE_HOSTED_PAGE_REQUESTED> { }
export interface ChargebeeHostedPageSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_HOSTED_PAGE_SUCCESSFUL> {
	readonly chargebeeHostedPage: ChargebeeHostedPage;
}
export interface ChargebeeHostedPageFailedAction extends Action<UserActionTypes.CHARGEBEE_HOSTED_PAGE_FAILED> { }
export interface ChargebeeUpdateCardPageRequestedAction extends Action<UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_REQUESTED> { }
export interface ChargebeeUpdateCardPageSuccessfulAction extends Action<UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_SUCCESSFUL> {
	readonly chargebeeUpdateCardPage: ChargebeeHostedPage;
}
export interface ChargebeeUpdateCardPageFailedAction extends Action<UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_FAILED> { }
export interface UpdateBillingPlanDrawerOpenedAction extends Action<UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_OPENED> { }
export interface UpdateBillingPlanDrawerClosedAction extends Action<UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_CLOSED> { }

export type UserActions =
	| ShowUserLoaderAction
	| HideUserLoaderAction
	| UserDeviceInformationRequestedAction
	| UserDeviceInformationSuccessfulAction
	| UserDeviceInformationFailedAction
	| UserRequestedAction
	| UserSuccessfulAction
	| UserFailedAction
	| UserAggregatesRequestedAction
	| UserAggregatesSuccessfulAction
	| UserAggregatesFailedAction
	| EditUserRequestedAction
	| EditUserSuccessfulAction
	| EditUserFailedAction
	| ResetUserAction
	| BillingPlansRequestedAction
	| BillingPlansSuccessfulAction
	| BillingPlansFailedAction
	| ChargebeeCheckoutPaymentStartedAction
	| ChargebeeCheckoutPaymentSuccessfulAction
	| ChargebeeCheckoutPaymentFailedAction
	| ChargebeeCheckoutPaymentAbortedAction
	| ChargebeeCheckoutCardUpdateStartedAction
	| ChargebeeCheckoutCardUpdateSuccessfulAction
	| ChargebeeCheckoutCardUpdateFailedAction
	| ChargebeeCheckoutCardUpdateAbortedAction
	| ChargebeeSubscriptionRequestedAction
	| ChargebeeSubscriptionSuccessfulAction
	| ChargebeeSubscriptionFailedAction
	| TotalInvoicesRequestedAction
	| TotalInvoicesSuccessfulAction
	| TotalInvoicesFailedAction
	| ChargebeeInvoicesRequestedAction
	| ChargebeeInvoicesSuccessfulAction
	| ChargebeeInvoicesFailedAction
	| ChargebeeInvoiceDownloadRequestedAction
	| ChargebeeInvoiceDownloadSuccessfulAction
	| ChargebeeInvoiceDownloadFailedAction
	| ChargebeeHostedPageRequestedAction
	| ChargebeeHostedPageSuccessfulAction
	| ChargebeeHostedPageFailedAction
	| ChargebeeUpdateCardPageRequestedAction
	| ChargebeeUpdateCardPageSuccessfulAction
	| ChargebeeUpdateCardPageFailedAction
	| UpdateBillingPlanDrawerOpenedAction
	| UpdateBillingPlanDrawerClosedAction;

export interface UserDispatch {
	readonly showUserLoader: ActionCreator<ThunkAction<ShowUserLoaderAction, GlobalState, null, ShowUserLoaderAction>>;
	readonly hideUserLoader: ActionCreator<ThunkAction<HideUserLoaderAction, GlobalState, null, HideUserLoaderAction>>;
	readonly getUserDeviceInformation: ActionCreator<ThunkAction<Promise<UserDeviceInformationSuccessfulAction>, GlobalState, string[], UserDeviceInformationSuccessfulAction>>;
	readonly getUser: ActionCreator<ThunkAction<Promise<UserSuccessfulAction>, GlobalState, string, UserSuccessfulAction>>;
	readonly getUserAggregates: ActionCreator<ThunkAction<Promise<UserAggregatesSuccessfulAction>, GlobalState, string, UserAggregatesSuccessfulAction>>;
	readonly editUser: ActionCreator<ThunkAction<Promise<EditUserSuccessfulAction>, GlobalState, string, EditUserSuccessfulAction>>;
	readonly resetUser: ActionCreator<ThunkAction<ResetUserAction, GlobalState, string, ResetUserAction>>;
	readonly getBillingPlans: ActionCreator<ThunkAction<Promise<BillingPlansSuccessfulAction>, GlobalState, boolean, BillingPlansSuccessfulAction>>;
	readonly chargebeeCheckoutPaymentStarted: ActionCreator<ThunkAction<ChargebeeCheckoutPaymentStartedAction, GlobalState, null, ChargebeeCheckoutPaymentStartedAction>>;
	readonly chargebeeCheckoutPaymentSuccessful: ActionCreator<ThunkAction<ChargebeeCheckoutPaymentSuccessfulAction, GlobalState, null, ChargebeeCheckoutPaymentSuccessfulAction>>;
	readonly chargebeeCheckoutPaymentFailed: ActionCreator<ThunkAction<ChargebeeCheckoutPaymentFailedAction, GlobalState, null, ChargebeeCheckoutPaymentFailedAction>>;
	readonly chargebeeCheckoutPaymentAborted: ActionCreator<ThunkAction<ChargebeeCheckoutPaymentAbortedAction, GlobalState, null, ChargebeeCheckoutPaymentAbortedAction>>;
	readonly chargebeeCheckoutCardUpdateStarted: ActionCreator<ThunkAction<ChargebeeCheckoutCardUpdateStartedAction, GlobalState, null, ChargebeeCheckoutCardUpdateStartedAction>>;
	readonly chargebeeCheckoutCardUpdateSuccessful: ActionCreator<ThunkAction<ChargebeeCheckoutCardUpdateSuccessfulAction, GlobalState, null, ChargebeeCheckoutCardUpdateSuccessfulAction>>;
	readonly chargebeeCheckoutCardUpdateFailed: ActionCreator<ThunkAction<ChargebeeCheckoutCardUpdateFailedAction, GlobalState, null, ChargebeeCheckoutCardUpdateFailedAction>>;
	readonly chargebeeCheckoutCardUpdateAborted: ActionCreator<ThunkAction<ChargebeeCheckoutCardUpdateAbortedAction, GlobalState, null, ChargebeeCheckoutCardUpdateAbortedAction>>;
	readonly getChargebeeSubscription: ActionCreator<ThunkAction<Promise<ChargebeeSubscriptionSuccessfulAction>, GlobalState, string, ChargebeeSubscriptionSuccessfulAction>>;
	readonly getTotalInvoices: ActionCreator<ThunkAction<Promise<TotalInvoicesSuccessfulAction>, GlobalState, string, TotalInvoicesSuccessfulAction>>;
	readonly getChargebeeInvoices: ActionCreator<ThunkAction<Promise<ChargebeeInvoicesSuccessfulAction>, GlobalState, ChargeebeeInvoiceRequest, ChargebeeInvoicesSuccessfulAction>>;
	readonly downloadChargebeeInvoice: ActionCreator<ThunkAction<Promise<ChargebeeInvoiceDownloadSuccessfulAction>, GlobalState, string, ChargebeeInvoiceDownloadSuccessfulAction>>;
	readonly getChargebeeHostedPage: ActionCreator<ThunkAction<Promise<ChargebeeHostedPageSuccessfulAction>, GlobalState, UserBillingPlanData, ChargebeeHostedPageSuccessfulAction>>;
	readonly getChargebeeUpdateCardPage: ActionCreator<ThunkAction<Promise<ChargebeeUpdateCardPageSuccessfulAction>, GlobalState, null, ChargebeeUpdateCardPageSuccessfulAction>>;
	readonly updateBillingPlanDrawerOpened: ActionCreator<ThunkAction<UpdateBillingPlanDrawerOpenedAction, GlobalState, null, UpdateBillingPlanDrawerOpenedAction>>;
	readonly updateBillingPlanDrawerClosed: ActionCreator<ThunkAction<UpdateBillingPlanDrawerClosedAction, GlobalState, null, UpdateBillingPlanDrawerClosedAction>>;
}

export const UserActionCreators: UserDispatch = {
	showUserLoader: () => {
		return (dispatch: Dispatch): ShowUserLoaderAction => {
			const showUserLoaderAction: ShowUserLoaderAction = {
				type: UserActionTypes.SHOW_USER_LOADER
			};
			return dispatch(showUserLoaderAction);
		};
	},
	hideUserLoader: () => {
		return (dispatch: Dispatch): HideUserLoaderAction => {
			const hideUserLoaderAction: HideUserLoaderAction = {
				type: UserActionTypes.HIDE_USER_LOADER
			};
			return dispatch(hideUserLoaderAction);
		};
	},
	getUserDeviceInformation: (userAgents: string[]) => {
		return async (dispatch: Dispatch): Promise<UserDeviceInformationSuccessfulAction> => {
			const userDeviceInformationRequestedAction: UserDeviceInformationRequestedAction = {
				type: UserActionTypes.USER_DEVICE_INFORMATION_REQUESTED
			};
			dispatch(userDeviceInformationRequestedAction);
			try {
				const getUserDeviceInformationResponse = await getUserDeviceInformation(userAgents);
				const userDeviceInformationSuccessfulAction: UserDeviceInformationSuccessfulAction = {
					type: UserActionTypes.USER_DEVICE_INFORMATION_SUCCESSFUL,
					userDeviceInformation: getUserDeviceInformationResponse
				};
				return dispatch(userDeviceInformationSuccessfulAction);
			} catch (error: any) {
				const userDeviceInformationFailedAction: UserDeviceInformationFailedAction = {
					type: UserActionTypes.USER_DEVICE_INFORMATION_FAILED
				};
				dispatch(userDeviceInformationFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getUser: (userId: string) => {
		return async (dispatch: Dispatch): Promise<UserSuccessfulAction> => {
			const userRequestedAction: UserRequestedAction = {
				type: UserActionTypes.USER_REQUESTED
			};
			dispatch(userRequestedAction);
			try {
				const userResponse = await getUser(userId);
				const userSuccessfulAction: UserSuccessfulAction = {
					type: UserActionTypes.USER_SUCCESSFUL,
					userData: userResponse
				};
				return dispatch(userSuccessfulAction);
			} catch (error: any) {
				const userFailedAction: UserFailedAction = {
					type: UserActionTypes.USER_FAILED
				};
				dispatch(userFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getUserAggregates: (userId: string) => {
		return async (dispatch: Dispatch): Promise<UserAggregatesSuccessfulAction> => {
			const userAggregatesRequestedAction: UserAggregatesRequestedAction = {
				type: UserActionTypes.USER_AGGREGATES_REQUESTED
			};
			dispatch(userAggregatesRequestedAction);
			try {
				const userAggregatesResponse = await getUserAggregates(userId);
				const userAggregatesSuccessfulAction: UserAggregatesSuccessfulAction = {
					type: UserActionTypes.USER_AGGREGATES_SUCCESSFUL,
					userAggregates: userAggregatesResponse
				};
				return dispatch(userAggregatesSuccessfulAction);
			} catch (error: any) {
				const userAggregatesFailedAction: UserAggregatesFailedAction = {
					type: UserActionTypes.USER_AGGREGATES_FAILED
				};
				dispatch(userAggregatesFailedAction);
				return Promise.reject(error);
			}
		};
	},
	editUser: (editUserRequest: UserData) => {
		return async (dispatch: Dispatch): Promise<EditUserSuccessfulAction> => {
			const editUserRequestedAction: EditUserRequestedAction = {
				type: UserActionTypes.EDIT_USER_REQUESTED
			};
			dispatch(editUserRequestedAction);
			try {
				const editUserResponse = await editUser(editUserRequest);
				const editUserSuccessfulAction: EditUserSuccessfulAction = {
					type: UserActionTypes.EDIT_USER_SUCCESSFUL,
					userData: editUserResponse
				};
				return dispatch(editUserSuccessfulAction);
			} catch (error: any) {
				const editUserFailedAction: EditUserFailedAction = {
					type: UserActionTypes.EDIT_USER_FAILED
				};
				dispatch(editUserFailedAction);
				return Promise.reject(error);
			}
		};
	},
	resetUser: () => {
		return (dispatch: Dispatch): ResetUserAction => {
			const resetUserAction: ResetUserAction = {
				type: UserActionTypes.RESET_USER
			};
			return dispatch(resetUserAction);
		};
	},
	chargebeeCheckoutPaymentStarted: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutPaymentStartedAction => {
			const chargebeeCheckoutPaymentStartedAction: ChargebeeCheckoutPaymentStartedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_STARTED_ACTION
			};
			window.location.hash = AppRouteHash.ChargebeeCheckoutPayment;
			return dispatch(chargebeeCheckoutPaymentStartedAction);
		};
	},
	chargebeeCheckoutPaymentSuccessful: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutPaymentSuccessfulAction => {
			const chargebeeCheckoutPaymentSuccessfulAction: ChargebeeCheckoutPaymentSuccessfulAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_SUCCESSFUL_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutPaymentSuccessfulAction);
		};
	},
	chargebeeCheckoutPaymentFailed: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutPaymentFailedAction => {
			const chargebeeCheckoutPaymentFailedAction: ChargebeeCheckoutPaymentFailedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_FAILED_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutPaymentFailedAction);
		};
	},
	chargebeeCheckoutPaymentAborted: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutPaymentAbortedAction => {
			const chargebeeCheckoutPaymentAbortedAction: ChargebeeCheckoutPaymentAbortedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_PAYMENT_ABORTED_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutPaymentAbortedAction);
		};
	},
	chargebeeCheckoutCardUpdateStarted: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutCardUpdateStartedAction => {
			const chargebeeCheckoutCardUpdateStartedAction: ChargebeeCheckoutCardUpdateStartedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_STARTED_ACTION
			};
			window.location.hash = AppRouteHash.ChargebeeCheckoutCard;
			return dispatch(chargebeeCheckoutCardUpdateStartedAction);
		};
	},
	chargebeeCheckoutCardUpdateSuccessful: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutCardUpdateSuccessfulAction => {
			const chargebeeCheckoutCardUpdateSuccessfulAction: ChargebeeCheckoutCardUpdateSuccessfulAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_SUCCESSFUL_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutCardUpdateSuccessfulAction);
		};
	},
	chargebeeCheckoutCardUpdateFailed: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutCardUpdateFailedAction => {
			const chargebeeCheckoutCardUpdateFailedAction: ChargebeeCheckoutCardUpdateFailedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_FAILED_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutCardUpdateFailedAction);
		};
	},
	chargebeeCheckoutCardUpdateAborted: () => {
		return (dispatch: Dispatch): ChargebeeCheckoutCardUpdateAbortedAction => {
			const chargebeeCheckoutCardUpdateAbortedAction: ChargebeeCheckoutCardUpdateAbortedAction = {
				type: UserActionTypes.CHARGEBEE_CHECKOUT_CARD_UPDATE_ABORTED_ACTION
			};
			resetBrowserUrlHash();
			return dispatch(chargebeeCheckoutCardUpdateAbortedAction);
		};
	},
	getBillingPlans: (fetchViaClientSdk: boolean) => {
		return async (dispatch: Dispatch): Promise<BillingPlansSuccessfulAction> => {
			const billingPlansRequestedAction: BillingPlansRequestedAction = {
				type: UserActionTypes.BILLING_PLANS_REQUESTED
			};
			dispatch(billingPlansRequestedAction);
			try {
				const billingPlansResponse = await getBillingPlans(fetchViaClientSdk);
				const billingPlansSuccessfulAction: BillingPlansSuccessfulAction = {
					type: UserActionTypes.BILLING_PLANS_SUCCESSFUL,
					billingPlanList: billingPlansResponse
				};
				return dispatch(billingPlansSuccessfulAction);
			} catch (error: any) {
				const billingPlansFailedAction: BillingPlansFailedAction = {
					type: UserActionTypes.BILLING_PLANS_FAILED
				};
				dispatch(billingPlansFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeSubscription: (userId: string) => {
		return async (dispatch: Dispatch): Promise<ChargebeeSubscriptionSuccessfulAction> => {
			const chargebeeSubscriptionRequestedAction: ChargebeeSubscriptionRequestedAction = {
				type: UserActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED
			};
			dispatch(chargebeeSubscriptionRequestedAction);
			try {
				const chargebeeSubscriptionResponse = await getChargebeeSubscription(userId);
				const chargebeeSubscriptionSuccessfulAction: ChargebeeSubscriptionSuccessfulAction = {
					type: UserActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL,
					chargebeeSubscription: chargebeeSubscriptionResponse
				};
				return dispatch(chargebeeSubscriptionSuccessfulAction);
			} catch (error: any) {
				const chargebeeSubscriptionFailedAction: ChargebeeSubscriptionFailedAction = {
					type: UserActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED
				};
				dispatch(chargebeeSubscriptionFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getTotalInvoices: (userId: string) => {
		return async (dispatch: Dispatch): Promise<TotalInvoicesSuccessfulAction> => {
			const totalInvoicesRequestedAction: TotalInvoicesRequestedAction = {
				type: UserActionTypes.TOTAL_INVOICES_REQUESTED
			};
			dispatch(totalInvoicesRequestedAction);
			try {
				const totalInvoicesResponse = await getTotalInvoices(userId);
				const totalInvoicesSuccessfulAction: TotalInvoicesSuccessfulAction = {
					type: UserActionTypes.TOTAL_INVOICES_SUCCESSFUL,
					totalInvoices: totalInvoicesResponse
				};
				return dispatch(totalInvoicesSuccessfulAction);
			} catch (error: any) {
				const totalInvoicesFailedAction: TotalInvoicesFailedAction = {
					type: UserActionTypes.TOTAL_INVOICES_FAILED
				};
				dispatch(totalInvoicesFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeInvoices: (chargebeeInvoicesRequest: ChargeebeeInvoiceRequest) => {
		return async (dispatch: Dispatch): Promise<ChargebeeInvoicesSuccessfulAction> => {
			const chargebeeInvoicesRequestedAction: ChargebeeInvoicesRequestedAction = {
				type: UserActionTypes.CHARGEBEE_INVOICES_REQUESTED
			};
			dispatch(chargebeeInvoicesRequestedAction);
			try {
				const chargebeeInvoicesResponse = await getChargebeeInvoices(chargebeeInvoicesRequest);
				const chargebeeInvoicesSuccessfulAction: ChargebeeInvoicesSuccessfulAction = {
					type: UserActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL,
					chargebeeInvoicesList: chargebeeInvoicesResponse
				};
				return dispatch(chargebeeInvoicesSuccessfulAction);
			} catch (error: any) {
				const chargebeeInvoicesFailedAction: ChargebeeInvoicesFailedAction = {
					type: UserActionTypes.CHARGEBEE_INVOICES_FAILED
				};
				dispatch(chargebeeInvoicesFailedAction);
				return Promise.reject(error);
			}
		};
	},
	downloadChargebeeInvoice: (invoiceId: string) => {
		return async (dispatch: Dispatch): Promise<ChargebeeInvoiceDownloadSuccessfulAction> => {
			const chargebeeInvoiceDownloadRequestedAction: ChargebeeInvoiceDownloadRequestedAction = {
				type: UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED
			};
			dispatch(chargebeeInvoiceDownloadRequestedAction);
			try {
				const chargebeeInvoiceDownloadResponse = await downloadChargebeeInvoice(invoiceId);
				const chargebeeInvoiceDownloadSuccessfulAction: ChargebeeInvoiceDownloadSuccessfulAction = {
					type: UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL,
					chargebeeInvoiceDownload: chargebeeInvoiceDownloadResponse
				};
				return dispatch(chargebeeInvoiceDownloadSuccessfulAction);
			} catch (error: any) {
				const chargebeeInvoiceDownloadFailedAction: ChargebeeInvoiceDownloadFailedAction = {
					type: UserActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED
				};
				dispatch(chargebeeInvoiceDownloadFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeHostedPage: (userBillingPlan: UserBillingPlanData) => {
		return async (dispatch: Dispatch): Promise<ChargebeeHostedPageSuccessfulAction> => {
			const chargebeeHostedPageRequestedAction: ChargebeeHostedPageRequestedAction = {
				type: UserActionTypes.CHARGEBEE_HOSTED_PAGE_REQUESTED
			};
			dispatch(chargebeeHostedPageRequestedAction);
			try {
				const chargebeeHostedPageResponse = await getChargebeeHostedPage(userBillingPlan);
				const chargebeeHostedPageSuccessfulAction: ChargebeeHostedPageSuccessfulAction = {
					type: UserActionTypes.CHARGEBEE_HOSTED_PAGE_SUCCESSFUL,
					chargebeeHostedPage: chargebeeHostedPageResponse
				};
				return dispatch(chargebeeHostedPageSuccessfulAction);
			} catch (error: any) {
				const chargebeeHostedPageFailedAction: ChargebeeHostedPageFailedAction = {
					type: UserActionTypes.CHARGEBEE_HOSTED_PAGE_FAILED
				};
				dispatch(chargebeeHostedPageFailedAction);
				return Promise.reject(error);
			}
		};
	},
	getChargebeeUpdateCardPage: () => {
		return async (dispatch: Dispatch): Promise<ChargebeeUpdateCardPageSuccessfulAction> => {
			const chargebeeUpdateCardPageRequestedAction: ChargebeeUpdateCardPageRequestedAction = {
				type: UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_REQUESTED
			};
			dispatch(chargebeeUpdateCardPageRequestedAction);
			try {
				const chargebeeUpdateCardPageResponse = await getChargebeeUpdateCardPage();
				const chargebeeUpdateCardPageSuccessfulAction: ChargebeeUpdateCardPageSuccessfulAction = {
					type: UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_SUCCESSFUL,
					chargebeeUpdateCardPage: chargebeeUpdateCardPageResponse
				};
				return dispatch(chargebeeUpdateCardPageSuccessfulAction);
			} catch (error: any) {
				const chargebeeUpdateCardPageFailedAction: ChargebeeUpdateCardPageFailedAction = {
					type: UserActionTypes.CHARGEBEE_UPDATE_CARD_PAGE_FAILED
				};
				dispatch(chargebeeUpdateCardPageFailedAction);
				return Promise.reject(error);
			}
		};
	},
	updateBillingPlanDrawerOpened: () => {
		return (dispatch: Dispatch): UpdateBillingPlanDrawerOpenedAction => {
			const updateBillingPlanDrawerOpenedAction: UpdateBillingPlanDrawerOpenedAction = {
				type: UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_OPENED
			};
			window.location.hash = AppRouteHash.BillingPlanDrawer;
			return dispatch(updateBillingPlanDrawerOpenedAction);
		};
	},
	updateBillingPlanDrawerClosed: () => {
		return (dispatch: Dispatch): UpdateBillingPlanDrawerClosedAction => {
			const updateBillingPlanDrawerClosedAction: UpdateBillingPlanDrawerClosedAction = {
				type: UserActionTypes.UPDATE_BILLING_PLAN_DRAWER_CLOSED
			};
			resetBrowserUrlHash();
			return dispatch(updateBillingPlanDrawerClosedAction);
		};
	}
};

export default UserActionCreators;
