import LinkifyIt from 'linkify-it';

interface Link {
	readonly href: string;
	readonly indices: number[];
}

const linkify = LinkifyIt({ fuzzyEmail: false, fuzzyIP: false, fuzzyLink: true });

export const extractLinksWithIndices = (text: string): Link[] => {
	if (!text || !linkify.pretest(text)) {
		return new Array<Link>();
	}
	const links = linkify.match(text);
	return links ? links.map((link) => { return { href: link.text, indices: [link.index, link.lastIndex] }; }) : new Array<Link>();
};
