import { Reducer } from 'redux';

import { UploadFile } from 'antd/lib/upload/interface';

import { SocialPlatform } from '../../Contracts';
import { Post, PostFileResponse } from '../../Contracts/Post';

import { PostActionTypes, PostActions } from '../../Actions/Post';

export interface PostState {
	readonly isLoading: boolean;
	readonly isSuggestedPostMediaLoading: boolean;
	readonly postsList: Post[];
	readonly suggestedPostMediaList: UploadFile<PostFileResponse>[];
	readonly currentActiveTab: SocialPlatform;
	readonly createPostDrawerOpen: boolean;
	readonly editPostDrawerOpen: boolean;
}

const initialState: PostState = {
	isLoading: false,
	isSuggestedPostMediaLoading: false,
	postsList: new Array<Post>(),
	suggestedPostMediaList: new Array<UploadFile>(),
	currentActiveTab: SocialPlatform.Facebook,
	createPostDrawerOpen: true,
	editPostDrawerOpen: true
};

export const PostReducer: Reducer<PostState, PostActions> = (
	state: PostState = initialState,
	action: PostActions
): PostState => {
	switch (action.type) {
		case PostActionTypes.SHOW_POST_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case PostActionTypes.HIDE_POST_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case PostActionTypes.SHOW_SUGGESTED_POST_MEDIA_LOADER: {
			return {
				...state,
				isSuggestedPostMediaLoading: true
			};
		}
		case PostActionTypes.HIDE_SUGGESTED_POST_MEDIA_LOADER: {
			return {
				...state,
				isSuggestedPostMediaLoading: false
			};
		}
		case PostActionTypes.CURRENT_ACTIVE_TAB_CHANGED: {
			return {
				...state,
				currentActiveTab: action.currentActiveTab
			};
		}
		case PostActionTypes.POST_REQUESTED: {
			return state;
		}
		case PostActionTypes.POST_SUCCESSFUL: {
			const postsList = state.postsList.filter((post) => post.id !== action.post.id);
			const updatePostIndex = state.postsList.findIndex((post) => post.id === action.post.id);
			if (updatePostIndex > -1) {
				postsList.splice(updatePostIndex, 0, action.post);
			}
			return {
				...state,
				postsList
			};
		}
		case PostActionTypes.POST_FAILED: {
			return state;
		}
		case PostActionTypes.POSTS_REQUESTED: {
			return state;
		}
		case PostActionTypes.POSTS_SUCCESSFUL: {
			return {
				...state,
				postsList: action.postsList
			};
		}
		case PostActionTypes.POSTS_FAILED: {
			return state;
		}
		case PostActionTypes.POSTS_RESET: {
			return {
				...state,
				postsList: new Array<Post>()
			};
		}
		case PostActionTypes.CREATE_POST_REQUESTED: {
			return state;
		}
		case PostActionTypes.CREATE_POST_SUCCESSFUL: {
			return {
				...state,
				postsList: state.postsList.concat([action.createPostRequest])
			};
		}
		case PostActionTypes.CREATE_POST_FAILED: {
			return state;
		}
		case PostActionTypes.EDIT_POST_REQUESTED:
		case PostActionTypes.PUBLISH_POST_REQUESTED: {
			return state;
		}
		case PostActionTypes.EDIT_POST_SUCCESSFUL:
		case PostActionTypes.PUBLISH_POST_SUCCESSFUL: {
			const postsList = state.postsList.filter((post) => post.id !== action.editPostRequest.id);
			const updatePostIndex = state.postsList.findIndex((post) => post.id === action.editPostRequest.id);
			if (updatePostIndex > -1) {
				postsList.splice(updatePostIndex, 0, action.editPostRequest);
			}
			return {
				...state,
				postsList
			};
		}
		case PostActionTypes.EDIT_POST_FAILED:
		case PostActionTypes.PUBLISH_POST_FAILED: {
			return state;
		}
		case PostActionTypes.SUGGEST_POST_MEDIA_REQUESTED: {
			return state;
		}
		case PostActionTypes.SUGGEST_POST_MEDIA_SUCCESSFUL: {
			return {
				...state,
				suggestedPostMediaList: action.suggestedPostMediaList
			};
		}
		case PostActionTypes.SUGGEST_POST_MEDIA_RESET: {
			return {
				...state,
				suggestedPostMediaList: new Array<UploadFile>()
			};
		}
		case PostActionTypes.SUGGEST_POST_MEDIA_FAILED: {
			return state;
		}
		case PostActionTypes.DELETE_POST_REQUESTED: {
			return state;
		}
		case PostActionTypes.DELETE_POST_SUCCESSFUL: {
			return {
				...state,
				postsList: state.postsList.filter((post) => post.id !== action.deletePostRequest.id)
			};
		}
		case PostActionTypes.DELETE_POST_FAILED: {
			return state;
		}
		case PostActionTypes.CREATE_POST_DRAWER_OPENED: {
			return {
				...state,
				createPostDrawerOpen: true
			};
		}
		case PostActionTypes.CREATE_POST_DRAWER_CLOSED: {
			return {
				...state,
				createPostDrawerOpen: false
			};
		}
		case PostActionTypes.EDIT_POST_DRAWER_OPENED: {
			return {
				...state,
				editPostDrawerOpen: true
			};
		}
		case PostActionTypes.EDIT_POST_DRAWER_CLOSED: {
			return {
				...state,
				editPostDrawerOpen: false
			};
		}
		default: {
			return state;
		}
	}
};

export default PostReducer;
