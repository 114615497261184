import { createStore, combineReducers, applyMiddleware, compose, Action, Middleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { getApps as firebaseGetApps, getApp as firebaseGetApp } from 'firebase/app';
import { isSupported as firebaseAnalyticsIsSupported, getAnalytics as firebaseGetAnalytics, logEvent as firebaseAnalyticsLogEvent } from 'firebase/analytics';

import StaticWebsiteReducer from './Reducers/StaticWebsite';
import UtilReducer from './Reducers/Util';
import AdminReducer from './Reducers/Admin';
import LoginReducer from './Reducers/Login';
import UserReducer from './Reducers/User';
import AccountsReducer from './Reducers/Accounts';
import PostReducer from './Reducers/Post';
import SettingsReducer from './Reducers/Settings';

import { isKnownWebCrawler } from './Contracts/Util';

const LogTag: string = 'ReduxStore';

export const generateReduxActionValue = (action: Action): string => {
	try {
		return JSON.stringify(action);
	} catch (error: any) {
		return action.type;
	}
};

export const GoogleAnalyticsReduxLogger: Middleware = (_store) => next => async (action: Action) => {
	try {
		if (firebaseGetApps().length > 0 && !isKnownWebCrawler(navigator.userAgent) && await firebaseAnalyticsIsSupported()) {
			const actionType: string = action.type;
			if (actionType?.length > 0 && actionType !== 'persist/PERSIST' && actionType !== 'persist/REHYDRATE') {
				firebaseAnalyticsLogEvent(firebaseGetAnalytics(firebaseGetApp()), action.type, {
					event_category: 'Redux',
					event_action: action.type,
					event_value: generateReduxActionValue(action),
					event_label: action.type,
					non_interaction: true
				});
			}
		}
	} catch (error: any) {
		console.error(LogTag, 'GoogleAnalyticsReduxLogger', error);
	}
	return next(action);
};

const middleWare: Middleware[] = [ReduxThunk, GoogleAnalyticsReduxLogger];
const composeEnhancers = compose;
const appliedMiddleware = composeEnhancers(applyMiddleware(...middleWare));

export const RootReducer = combineReducers({
	util: UtilReducer,
	admin: AdminReducer,
	staticWebsite: StaticWebsiteReducer,
	login: LoginReducer,
	user: UserReducer,
	accounts: AccountsReducer,
	post: PostReducer,
	settings: SettingsReducer
});

export const ReduxStore = createStore(persistReducer({ key: process.env.REACT_APP_COMPANY_NAME?.toLowerCase() ?? 'react', storage, blacklist: ['util', 'admin'] }, RootReducer), appliedMiddleware);
export const Persistor = persistStore(ReduxStore);
