import { Component } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import './Loader.scss';

import { GlobalState } from '../../../../Contracts';

import LoginActionCreators from '../../../../Actions/Login';
import UserActionCreators from '../../../../Actions/User';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		logout: () => dispatch(LoginActionCreators.logout()),
		resetUser: () => dispatch(UserActionCreators.resetUser())
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps> {
	readonly postLoginLoader?: boolean;
}

export class Loader extends Component<Props> {

	postLoginTimeout: NodeJS.Timeout | null = null;

	componentDidMount() {
		if (this.props.postLoginLoader) {
			this.postLoginTimeout = setTimeout(() => {
				this.props.logout();
				this.props.resetUser();
				window.location.reload();
			}, 30000);
		}
	}

	componentWillUnmount() {
		if (this.postLoginTimeout) {
			clearTimeout(this.postLoginTimeout);
		}
	}

	render() {
		return (
			<div className="loading-container">
				<div className="loading">
					<div className="finger finger-1">
						<div className="finger-item">
							<span />
							<i />
						</div>
					</div>
					<div className="finger finger-2">
						<div className="finger-item">
							<span />
							<i />
						</div>
					</div>
					<div className="finger finger-3">
						<div className="finger-item">
							<span />
							<i />
						</div>
					</div>
					<div className="finger finger-4">
						<div className="finger-item">
							<span />
							<i />
						</div>
					</div>
					<div className="last-finger">
						<div className="last-finger-item">
							<i />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(Loader);
