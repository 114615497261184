import { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Typography } from 'antd';

import './CookiePolicyPage.scss';

import { AppRoutes, GlobalState, AppRouteParams, AppRouteState } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps> { }

export class CookiePolicyPage extends Component<Props> {

	render() {
		return (
			<div className="static-website-cookie-policy-page-container">
				<Helmet>
					<title>Cookie Policy</title>
				</Helmet>
				<Typography>
					<Typography.Title level={1}>Cookie policy</Typography.Title>
					<Typography.Paragraph>Last updated: December 20th, 2020</Typography.Paragraph>
					<Typography.Title level={2}>Important definitions</Typography.Title>
					<Typography.Paragraph>
						<ol>
							<li>
								<Typography.Paragraph>For all legal purposes, Schedulify shall mean ("Company", "we," "us" or "our")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>When you use any of our services (the "Service" or "Services"), which include your access to and use of the <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> website as well as any other media form, media channel, application, mobile website or mobile application, and other services related, linked, or otherwise connected thereto (collectively, the "Site" or "Website")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>OnMyCanvas Technology Solutions LLP, is doing business as Schedulify</Typography.Paragraph>
							</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Title level={2}>Introduction</Typography.Title>
					<Typography.Paragraph>This Cookie Policy explains how {process.env.REACT_APP_COMPANY_NAME} ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our website at <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.</Typography.Paragraph>
					<Typography.Paragraph>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</Typography.Paragraph>
					<Typography.Title level={2}>What are cookies?</Typography.Title>
					<Typography.Paragraph>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</Typography.Paragraph>
					<Typography.Paragraph>Cookies set by the website owner (in this case, {process.env.REACT_APP_COMPANY_NAME}) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</Typography.Paragraph>
					<Typography.Title level={2}>Why do we use cookies?</Typography.Title>
					<Typography.Paragraph>We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our "Website" to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our "Website" for advertising, analytics and other purposes. This is described in more detail below.</Typography.Paragraph>
					<Typography.Title level={2}>How can I control cookies?</Typography.Title>
					<Typography.Paragraph>You have the right to decide whether to accept the cookies or to not use our Services. Our Site and Services strictly require the usage of cookies.</Typography.Paragraph>
					<Typography.Paragraph>If you have set your web browser controls to refuse cookies, our Site will not load. You should visit your browser's help menu for more information.</Typography.Paragraph>
					<Typography.Paragraph>If you use our Site, you consent to our usage of your cookies.</Typography.Paragraph>
					<Typography.Paragraph>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit <a href="https://optout.aboutads.info" target="_blank" rel="noopener noreferrer nofollow">https://optout.aboutads.info</a> or <a href="https://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer nofollow">https://www.youronlinechoices.com</a>.</Typography.Paragraph>
					<Typography.Title level={2}>What about other tracking technologies, like web beacons?</Typography.Title>
					<Typography.Paragraph>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</Typography.Paragraph>
					<Typography.Title level={2}>Do you use Flash cookies or Local Shared Objects?</Typography.Title>
					<Typography.Paragraph>No. We do not.</Typography.Paragraph>
					<Typography.Title level={2}>Do you serve targeted advertising?</Typography.Title>
					<Typography.Paragraph>No. We do not.</Typography.Paragraph>
					<Typography.Title level={2}>How often will you update this Cookie Policy?</Typography.Title>
					<Typography.Paragraph>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</Typography.Paragraph>
					<Typography.Paragraph>The date at the top of this Cookie Policy indicates when it was last updated.</Typography.Paragraph>
					<Typography.Title level={2}>Where can I get further information?</Typography.Title>
					<Typography.Paragraph>If you have any questions about our use of cookies or other tracking technologies, do not hesitate to contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
				</Typography>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(CookiePolicyPage));
