import axios, { AxiosResponse } from 'axios';

import { postRequest } from '../Backend';

import { ContactUs, BugReport, FeatureRequest } from '../../Contracts/StaticWebsite';

const contactUsUrl = '/staticWebsite/contact-form/contact-us';
const bugReportUrl = '/staticWebsite/contact-form/bug-report';
const featureRequestUrl = '/staticWebsite/contact-form/feature-request';

export const contactUs = async (contactUsRequest: ContactUs): Promise<ContactUs> => {
	try {
		const response: AxiosResponse<ContactUs> = await axios(await postRequest(contactUsUrl, contactUsRequest, true, false, false, false, {}));
		return response.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const bugReport = async (bugReportRequest: BugReport): Promise<BugReport> => {
	try {
		const response: AxiosResponse<BugReport> = await axios(await postRequest(bugReportUrl, bugReportRequest, true, false, false, false, {}));
		return response.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const featureRequest = async (featureRequestRequest: FeatureRequest): Promise<FeatureRequest> => {
	try {
		const response: AxiosResponse<FeatureRequest> = await axios(await postRequest(featureRequestUrl, featureRequestRequest, true, false, false, false, {}));
		return response.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};
