import { Component, ReactText, ReactNode } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Layout, Modal, Menu, Spin, message as Message } from 'antd';

import {
	FireOutlined as FireOutlinedIcon,
	HomeOutlined as HomeOutlinedIcon,
	CalendarOutlined as CalendarOutlinedIcon,
	UsergroupAddOutlined as UsergroupAddOutlinedIcon,
	SettingOutlined as SettingOutlinedIcon,
	LogoutOutlined as LogoutOutlinedIcon,
	CameraOutlined as CameraOutlinedIcon,
	UserOutlined as UserOutlinedIcon,
	CreditCardOutlined as CreditCardOutlinedIcon,
	NotificationOutlined as NotificationOutlinedIcon,
	CustomerServiceOutlined as CustomerServiceOutlinedIcon,
	DoubleLeftOutlined as DoubleLeftOutlinedIcon,
	DoubleRightOutlined as DoubleRightOutlinedIcon
} from '@ant-design/icons';

import Image from '../UI/Image';
import SocialIcon from '../UI/SocialIcon';

import { GlobalState, AppRoutes, AppRouteParams, AppRouteState, DefaultPrivateRoute, SocialPlatform, AppRouteHash } from '../../../Contracts';
import { getServerErrorMessage, getGravatarProfilePhotoUrl, capitalizeFirstLetter, capitalizeAfterUnderscore, isLocalhost } from '../../../Contracts/Util';
import { UserRole } from '../../../Contracts/User';
import { SettingsActiveTab } from '../../../Contracts/Settings';

import LoginActionCreators from '../../../Actions/Login';
import UserActionCreators from '../../../Actions/User';
import PostActionCreators from '../../../Actions/Post';
import AccountsActionCreators from '../../../Actions/Accounts';
import SettingsActionCreators from '../../../Actions/Settings';

const LogTag: string = 'Logout Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isAppLoading: state.admin.isLoading || state.accounts.isLoading || state.post.isLoading || state.login.isLoading || state.user.isLoading,
		isAppDrawerOpen: state.post.createPostDrawerOpen || state.post.editPostDrawerOpen || state.user.updateBillingPlanDrawerOpen,
		userData: state.user.userData,
		browserWindowWidth: state.util.browserWindowWidth,
		colorScheme: state.util.colorScheme,
		createPostCurrentActiveTab: state.post.currentActiveTab,
		accountsCurrentActiveTab: state.accounts.currentActiveTab,
		settingsCurrentActiveTab: state.settings.currentActiveTab
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		logout: () => dispatch(LoginActionCreators.logout()),
		resetUser: () => dispatch(UserActionCreators.resetUser()),
		createPostCurrentActiveTabChanged: (socialPlatform: SocialPlatform) => dispatch(PostActionCreators.currentActiveTabChanged(socialPlatform)),
		accountsCurrentActiveTabChanged: (socialPlatform: SocialPlatform) => dispatch(AccountsActionCreators.currentActiveTabChanged(socialPlatform)),
		settingsCurrentActiveTabChanged: (settingsActiveTab: SettingsActiveTab) => dispatch(SettingsActionCreators.currentActiveTabChanged(settingsActiveTab))
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
	readonly siderCollapsed: boolean;
	readonly siderWidth: ReactText;
	readonly siderCollapsedWidth: ReactText;
	readonly onSiderBreakpoint: (siderBreakpointBroken: boolean) => void;
	readonly toggleSiderCollapsible: () => void;
	readonly toggleFreshchatWidget: () => void;
}

export class Sider extends Component<Props> {

	logout = () => {
		const modal = Modal.confirm({
			title: 'Logout',
			content: 'Are you sure you want to logout?',
			centered: true,
			closable: true,
			maskClosable: true,
			keyboard: true,
			onOk: async () => {
				modal.update({ closable: false, maskClosable: false, keyboard: false, okButtonProps: { loading: true, disabled: true }, cancelButtonProps: { disabled: true } });
				try {
					await this.props.logout();
					this.props.resetUser();
					this.props.history.push(AppRoutes.Login);
				} catch (error: any) {
					console.error(LogTag, 'Logout', error);
					Message.error(getServerErrorMessage(error), 3);
				}
			}
		});
	}

	closeSiderCollapsible = () => {
		if (!this.props.siderCollapsed) {
			this.props.toggleSiderCollapsible();
		}
	}

	toggleFreshchatWidget = () => {
		this.props.toggleFreshchatWidget();
	}

	renderCreatePostSubMenuItems = (): ReactNode[] => {
		const createPostSubMenuItems = [
			<Menu.Item key={`${AppRoutes.CreatePost}#${SocialPlatform.Facebook}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.CreatePost}#${SocialPlatform.Facebook}`} onClick={() => this.props.createPostCurrentActiveTabChanged(SocialPlatform.Facebook)}>
					<SocialIcon platform={SocialPlatform.Facebook} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Facebook)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.CreatePost}#${SocialPlatform.Twitter}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.CreatePost}#${SocialPlatform.Twitter}`} onClick={() => this.props.createPostCurrentActiveTabChanged(SocialPlatform.Twitter)}>
					<SocialIcon platform={SocialPlatform.Twitter} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Twitter)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.CreatePost}#${SocialPlatform.LinkedIn}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.CreatePost}#${SocialPlatform.LinkedIn}`} onClick={() => this.props.createPostCurrentActiveTabChanged(SocialPlatform.LinkedIn)}>
					<SocialIcon platform={SocialPlatform.LinkedIn} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.LinkedIn)}</span>
				</Link>
			</Menu.Item>
		];
		if (isLocalhost(this.props.userData?.email)) {
			createPostSubMenuItems.splice(2, 0, (
				<Menu.Item key={`${AppRoutes.CreatePost}#${SocialPlatform.Instagram}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
					<Link to={`${AppRoutes.CreatePost}#${SocialPlatform.Instagram}`} onClick={() => this.props.createPostCurrentActiveTabChanged(SocialPlatform.Instagram)}>
						<SocialIcon platform={SocialPlatform.Instagram} />
						<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Instagram)}</span>
					</Link>
				</Menu.Item>
			));
		}
		return createPostSubMenuItems;
	}

	renderAccountsSubMenuItems = (): ReactNode[] => {
		const accountsSubMenuItems = [
			<Menu.Item key={`${AppRoutes.Accounts}#${SocialPlatform.Facebook}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Accounts}#${SocialPlatform.Facebook}`} onClick={() => this.props.accountsCurrentActiveTabChanged(SocialPlatform.Facebook)}>
					<SocialIcon platform={SocialPlatform.Facebook} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Facebook)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.Accounts}#${SocialPlatform.Twitter}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Accounts}#${SocialPlatform.Twitter}`} onClick={() => this.props.accountsCurrentActiveTabChanged(SocialPlatform.Twitter)}>
					<SocialIcon platform={SocialPlatform.Twitter} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Twitter)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.Accounts}#${SocialPlatform.LinkedIn}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Accounts}#${SocialPlatform.LinkedIn}`} onClick={() => this.props.accountsCurrentActiveTabChanged(SocialPlatform.LinkedIn)}>
					<SocialIcon platform={SocialPlatform.LinkedIn} />
					<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.LinkedIn)}</span>
				</Link>
			</Menu.Item>
		];
		if (isLocalhost(this.props.userData?.email)) {
			accountsSubMenuItems.splice(2, 0, (
				<Menu.Item key={`${AppRoutes.Accounts}#${SocialPlatform.Instagram}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
					<Link to={`${AppRoutes.Accounts}#${SocialPlatform.Instagram}`} onClick={() => this.props.accountsCurrentActiveTabChanged(SocialPlatform.Instagram)}>
						<SocialIcon platform={SocialPlatform.Instagram} />
						<span className="nav-text">{capitalizeFirstLetter(SocialPlatform.Instagram)}</span>
					</Link>
				</Menu.Item>
			));
		}
		return accountsSubMenuItems;
	}

	renderSettingsSubMenuItems = (): ReactNode[] => {
		return [
			<Menu.Item key={`${AppRoutes.Settings}#${SettingsActiveTab.Profile}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Settings}#${SettingsActiveTab.Profile}`} onClick={() => this.props.settingsCurrentActiveTabChanged(SettingsActiveTab.Profile)}>
					<UserOutlinedIcon />
					<span className="nav-text">{capitalizeAfterUnderscore(SettingsActiveTab.Profile)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.Settings}#${SettingsActiveTab.Billing}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Settings}#${SettingsActiveTab.Billing}`} onClick={() => this.props.settingsCurrentActiveTabChanged(SettingsActiveTab.Billing)}>
					<CreditCardOutlinedIcon />
					<span className="nav-text">{capitalizeAfterUnderscore(SettingsActiveTab.Billing)}</span>
				</Link>
			</Menu.Item>,
			<Menu.Item key={`${AppRoutes.Settings}#${SettingsActiveTab.Notification}`} title={null} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
				<Link to={`${AppRoutes.Settings}#${SettingsActiveTab.Notification}`} onClick={() => this.props.settingsCurrentActiveTabChanged(SettingsActiveTab.Notification)}>
					<NotificationOutlinedIcon />
					<span className="nav-text">{capitalizeAfterUnderscore(SettingsActiveTab.Notification)}</span>
				</Link>
			</Menu.Item>
		];
	}

	getCurrentActiveTab = (): string => {
		switch (this.props.location.pathname) {
			case AppRoutes.CreatePost: {
				return this.props.createPostCurrentActiveTab;
			}
			case AppRoutes.Accounts: {
				return this.props.accountsCurrentActiveTab;
			}
			case AppRoutes.Settings: {
				return this.props.settingsCurrentActiveTab;
			}
			default: {
				return '';
			}
		}
	}

	render() {
		return (
			<Layout.Sider
				trigger={null}
				collapsible={true}
				collapsed={this.props.siderCollapsed}
				width={this.props.siderWidth}
				collapsedWidth={this.props.siderCollapsedWidth}
				breakpoint="sm"
				defaultCollapsed={true}
				onBreakpoint={this.props.onSiderBreakpoint}
				theme={this.props.colorScheme}
				className="dashboard-page-sider"
				style={{
					flex: `0 0 ${this.props.siderCollapsed ? this.props.siderCollapsedWidth : this.props.siderWidth}px`,
					maxWidth: this.props.siderCollapsed ? this.props.siderCollapsedWidth : this.props.siderWidth,
					minWidth: this.props.siderCollapsed ? this.props.siderCollapsedWidth : this.props.siderWidth,
					width: this.props.siderCollapsed ? this.props.siderCollapsedWidth : this.props.siderWidth
				}}
			>
				<div className="dashboard-page-sider-logo">
					<Image src={this.props.userData?.photo?.length > 0 ? this.props.userData?.photo : getGravatarProfilePhotoUrl(this.props.userData?.email, 200)} alt="gravatar" className="gravatar" loader={<Spin spinning={true} className="spin-center" />} />
				</div>
				<Menu mode={(this.props.browserWindowWidth < 576) ? 'inline' : 'vertical'} theme={this.props.colorScheme} selectedKeys={[this.props.location.pathname, `${this.props.location.pathname}#${this.getCurrentActiveTab()}`]} defaultSelectedKeys={[DefaultPrivateRoute, `${DefaultPrivateRoute}#${this.getCurrentActiveTab()}`]} multiple={false} triggerSubMenuAction="hover" forceSubMenuRender={false} className="dashboard-page-sider-menu">
					{(this.props.userData?.role === UserRole.Admin) && (
						<Menu.Item key={AppRoutes.Admin} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : 'Admin'} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
							<Link to={AppRoutes.Admin}>
								<FireOutlinedIcon />
								<span className="nav-text">Admin</span>
							</Link>
						</Menu.Item>
					)}
					<Menu.Item key={AppRoutes.Home} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : 'Home'} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
						<Link to={AppRoutes.Home}>
							<HomeOutlinedIcon />
							<span className="nav-text">Home</span>
						</Link>
					</Menu.Item>
					<Menu.Item key={AppRoutes.Calendar} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : 'Calendar'} onClick={this.closeSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
						<Link to={AppRoutes.Calendar}>
							<CalendarOutlinedIcon />
							<span className="nav-text">Calendar</span>
						</Link>
					</Menu.Item>
					<Menu.SubMenu key={AppRoutes.CreatePost} title="Create Post" icon={<CameraOutlinedIcon />} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen} popupClassName="dashboard-sider-submenu">
						{(this.props.siderCollapsed) ? (
							<Menu.ItemGroup key={AppRoutes.CreatePost} title="Create Post">
								{this.renderCreatePostSubMenuItems()}
							</Menu.ItemGroup>
						) : (
							this.renderCreatePostSubMenuItems()
						)}
					</Menu.SubMenu>
					<Menu.SubMenu key={AppRoutes.Accounts} title="Accounts" icon={<UsergroupAddOutlinedIcon />} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen} popupClassName="dashboard-sider-submenu">
						{(this.props.siderCollapsed) ? (
							<Menu.ItemGroup key={AppRoutes.Accounts} title="Accounts">
								{this.renderAccountsSubMenuItems()}
							</Menu.ItemGroup>
						) : (
							this.renderAccountsSubMenuItems()
						)}
					</Menu.SubMenu>
					<Menu.SubMenu key={AppRoutes.Settings} title="Settings" icon={<SettingOutlinedIcon />} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen} popupClassName="dashboard-sider-submenu">
						{(this.props.siderCollapsed) ? (
							<Menu.ItemGroup key={AppRoutes.Settings} title="Settings">
								{this.renderSettingsSubMenuItems()}
							</Menu.ItemGroup>
						) : (
							this.renderSettingsSubMenuItems()
						)}
					</Menu.SubMenu>
				</Menu>
				<div className="dashboard-page-sider-menu-extra-container">
					<Menu mode={(this.props.browserWindowWidth < 576) ? 'inline' : 'vertical'} theme={this.props.colorScheme} selectedKeys={[]} defaultSelectedKeys={[]} multiple={false} triggerSubMenuAction="hover" forceSubMenuRender={false} className="dashboard-page-sider-menu-extra">
						<Menu.Item key={AppRouteHash.FreshchatWidget} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : 'Support'} onClick={this.toggleFreshchatWidget} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
							<CustomerServiceOutlinedIcon />
							<span className="nav-text">Support</span>
						</Menu.Item>
						<Menu.Item key={AppRoutes.Login} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : 'Logout'} onClick={this.logout} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen}>
							<LogoutOutlinedIcon />
							<span className="nav-text">Logout</span>
						</Menu.Item>
						<Menu.Item key={AppRouteHash.DashboardSiderMenu} title={(this.props.isAppLoading || this.props.isAppDrawerOpen) ? null : (this.props.isAppDrawerOpen ? 'Close Menu' : 'Open Menu')} onClick={this.props.toggleSiderCollapsible} disabled={this.props.isAppLoading || this.props.isAppDrawerOpen} className="align-center">
							{(this.props.siderCollapsed) ? <DoubleRightOutlinedIcon /> : <DoubleLeftOutlinedIcon />}
						</Menu.Item>
					</Menu>
				</div>
			</Layout.Sider>
		);
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sider));
