import { Key } from 'react';

import { FieldPath as FirestoreFieldPath, WhereFilterOp as FirestoreWhereFilterOp } from 'firebase/firestore/lite';

export enum FireStoreCollections {
	Aggregates = 'aggregates',
	Users = 'users',
	Accounts = 'accounts',
	Posts = 'posts',
	BillingPlans = 'pricing_plans',
	Subscriptions = 'subscriptions',
	Invoices = 'invoices',
	Transactions = 'transactions',
	ContactForms = 'contact_forms'
}

export interface FirebasePaginationRequest<T> {
	readonly limit: number;
	readonly navigation?: 'next' | 'back';
	readonly lastEntity?: T | null;
}

export interface FirebaseFilterFormValues {
	readonly ['name.full_name']: string;
	readonly email: string;
	readonly ['billing_plan.id']: string[];
}

export interface FirebaseFilterRequest {
	fieldPath: string | FirestoreFieldPath;
	opStr: FirestoreWhereFilterOp;
	value: Key | Key[];
}
