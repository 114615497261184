import { Component, createRef as CreateRef } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Form, Row, Col, Input, Button, Checkbox, Typography, message as Message } from 'antd';

import {
	UserOutlined as UserOutlinedIcon,
	MailOutlined as MailOutlinedIcon,
	LockOutlined as LockOutlinedIcon
} from '@ant-design/icons';

import ReCaptcha from 'react-google-recaptcha';

import { AppRoutes, DefaultErrorMessage, GlobalState, PasswordRegex } from '../../../../../Contracts';
import { getServerErrorMessage } from '../../../../../Contracts/Util';
import { SignupFormValues, SignupRequest } from '../../../../../Contracts/Login';

import LoginActionCreators from '../../../../../Actions/Login';

const LogTag: string = 'Signup Form Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isLoginLoading: state.login.isLoading,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showLoginLoader: () => dispatch(LoginActionCreators.showLoginLoader()),
		hideLoginLoader: () => dispatch(LoginActionCreators.hideLoginLoader()),
		signup: (signupRequest: SignupRequest) => dispatch(LoginActionCreators.signup(signupRequest))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
	readonly afterSubmit?: () => void;
	readonly showLoginPage: () => void;
	readonly showForgotPasswordPage: () => void;
}

export class SignupForm extends Component<Props> {

	reCaptchaRef = CreateRef<ReCaptcha>();

	signup = async (signupFormValues: SignupFormValues) => {
		this.props.showLoginLoader();
		try {
			const googleRecaptchaToken = await this.reCaptchaRef.current?.executeAsync();
			if (googleRecaptchaToken && googleRecaptchaToken?.length > 0) {
				const signupRequest: SignupRequest = { ...signupFormValues, googleRecaptchaToken };
				await this.props.signup(signupRequest);
				this.props.hideLoginLoader();
				if (this.props.afterSubmit) {
					this.props.afterSubmit();
				}
			} else {
				Message.error(DefaultErrorMessage, 3);
			}
		} catch (error: any) {
			console.error(LogTag, 'Signup', error, signupFormValues.email);
			Message.error(getServerErrorMessage(error), 3);
		} finally {
			this.props.hideLoginLoader();
		}
	}

	render() {
		return (
			<Form layout="vertical" onFinish={(signupFormValues) => this.signup(signupFormValues as SignupFormValues)} className="signup-form" initialValues={{ terms: true }}>
				<Row justify="center" align="middle" gutter={24}>
					<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
						<Form.Item label="First Name" name="first_name" hasFeedback={true} rules={[
							{ required: true, type: 'string', whitespace: true, message: 'Please enter your first name' }
						]}>
							<Input id="first_name" name="first_name" type="text" placeholder="Enter first name" disabled={this.props.isLoginLoading} prefix={<UserOutlinedIcon />} autoComplete="given-name" />
						</Form.Item>
					</Col>
					<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
						<Form.Item label="Last Name" name="last_name" hasFeedback={true} rules={[
							{ required: true, type: 'string', whitespace: true, message: 'Please enter your last name' }
						]}>
							<Input id="last_name" name="last_name" type="text" placeholder="Enter last name" disabled={this.props.isLoginLoading} prefix={<UserOutlinedIcon />} autoComplete="family-name" />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item label="Email" name="email" hasFeedback={true} rules={[
					{ required: true, type: 'email', whitespace: true, message: 'Please enter your email' }
				]}>
					<Input id="email" name="email" type="email" placeholder="Enter email" prefix={<MailOutlinedIcon />} disabled={this.props.isLoginLoading} autoComplete="email" />
				</Form.Item>
				<Form.Item label="Password" name="password" hasFeedback={true} rules={[
					{ required: true, type: 'string', whitespace: true, message: 'Please enter your password' },
					{ pattern: PasswordRegex, message: 'Allowed - min 8 characters, one letter, one number' }
				]}>
					<Input.Password id="password" name="password" type="password" placeholder="Enter password" disabled={this.props.isLoginLoading} prefix={<LockOutlinedIcon />} autoComplete="new-password" />
				</Form.Item>
				<Form.Item label={null} name="terms" valuePropName="checked" rules={[
					{ required: true, type: 'boolean', message: 'Please accept terms and conditions' },
					{ validator: (_ruleObject, checked: boolean) => checked ? Promise.resolve() : Promise.reject('Please accept terms and conditions') }
				]}>
					<Checkbox id="terms" name="terms" disabled={this.props.isLoginLoading} className="margin-top-24px">I have read and understood {process.env.REACT_APP_COMPANY_NAME}'s <a href={`${process?.env.REACT_APP_WEBSITE_HOST}${AppRoutes.TermsConditions}`} target="_blank" rel="noreferrer noopener">Terms</a> and <a href={`${process?.env.REACT_APP_WEBSITE_HOST}${AppRoutes.Privacy}`} target="_blank" rel="noreferrer noopener">Privacy Policy</a></Checkbox>
				</Form.Item>
				<div className="margin-top-32px">
					<Row justify="center" align="middle">
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
							<Button type="primary" htmlType="submit" disabled={this.props.isLoginLoading} loading={this.props.isLoginLoading} icon={<MailOutlinedIcon />} className="signup-button full-width">Sign Up</Button>
						</Col>
					</Row>
				</div>
				<Row justify="center" align="middle" gutter={32} className="margin-top-12px">
					<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="link text-underline align-left" onClick={this.props.showForgotPasswordPage}>
						<Typography.Text className="link">Forgot password?</Typography.Text>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="link text-underline align-right" onClick={this.props.showLoginPage}>
						<Typography.Text className="link">Sign In</Typography.Text>
					</Col>
				</Row>
				<div className="display-none">
					<ReCaptcha ref={this.reCaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string} theme={this.props.colorScheme} />
				</div>
			</Form>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
