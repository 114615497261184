import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState, SocialPlatform } from '../../Contracts';
import { OAuthAccount, AccountsRequest, DeleteAccountRequest } from '../../Contracts/Accounts';

import { getAccounts, getAccount, deleteAccount } from '../../Services/Accounts';

export enum AccountsActionTypes {
	SHOW_ACCOUNTS_LOADER = 'SHOW_ACCOUNTS_LOADER',
	HIDE_ACCOUNTS_LOADER = 'HIDE_ACCOUNTS_LOADER',
	CURRENT_ACTIVE_TAB_CHANGED = 'ACCOUNTS_CURRENT_ACTIVE_TAB_CHANGED',
	ACCOUNTS_REQUESTED = 'ACCOUNTS_REQUESTED',
	ACCOUNTS_SUCCESSFUL = 'ACCOUNTS_SUCCESSFUL',
	ACCOUNTS_FAILED = 'ACCOUNTS_FAILED',
	ACCOUNTS_RESET = 'ACCOUNTS_RESET',
	ACCOUNT_REQUESTED = 'ACCOUNT_REQUESTED',
	ACCOUNT_SUCCESSFUL = 'ACCOUNT_SUCCESSFUL',
	ACCOUNT_FAILED = 'ACCOUNT_FAILED',
	DELETE_ACCOUNT_REQUESTED = 'DELETE_ACCOUNT_REQUESTED',
	DELETE_ACCOUNT_SUCCESSFUL = 'DELETE_ACCOUNT_SUCCESSFUL',
	DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED'
}

export interface ShowAccountsLoaderAction extends Action<AccountsActionTypes.SHOW_ACCOUNTS_LOADER> { }
export interface HideAccountsLoaderAction extends Action<AccountsActionTypes.HIDE_ACCOUNTS_LOADER> { }
export interface CurrentActiveTabChangedAction extends Action<AccountsActionTypes.CURRENT_ACTIVE_TAB_CHANGED> {
	readonly currentActiveTab: SocialPlatform;
}
export interface AccountsRequestedAction extends Action<AccountsActionTypes.ACCOUNTS_REQUESTED> { }
export interface AccountsSuccessfulAction extends Action<AccountsActionTypes.ACCOUNTS_SUCCESSFUL> {
	readonly accountsList: OAuthAccount[];
}
export interface AccountsFailedAction extends Action<AccountsActionTypes.ACCOUNTS_FAILED> { }
export interface AccountsResetAction extends Action<AccountsActionTypes.ACCOUNTS_RESET> { }
export interface AccountRequestedAction extends Action<AccountsActionTypes.ACCOUNT_REQUESTED> { }
export interface AccountSuccessfulAction extends Action<AccountsActionTypes.ACCOUNT_SUCCESSFUL> {
	readonly account: OAuthAccount;
}
export interface AccountFailedAction extends Action<AccountsActionTypes.ACCOUNT_FAILED> { }
export interface DeleteAccountRequestedAction extends Action<AccountsActionTypes.DELETE_ACCOUNT_REQUESTED> { }
export interface DeleteAccountSuccessfulAction extends Action<AccountsActionTypes.DELETE_ACCOUNT_SUCCESSFUL> {
	readonly deleteAccountRequest: OAuthAccount;
}
export interface DeleteAccountFailedAction extends Action<AccountsActionTypes.DELETE_ACCOUNT_FAILED> { }

export type AccountsActions =
	| ShowAccountsLoaderAction
	| HideAccountsLoaderAction
	| CurrentActiveTabChangedAction
	| AccountsRequestedAction
	| AccountsSuccessfulAction
	| AccountsFailedAction
	| AccountsResetAction
	| AccountRequestedAction
	| AccountSuccessfulAction
	| AccountFailedAction
	| DeleteAccountRequestedAction
	| DeleteAccountSuccessfulAction
	| DeleteAccountFailedAction;

export interface AccountsDispatch {
	readonly showAccountsLoader: ActionCreator<ThunkAction<ShowAccountsLoaderAction, GlobalState, null, ShowAccountsLoaderAction>>;
	readonly hideAccountsLoader: ActionCreator<ThunkAction<HideAccountsLoaderAction, GlobalState, null, HideAccountsLoaderAction>>;
	readonly currentActiveTabChanged: ActionCreator<ThunkAction<CurrentActiveTabChangedAction, GlobalState, SocialPlatform, CurrentActiveTabChangedAction>>;
	readonly getAccounts: ActionCreator<ThunkAction<Promise<AccountsSuccessfulAction>, GlobalState, AccountsRequest, AccountsSuccessfulAction>>;
	readonly resetAccounts: ActionCreator<ThunkAction<AccountsResetAction, GlobalState, null, AccountsResetAction>>;
	readonly getAccount: ActionCreator<ThunkAction<Promise<AccountSuccessfulAction>, GlobalState, string, AccountSuccessfulAction>>;
	readonly deleteAccount: ActionCreator<ThunkAction<Promise<DeleteAccountSuccessfulAction>, GlobalState, DeleteAccountRequest, DeleteAccountSuccessfulAction>>;
}

export const AccountsActionCreators: AccountsDispatch = {
	showAccountsLoader: () => {
		return (dispatch: Dispatch): ShowAccountsLoaderAction => {
			const showAccountsLoaderAction: ShowAccountsLoaderAction = {
				type: AccountsActionTypes.SHOW_ACCOUNTS_LOADER
			};
			return dispatch(showAccountsLoaderAction);
		};
	},
	hideAccountsLoader: () => {
		return (dispatch: Dispatch): HideAccountsLoaderAction => {
			const hideAccountsLoaderAction: HideAccountsLoaderAction = {
				type: AccountsActionTypes.HIDE_ACCOUNTS_LOADER
			};
			return dispatch(hideAccountsLoaderAction);
		};
	},
	currentActiveTabChanged: (currentActiveTab: SocialPlatform) => {
		return (dispatch: Dispatch): CurrentActiveTabChangedAction => {
			const currentActiveTabChangedAction: CurrentActiveTabChangedAction = {
				type: AccountsActionTypes.CURRENT_ACTIVE_TAB_CHANGED,
				currentActiveTab
			};
			window.location.hash = currentActiveTab;
			return dispatch(currentActiveTabChangedAction);
		};
	},
	getAccounts: (accountsRequest: AccountsRequest) => {
		return async (dispatch: Dispatch): Promise<AccountsSuccessfulAction> => {
			const accountsRequestedAction: AccountsRequestedAction = {
				type: AccountsActionTypes.ACCOUNTS_REQUESTED
			};
			dispatch(accountsRequestedAction);
			try {
				const accountsResponse = await getAccounts(accountsRequest);
				const accountsSuccessfulAction: AccountsSuccessfulAction = {
					type: AccountsActionTypes.ACCOUNTS_SUCCESSFUL,
					accountsList: accountsResponse
				};
				return dispatch(accountsSuccessfulAction);
			} catch (error: any) {
				const accountsFailedAction: AccountsFailedAction = {
					type: AccountsActionTypes.ACCOUNTS_FAILED
				};
				dispatch(accountsFailedAction);
				return Promise.reject(error);
			}
		};
	},
	resetAccounts: () => {
		return (dispatch: Dispatch): AccountsResetAction => {
			const accountsResetAction: AccountsResetAction = {
				type: AccountsActionTypes.ACCOUNTS_RESET
			};
			return dispatch(accountsResetAction);
		};
	},
	getAccount: (accountId: string) => {
		return async (dispatch: Dispatch): Promise<AccountSuccessfulAction> => {
			const accountRequestedAction: AccountRequestedAction = {
				type: AccountsActionTypes.ACCOUNT_REQUESTED
			};
			dispatch(accountRequestedAction);
			try {
				const accountResponse = await getAccount(accountId);
				const accountSuccessfulAction: AccountSuccessfulAction = {
					type: AccountsActionTypes.ACCOUNT_SUCCESSFUL,
					account: accountResponse
				};
				return dispatch(accountSuccessfulAction);
			} catch (error: any) {
				const accountFailedAction: AccountFailedAction = {
					type: AccountsActionTypes.ACCOUNT_FAILED
				};
				dispatch(accountFailedAction);
				return Promise.reject(error);
			}
		};
	},
	deleteAccount: (deleteAccountRequest: DeleteAccountRequest) => {
		return async (dispatch: Dispatch): Promise<DeleteAccountSuccessfulAction> => {
			const deleteAccountRequestedAction: DeleteAccountRequestedAction = {
				type: AccountsActionTypes.DELETE_ACCOUNT_REQUESTED
			};
			dispatch(deleteAccountRequestedAction);
			try {
				await deleteAccount(deleteAccountRequest);
				const deleteAccountSuccessfulAction: DeleteAccountSuccessfulAction = {
					type: AccountsActionTypes.DELETE_ACCOUNT_SUCCESSFUL,
					deleteAccountRequest: deleteAccountRequest.account
				};
				return dispatch(deleteAccountSuccessfulAction);
			} catch (error: any) {
				const deleteAccountFailedAction: DeleteAccountFailedAction = {
					type: AccountsActionTypes.DELETE_ACCOUNT_FAILED
				};
				dispatch(deleteAccountFailedAction);
				return Promise.reject(error);
			}
		};
	}
};

export default AccountsActionCreators;
