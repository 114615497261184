import { Name, Address, BillingPlanCycle, SocialPlatform } from '../';
import { FireStoreCollections, FirebasePaginationRequest, FirebaseFilterRequest } from '../Util/Firebase';
import { IANATimeZone } from '../Util/TimeZone';
import { PostStatus } from '../Post';

export enum UserRole {
	Admin = 'admin',
	Primary = 'primary',
	Secondary = 'secondary'
}

export interface UserBillingPlanData {
	readonly id: string;
	readonly billing_cycle: BillingPlanCycle;
}

export interface UserNotificationToken {
	readonly userAgent: string;
	readonly firebase_messaging_token: string;
}

export interface UserData {
	readonly id: string;
	readonly role: UserRole;
	readonly email: string;
	readonly name: Name;
	readonly phone: string;
	readonly country_code: string;
	readonly photo: string;
	readonly billing_plan: UserBillingPlanData;
	readonly company_name: string;
	readonly job_title: string;
	readonly iana_timezone: IANATimeZone;
	readonly address: Address;
	readonly freshchat_restore_id: string | null;
	readonly ipAddress: string | null;
	readonly notification_token: UserNotificationToken[];
	readonly notification_preferences: UserNotificationPreferences;
}

export type UserAggregatesUnion = UserCountAggregates | SocialPlatformAggregates | SocialPlatformPostStatusAggregates | InvoiceCountAggregates | TransactionCountAggregates;

export interface UserCountAggregates {
	readonly count: number;
}
export interface SocialPlatformAggregates {
	readonly [SocialPlatform.Facebook]: number;
	readonly [SocialPlatform.Twitter]: number;
	readonly [SocialPlatform.Instagram]: number;
	readonly [SocialPlatform.LinkedIn]: number;
}

export interface PostStatusAggregates {
	readonly [PostStatus.Scheduled]: number;
	readonly [PostStatus.Published]: number;
	readonly [PostStatus.Failed]: number;
}

export interface SocialPlatformPostStatusAggregates {
	readonly [SocialPlatform.Facebook]: PostStatusAggregates;
	readonly [SocialPlatform.Twitter]: PostStatusAggregates;
	readonly [SocialPlatform.Instagram]: PostStatusAggregates;
	readonly [SocialPlatform.LinkedIn]: PostStatusAggregates;
}

export interface InvoiceCountAggregates {
	readonly count: number;
}

export interface TransactionCountAggregates {
	readonly count: number;
}

export interface UserAggregates {
	readonly [FireStoreCollections.Accounts]: SocialPlatformAggregates;
	readonly [FireStoreCollections.Posts]: SocialPlatformPostStatusAggregates;
	readonly [FireStoreCollections.Invoices]: InvoiceCountAggregates;
	readonly [FireStoreCollections.Transactions]: TransactionCountAggregates;
}

export const EmptyUserAggregates: UserAggregates = {
	[FireStoreCollections.Accounts]: {
		[SocialPlatform.Facebook]: 0,
		[SocialPlatform.Twitter]: 0,
		[SocialPlatform.Instagram]: 0,
		[SocialPlatform.LinkedIn]: 0
	},
	[FireStoreCollections.Posts]: {
		[SocialPlatform.Facebook]: {
			[PostStatus.Scheduled]: 0,
			[PostStatus.Published]: 0,
			[PostStatus.Failed]: 0
		},
		[SocialPlatform.Twitter]: {
			[PostStatus.Scheduled]: 0,
			[PostStatus.Published]: 0,
			[PostStatus.Failed]: 0
		},
		[SocialPlatform.Instagram]: {
			[PostStatus.Scheduled]: 0,
			[PostStatus.Published]: 0,
			[PostStatus.Failed]: 0
		},
		[SocialPlatform.LinkedIn]: {
			[PostStatus.Scheduled]: 0,
			[PostStatus.Published]: 0,
			[PostStatus.Failed]: 0
		}
	},
	[FireStoreCollections.Invoices]: {
		count: 0
	},
	[FireStoreCollections.Transactions]: {
		count: 0
	}
};

export interface UserNotificationPreferences {
	readonly promotional: boolean;
	readonly monthly_digest: boolean;
	readonly newsletters: boolean;
	readonly push: boolean;
}

export interface UserDataFormValues {
	readonly first_name: string;
	readonly last_name: string;
	readonly phone: string;
	readonly country_code: string;
	readonly company_name: string;
	readonly job_title: string;
	readonly iana_timezone: IANATimeZone;
	readonly address: Address;
}

export interface ChargebeeHostedPageResponse {
	readonly hosted_page: ChargebeeHostedPage;
}

export interface ChargebeeHostedPage {
	readonly id: string;
	readonly type: 'checkout_new' | 'checkout_existing' | 'update_payment_method' | 'manage_payment_sources' | 'collect_now' | 'extend_subscription' | 'checkout_gift' | 'claim_gift';
	readonly url: string;
	readonly state: 'created' | 'requested' | 'succeeded' | 'cancelled' | 'acknowledged';
	readonly embed: boolean;
	readonly created_at: number;
	readonly expires_at: number;
}

export interface ChargebeeHostedPageAcknowledgementResponse {
	readonly hosted_page: {
		readonly content: ChargebeeHostedPageAcknowledgementContent;
	};
}

export interface ChargebeeHostedPageAcknowledgementContent {
	readonly subscription: ChargebeeSubscription;
	readonly customer: ChargebeeCustomer;
	readonly card?: ChargebeeCard;
	readonly invoice?: ChargebeeInvoice;
	readonly unbilled_charges?: ChargebeeUnbilledCharge;
}

export interface ChargebeeSubscription {
	readonly id: string;
	readonly customer_id: string;
	readonly activated_at: number;
	readonly billing_period: number;
	readonly billing_period_unit: 'day' | 'week' | 'month' | 'year';
	readonly created_at: number;
	readonly auto_collection: 'on' | 'off';
	readonly currency_code: string;
	readonly deleted: boolean;
	readonly created_from_ip: string;
	readonly due_invoices_count: number;
	readonly due_since: number;
	readonly has_scheduled_changes: boolean;
	readonly mrr: number;
	readonly next_billing_at: number;
	readonly object: string;
	readonly plan_amount: number;
	readonly plan_free_quantity: number;
	readonly plan_id: string;
	readonly plan_quantity: number;
	readonly plan_unit_price: number;
	readonly setup_fee: number;
	readonly resource_version: number;
	readonly started_at: number;
	readonly start_date: number;
	readonly end_date: number;
	readonly trial_start: number;
	readonly trial_end: number;
	readonly current_term_start: number;
	readonly current_term_end: number;
	readonly pause_date: number;
	readonly resume_date: number;
	readonly cancelled_at: number;
	readonly cancel_reason: 'not_paid' | 'no_card' | 'fraud_review_failed' | 'non_compliant_eu_customer' | 'tax_calculation_failed' | 'currency_incompatible_with_gateway' | 'non_compliant_customer';
	readonly remaining_billing_cycles: number;
	readonly po_number: string;
	readonly payment_source_id: string;
	readonly status: 'future' | 'in_trial' | 'active' | 'non_renewing' | 'paused' | 'cancelled';
	readonly total_dues: number;
	readonly base_currency_code: string;
	readonly updated_at: number;
}

export interface ChargebeeCustomer {
	readonly id: string;
	readonly first_name: string;
	readonly full_name: string;
	readonly email: string;
	readonly phone: string;
	readonly company: string;
	readonly vat_number: string;
	readonly vat_number_validated_time: number;
	readonly vat_number_status: 'valid' | 'invalid' | 'not_validated' | 'undetermined';
	readonly allow_direct_debit: boolean;
	readonly auto_collection: 'on' | 'off';
	readonly card_status: string;
	readonly created_at: number;
	readonly deleted: boolean;
	readonly created_from_ip: string;
	readonly taxability: 'taxable' | 'exempt';
	readonly is_location_valid: boolean;
	readonly locale: string;
	readonly consolidated_invoicing: boolean;
	readonly excess_payments: number;
	readonly net_term_days: number;
	readonly object: string;
	readonly pii_cleared: string;
	readonly preferred_currency_code: string;
	readonly promotional_credits: number;
	readonly refundable_credits: number;
	readonly resource_version: number;
	readonly unbilled_charges: number;
	readonly updated_at: number;
}

export interface ChargebeeCard {
	readonly customer_id: string;
	readonly ip_address: string;
	readonly masked_number: string;
	readonly payment_source_id: string;
	readonly status: 'valid' | 'expiring' | 'expired';
	readonly gateway: 'chargebee' | 'stripe' | 'paypal_pro' | 'hdfc' | 'pin' | 'authorize_net' | 'braintree' | 'eway' | 'eway_rapid' | 'wepay' | 'worldpay' | 'balanced_payments' | 'beanstream' | 'bluepay' | 'elavon' | 'first_data_global' | 'migs' | 'nmi' | 'ogone' | 'paymill' | 'paypal_payflow_pro' | 'sage_pay' | 'tco' | 'wirecard' | 'amazon_payments' | 'paypal_express_checkout' | 'gocardless' | 'adyen' | 'orbital' | 'moneris_us' | 'moneris' | 'bluesnap' | 'cybersource' | 'vantiv' | 'checkout_com' | 'not_applicable';
	readonly gateway_account_id: string;
	readonly ref_tx_id: string;
	readonly first_name: string;
	readonly last_name: string;
	readonly iin: string;
	readonly last4: string;
	readonly card_type: 'visa' | 'mastercard' | 'american_express' | 'discover' | 'jcb' | 'diners_club' | 'other';
	readonly funding_type: 'credit' | 'debit' | 'prepaid' | 'not_known';
	readonly expiry_month: number;
	readonly expiry_year: number;
	readonly issuing_country: string;
	readonly billing_addr1: string;
	readonly billing_addr2: string;
	readonly billing_city: string;
	readonly billing_state: string;
	readonly billing_state_code: string;
	readonly billing_country: string;
	readonly billing_zip: string;
	readonly created_at: number;
	readonly deleted: boolean;
	readonly updated_at: number;
}

export interface ChargebeeInvoice {
	readonly id: string;
	readonly customer_id: string;
	readonly subscription_id: string;
	readonly amount_adjusted: number;
	readonly amount_due: number;
	readonly amount_paid: number;
	readonly amount_to_collect: number;
	readonly base_currency_code: string;
	readonly credits_applied: number;
	readonly currency_code: string;
	readonly net_term_days: number;
	readonly recurring: boolean;
	readonly resource_version: number;
	readonly round_off_amount: number;
	readonly status: 'paid' | 'posted' | 'payment_due' | 'not_paid' | 'voided' | 'pending';
	readonly sub_total: number;
	readonly tax: number;
	readonly term_finalized: boolean;
	readonly total: number;
	readonly write_off_amount: number;
	readonly exchange_rate: number;
	readonly first_invoice: boolean;
	readonly has_advance_charges: boolean;
	readonly billing_address: { readonly first_name: string; readonly last_name: string; readonly validation_status: 'validated' | 'not_validated'; };
	readonly line_items: { readonly id: string; readonly is_taxed: boolean; readonly item_level_discount_amount: number; readonly pricing_model: 'flat_fee'; readonly quantity: number; readonly subscription_id: string; readonly tax_amount: number; readonly tax_exempt_reason: 'tax_not_configured'; readonly amount: number; readonly customer_id: number; readonly date_from: number; readonly date_to: number; readonly description: string; readonly discount_amount: number; readonly entity_id: string; readonly entity_type: 'plan'; readonly entity_description: string; }[];
	readonly linked_payments: { readonly applied_amount: number; readonly applied_at: number; readonly txn_amount: number; readonly txn_date: number; readonly txn_id: string; readonly txn_status: 'success'; }[];
	readonly date: number;
	readonly paid_at: number;
	readonly due_date: number;
	readonly created_at: number;
	readonly deleted: boolean;
	readonly updated_at: number;
}

export interface ChargebeeUnbilledCharge {
	readonly id: string;
	readonly customer_id: string;
	readonly subscription_id: string;
	readonly date_from: number;
	readonly date_to: number;
	readonly unit_amount: number;
	readonly pricing_model: 'flat_fee';
	readonly quantity: number;
	readonly amount: string;
	readonly currency_code: string;
	readonly discount_amount: string;
	readonly description: string;
	readonly entity_type: 'plan';
	readonly entity_id: string;
	readonly is_voided: boolean;
	readonly voided_at: number;
	readonly unit_amount_in_decimal: string;
	readonly quantity_in_decimal: string;
	readonly amount_in_decimal: string;
	readonly deleted: boolean;
}

export interface ChargebeeTransaction {
	readonly id: string;
	readonly customer_id: string;
	readonly subscription_id: string;
	readonly gateway_account_id: string;
	readonly payment_source_id: string;
	readonly payment_method: 'card' | 'cash' | 'check' | 'chargeback' | 'bank_transfer' | 'amazon_payments' | 'paypal_express_checkout' | 'direct_debit' | 'alipay' | 'unionpay' | 'apple_pay' | 'wechat_pay' | 'ach_credit' | 'sepa_credit' | 'ideal' | 'google_pay' | 'sofort' | 'bancontact' | 'sofort' | 'giropay' | 'dotpay' | 'other';
	readonly reference_number: string;
	readonly gateway: 'chargebee' | 'stripe' | 'paypal_pro' | 'hdfc' | 'pin' | 'authorize_net' | 'braintree' | 'eway' | 'eway_rapid' | 'wepay' | 'worldpay' | 'balanced_payments' | 'beanstream' | 'bluepay' | 'elavon' | 'first_data_global' | 'migs' | 'nmi' | 'ogone' | 'paymill' | 'paypal_payflow_pro' | 'sage_pay' | 'tco' | 'wirecard' | 'amazon_payments' | 'paypal_express_checkout' | 'gocardless' | 'adyen' | 'orbital' | 'moneris_us' | 'moneris' | 'bluesnap' | 'cybersource' | 'vantiv' | 'checkout_com' | 'not_applicable';
	readonly type: 'authorization' | 'payment' | 'refund' | 'payment_reversal';
	readonly date: number;
	readonly settled_at: string;
	readonly currency_code: string;
	readonly amount: string;
	readonly id_at_gateway: string;
	readonly status: 'in_progress' | 'success' | 'voided' | 'failure' | 'timeout' | 'needs_attention';
	readonly fraud_flag: 'safe' | 'suspicious' | 'fraudulent';
	readonly initiator_type: 'customer' | 'merchant';
	readonly three_d_secure: boolean;
	readonly authorization_reason: 'blocking_funds' | 'verification';
	readonly error_code: string;
	readonly error_text: string;
	readonly voided_at: number;
	readonly resource_version: number;
	readonly updated_at: number;
	readonly fraud_reason: string;
	readonly amount_unused: number;
	readonly masked_card_number: string;
	readonly reference_transaction_id: string;
	readonly refunded_txn_id: string;
	readonly reference_authorization_id: string;
	readonly amount_capturable: number;
	readonly reversal_transaction_id: string;
	readonly deleted: boolean;
	readonly linked_invoices?: ChargebeeInvoice[];
	readonly linked_credit_notes?: {
		readonly cn_id: string;
		readonly applied_amount: number;
		readonly applied_at: number;
		readonly cn_reason_code: 'write_off' | 'subscription_change' | 'subscription_cancellation' | 'subscription_pause';
		readonly cn_create_reason_code: string;
		readonly cn_date: number;
		readonly cn_total: number;
		readonly cn_status: 'adjusted' | 'refunded' | 'refund_due' | 'voided';
		readonly cn_reference_invoice_id: string;
	}[];
	readonly linked_payments?: {
		readonly id: string;
		readonly date: number;
		readonly amount: string;
		readonly status: 'in_progress' | 'success' | 'voided' | 'failure' | 'timeout' | 'needs_attention';
	}[];
}

export interface ChargeebeeInvoiceRequest extends FirebasePaginationRequest<ChargebeeInvoice> {
	readonly userId: string;
	firebaseFilters?: FirebaseFilterRequest[];
}

export interface ChargebeeDownloadInvoiceResponse {
	readonly download: {
		readonly download_url: string
	};
}

export enum ChargebeeCheckoutStatus {
	Aborted = 'aborted',
	Error = 'error',
	Success = 'success'
}
