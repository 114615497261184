import { Component, createRef as CreateRef } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Form, Typography, Row, Col, Input, Button, Spin, message as Message } from 'antd';
import { FormInstance } from 'antd/lib/form';

import ReCaptcha from 'react-google-recaptcha';

import './ContactFormPage.scss';

import { DefaultErrorMessage, GlobalState } from '../../../Contracts';
import { getServerErrorMessage } from '../../../Contracts/Util';
import { ContactUsFormValues, ContactUs, ContactFormType, ContactFormStatus } from '../../../Contracts/StaticWebsite';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

const LogTag: string = 'ContacUsPage Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isStaticWebsiteLoading: state.staticWebsite.isLoading,
		userData: state.user.userData,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.showStaticWebsiteLoader()),
		hideStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.hideStaticWebsiteLoader()),
		contactUs: (contactUsRequest: ContactUs) => dispatch(StaticWebsiteActionCreators.contactUs(contactUsRequest)),
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly formSubmitted: boolean;
}

export class ContactUsPage extends Component<Props, State> {

	state: State = {
		formSubmitted: false
	};

	formRef = CreateRef<FormInstance>();
	reCaptchaRef = CreateRef<ReCaptcha>();

	contactUs = async (contactUsFormValues: ContactUsFormValues) => {
		if (this.state.formSubmitted) {
			Message.info('We have received your request. Please give us some time to work on it before submitting another request', 3);
		} else {
			this.props.showStaticWebsiteLoader();
			try {
				const googleRecaptchaToken = await this.reCaptchaRef.current?.executeAsync();
				if (googleRecaptchaToken && googleRecaptchaToken?.length > 0) {
					const contactUsRequest: ContactUs = {
						...contactUsFormValues,
						userId: this.props.userData?.id,
						type: ContactFormType.ContactUs,
						status: ContactFormStatus.Open,
						googleRecaptchaToken
					};
					await this.props.contactUs(contactUsRequest);
					Message.success('Your request has been sent successfully', 3);
					this.props.hideStaticWebsiteLoader();
					this.formRef.current?.resetFields();
					this.setState({ formSubmitted: true });
				} else {
					Message.error(DefaultErrorMessage, 3);
				}
			} catch (error: any) {
				console.error(LogTag, 'ContactUs', error);
				Message.error(getServerErrorMessage(error), 3);
			} finally {
				this.props.hideStaticWebsiteLoader();
			}
		}
	}

	render() {
		return (
			<Spin spinning={this.props.isStaticWebsiteLoading}>
				<Helmet>
					<title>Contact Us</title>
				</Helmet>
				<Form ref={this.formRef} layout="vertical" onFinish={(contactUsFormValues) => this.contactUs(contactUsFormValues as ContactUsFormValues)} className="contact-us-form">
					<Typography>
						<Typography.Title level={1} className="contact-form-title">Contact us</Typography.Title>
						<Typography.Paragraph>Have something to say or complain? Please don't hesitate to write to us through the contact form below. We have worked hard day and night to build this product so your happy words would make our day. But if you aren't satisfied, we are even more eager to know why and how we can turn your downward frown into a smile.</Typography.Paragraph>
						<Typography.Paragraph>And please be kind. For now, it is just two people coding, testing, and running the {process.env.REACT_APP_COMPANY_NAME} wagon.</Typography.Paragraph>
					</Typography>
					<Form.Item label="Name" name="name" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your name' }
					]}>
						<Input id="name" name="name" type="text" placeholder="Enter name" disabled={this.props.isStaticWebsiteLoading} autoComplete="name" />
					</Form.Item>
					<Form.Item label="Email" name="email" hasFeedback={true} rules={[
						{ required: true, type: 'email', whitespace: true, message: 'Please enter your email' }
					]}>
						<Input id="email" name="email" type="email" placeholder="Enter email" disabled={this.props.isStaticWebsiteLoading} autoComplete="email" />
					</Form.Item>
					<Form.Item label="Subject" name="subject" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter subject' }
					]}>
						<Input id="subject" name="subject" type="text" placeholder="Enter subject" disabled={this.props.isStaticWebsiteLoading} />
					</Form.Item>
					<Form.Item label="Message" name="message" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your message' }
					]}>
						<Input.TextArea id="message" name="message" placeholder="Enter message" disabled={this.props.isStaticWebsiteLoading} autoSize={{ minRows: 3 }} />
					</Form.Item>
					<div className="display-none">
						<ReCaptcha ref={this.reCaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string} theme={this.props.colorScheme} />
					</div>
					<div className="contact-form-button-container margin-top-32px">
						<Row justify="center" align="middle">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<Button type="primary" htmlType="submit" disabled={this.props.isStaticWebsiteLoading} loading={this.props.isStaticWebsiteLoading} className="contact-form-button">Submit</Button>
							</Col>
						</Row>
					</div>
				</Form>
			</Spin>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
