import { EditorState } from 'draft-js';

import LinkifyIt, { Match as LinkMatch } from 'linkify-it';
import punycode from 'punycode';

import { DefaultErrorMessage, AppErrorResponse, SocialPlatform, ColorScheme } from '../';
import MD5 from './MD5';

import { extractLinksWithIndices } from '../../Components/Util/UI/PostEditor/Link/Util';

const linkify = LinkifyIt({ fuzzyEmail: false, fuzzyIP: false, fuzzyLink: true });

const LogTag: string = 'Contracts Util';

export const SIDER_WIDTH = 200;

export const SIDER_WIDTH_MOBILE = 200;

export const SIDER_COLLAPSED_WIDTH = 80;

export const SIDER_COLLAPSED_WIDTH_MOBILE = 0;

export const delay = async (delayTimeInMilliseconds: number): Promise<void> => {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, delayTimeInMilliseconds);
	});
};

export const removeEscapeSlashes = (stringWithEscapedCharacters: string): string => {
	return stringWithEscapedCharacters.replace(/\\"/g, '').replace(/"/g, '');
};

export const datesOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const datesOfLeapYearMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const humanizeDateFormat = (date: Date, showTime = false): string => {
	try {
		if (!date) {
			return '';
		}
		date = new Date(date);
		const year: number = date.getFullYear();
		const month: number = date.getMonth();
		const day: number = date.getDate();
		let hours: number = date.getHours();
		let minutes: number | string = date.getMinutes();
		let seconds: number | string = date.getSeconds();
		const amOrPm = hours >= 12 ? 'PM' : 'AM';
		if (hours > 12) {
			hours = hours - 12;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		const time = showTime ? `${hours}:${minutes} ${amOrPm}, ` : '';
		return `${time}${monthsOfYear[month]} ${day}, ${year}`;
	} catch (error: any) {
		console.error(LogTag, 'HumanizeDateFormat', error);
		return '';
	}
};

export const capitalize = (input: string | undefined | null): string => {
	if (!input || input.length === 0) {
		return '';
	}
	return input.toUpperCase();
};

export const capitalizeFirstLetter = (input: string | undefined | null): string => {
	if (!input || input.length === 0) {
		return '';
	}
	return input.charAt(0).toUpperCase() + input.slice(1);
};

export const capitalizeAfterUnderscore = (input: string | undefined | null): string => {
	if (!input || input.length === 0) {
		return '';
	}
	return input.split('_').join(' ').replace(/\b\w/g, (word: string) => word.toUpperCase());
};

export const allZeroesGuid: string = '0'.repeat(32);

export const createGuid = (): string => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16).replace(/-/g, '');
	});
};

export const convertFileSizeFormat = (fileSizeInBytes: number) => {
	if (!fileSizeInBytes || isNaN(fileSizeInBytes) || fileSizeInBytes <= 0) {
		return '0 KB';
	}
	if (fileSizeInBytes <= 1024) {
		return '0.1 KB';
	} else if (fileSizeInBytes > 1024 && fileSizeInBytes <= 1024000) {
		return Math.floor(fileSizeInBytes / 1000) + ' KB';
	} else if (fileSizeInBytes > 1024000 && fileSizeInBytes <= 1024000000) {
		return Math.floor(fileSizeInBytes / 1000) + ' MB';
	} else if (fileSizeInBytes > 1024000 && fileSizeInBytes <= 1024000000) {
		return Math.floor(fileSizeInBytes / 1000) + ' MB';
	} else if (fileSizeInBytes > 1024000000 && fileSizeInBytes <= 1024000000000) {
		return Math.floor(fileSizeInBytes / 1000) + ' GB';
	} else {
		return Math.floor(fileSizeInBytes / 1000) + ' TB';
	}
};

export const timeSinceXAgo = (date: Date): string => {
	const templates: { [key: string]: string } = {
		prefix: '',
		suffix: ' ago',
		seconds: 'few seconds',
		minute: 'about a minute',
		minutes: '%d minutes',
		hour: 'about an hour',
		hours: 'about %d hours',
		day: 'a day',
		days: '%d days',
		month: 'about a month',
		months: '%d months',
		year: 'about a year',
		years: '%d years'
	};
	const template = (key: string, value: number): string => {
		return templates[key] ? templates[key].replace(/%d/i, Math.abs(Math.round(value)).toString()) : '';
	};
	try {
		if (!date) {
			return '';
		}
		date = new Date(date);
		const now = new Date();
		const seconds = ((now.getTime() - date.getTime()) * 0.001) >> 0;
		const minutes = seconds / 60;
		const hours = minutes / 60;
		const days = hours / 24;
		const years = days / 365;
		return (
			templates.prefix +
			((seconds < 45 && template('seconds', seconds)) ||
				(seconds < 90 && template('minute', 1)) ||
				(minutes < 45 && template('minutes', minutes)) ||
				(minutes < 90 && template('hour', 1)) ||
				(hours < 24 && template('hours', hours)) ||
				(hours < 42 && template('day', 1)) ||
				(days < 30 && template('days', days)) ||
				(days < 45 && template('month', 1)) ||
				(days < 365 && template('months', days / 30)) ||
				(years < 1.5 && template('year', 1)) ||
				template('years', years)) +
			templates.suffix
		);
	} catch (error: any) {
		console.error(LogTag, 'TimeSinceXAgo', error);
		return '';
	}
};

export const copyToClipboardFromInputElement = (textToCopy: string) => {
	try {
		const element = document.createElement('textarea');
		element.value = textToCopy;
		element.style.position = 'absolute';
		element.style.left = '-9999';
		element.style.top = '-9999';
		document.body.appendChild(element);
		element.select();
		document.execCommand('copy');
		document.body.removeChild(element);
	} catch (error: any) {
		console.error(LogTag, 'CopyToClipboardFromInputElement', error);
		throw error;
	}
};

export const getServerErrorMessage = (error: AppErrorResponse): string => {
	return (error?.message?.length > 0) ? error.message : ((error?.error && error?.error?.length > 0) ? error.error : DefaultErrorMessage);
};

export const getGravatarProfilePhotoUrl = (email: string | null | undefined, dimension: number) => {
	if (!email || email.length === 0 || !dimension || isNaN(dimension)) {
		return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y';
	}
	return `https://secure.gravatar.com/avatar/${MD5(email)}?s=${dimension}&r=pg&d=mm`;
};

export const getDefaultFreshChatConfig = (_colorScheme: ColorScheme) => {
	return {
		token: process.env.REACT_APP_FRESHCHAT,
		host: 'https://wchat.freshchat.com',
		open: false,
		config: {
			headerProperty: {
				hideChatButton: true,
				direction: 'rtl',
				right: '6px',
				bottom: '6px',
				backgroundColor: '#1890FF',
				foregroundColor: '#FFFFFF',
				fontName: 'Source Sans Pro',
				fontUrl: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap'
			}
		}
	};
};

export const decodeUnicode = (str: string) => {
	return punycode.ucs2.decode(str);
};

export const extractLinksFromPostTextContent = (textContent: string): LinkMatch[] => {
	let links = new Array<LinkMatch>();
	try {
		if (textContent?.length > 0 && linkify.pretest(textContent)) {
			links = linkify.match(textContent) ?? new Array<LinkMatch>();
		}
		return links;
	} catch (error: any) {
		console.error(LogTag, 'ExtractLinksFromPostTextContent', error);
		return links;
	}
};

export const getCharacterCount = (textContent: string, socialPlatform?: SocialPlatform): number => {
	if (!textContent || textContent.length === 0) {
		return 0;
	}
	let characterCount = decodeUnicode(textContent).length;
	const links = extractLinksWithIndices(textContent);
	if (links?.length > 0 && socialPlatform === SocialPlatform.Twitter) {
		links?.forEach((result) => {
			characterCount = characterCount - result.href?.length + 23;
		});
	}
	return characterCount;
};

export const getWordCount = (editorState: EditorState | null): number => {
	if (!editorState) {
		return 0;
	}
	const plainText = editorState.getCurrentContent().getPlainText('');
	const regex = /(?:\r\n|\r|\n)/g;
	const cleanString = plainText.replace(regex, ' ').trim();
	const wordArray = cleanString.match(/\S+/g);
	return wordArray ? wordArray.length : 0;
};

export const getLineCount = (editorState: EditorState | null): number => {
	if (!editorState) {
		return 0;
	}
	const blocksArray = editorState.getCurrentContent().getBlocksAsArray();
	return blocksArray ? blocksArray.length : 0;
};

export const getBase64StringFromFile = (file: File | Blob): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			return resolve(reader.result as string);
		};
		reader.onerror = (error: ProgressEvent<FileReader>) => {
			return reject(error);
		};
	});
};

export const truncateText = (text: string, length: number, showEllipsis: boolean) => {
	if (text.length > length) {
		text = text.substring(0, length).concat(showEllipsis ? '...' : '');
	}
	return text;
};

export const download = (filename: string, url: string) => {
	const element = document.createElement('a');
	element.setAttribute('href', url);
	element.setAttribute('download', filename);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export const isLocalhost = (email?: string): boolean => {
	return (
		(process.env.REACT_APP_NODE_ENV !== 'production') && (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging')
	) || (
		(
			(typeof email === 'string' && email?.length > 0) &&
			(email === process.env.REACT_APP_OWNER_EMAIL || email === process.env.REACT_APP_COMPANY_EMAIL)
		)
	);
};

export const resetBrowserUrlHash = () => {
	if (window.location.hash.length > 0) {
		window.history.replaceState('', document.title, window.location.pathname + window.location.search);
	}
};

export const getCardIssuerMapping = (cardType: string) => {
	switch (cardType) {
		case 'american_express': {
			return 'amex';
		}
		case 'diners_club': {
			return 'dinersclub';
		}
		default: {
			return cardType;
		}
	}
};

export const createPreconnectLink = (href?: string) => {
	if (href) {
		const res = document.createElement('link');
		res.rel = 'preconnect';
		res.as = 'href';
		res.href = href;
		document.head.appendChild(res);
	}
};

export const addScriptToDocument = (scriptPath: string, scriptPreLoadInitialize: (() => void) | null, scriptLoadedCallback: (() => void) | null, externalDomain: boolean, crossOrigin: string | null, scriptId: string | null, async: boolean, defer: boolean, dataAttributes: Record<string, string> | null) => {
	if (scriptPreLoadInitialize) {
		scriptPreLoadInitialize();
	}
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = async;
	script.defer = defer;
	script.src = scriptPath;
	if (crossOrigin && crossOrigin.length > 0) {
		script.crossOrigin = crossOrigin;
	}
	if (scriptId && scriptId.length > 0) {
		script.id = scriptId;
	}
	if (dataAttributes) {
		Object.keys(dataAttributes).map((attributeKey) => {
			return script.setAttribute(attributeKey, dataAttributes[attributeKey]);
		});
	}
	if (externalDomain && (!crossOrigin || crossOrigin.length === 0)) {
		const res = document.createElement('link');
		res.rel = 'preload';
		res.as = 'script';
		res.href = scriptPath;
		document.head.appendChild(res);
	}
	if (scriptLoadedCallback) {
		script.onload = scriptLoadedCallback;
	}
	script.onerror = (message: Event | string, url?: string, line?: number, column?: number, error?: Error) => {
		if (!message.toString().includes('ResizeObserver') && !message.toString().includes('postMessage')) {
			console.error(LogTag, 'AddScriptToDocument OnError', message, url, line, column, error);
		}
	};
	document.body.appendChild(script);
};

export const isKnownWebCrawler = (userAgent: string): boolean => {
	if (userAgent?.length > 0) {
		if (userAgent?.toLowerCase()?.includes('apis-Google') || userAgent?.toLowerCase()?.includes('mediapartners-google') || userAgent?.toLowerCase()?.includes('adsbot-google') || userAgent?.toLowerCase()?.includes('lighthouse') || userAgent?.toLowerCase()?.includes('chrome-lighthouse') || userAgent?.toLowerCase()?.includes('google page speed insights') || userAgent?.toLowerCase()?.includes('googlebot') || userAgent?.toLowerCase()?.includes('facebook') || userAgent?.toLowerCase()?.includes('facebot') || userAgent?.toLowerCase()?.includes('facebookexternalhit') || userAgent?.toLowerCase()?.includes('twitterbot') || userAgent?.toLowerCase()?.includes('bingbot') || userAgent?.toLowerCase()?.includes('slurp') || userAgent?.toLowerCase()?.includes('duckduckbot') || userAgent?.toLowerCase()?.includes('baiduspider') || userAgent?.toLowerCase()?.includes('yandexBot') || userAgent?.toLowerCase()?.includes('exabot') || userAgent?.toLowerCase()?.includes('ia_archiver')) {
			return true;
		}
	}
	return false;
};

export const replaceSubstringBeteenIndices = (text: string, replacement: string, startIndex: number, endIndex: number): string => {
	if (!text || text?.length === 0) {
		return '';
	}
	return text.substring(0, startIndex) + replacement + text.substring(endIndex);
};
