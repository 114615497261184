import { Reducer } from 'redux';

import { StaticWebsiteActionTypes, StaticWebsiteActions } from '../../Actions/StaticWebsite';

export interface StaticWebsiteState {
	readonly isLoading: boolean;
}

const initialState: StaticWebsiteState = {
	isLoading: false
};

export const StaticWebsiteReducer: Reducer<StaticWebsiteState, StaticWebsiteActions> = (
	state: StaticWebsiteState = initialState,
	action: StaticWebsiteActions
): StaticWebsiteState => {
	switch (action.type) {
		case StaticWebsiteActionTypes.SHOW_STATIC_WEBSITE_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case StaticWebsiteActionTypes.HIDE_STATIC_WEBSITE_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case StaticWebsiteActionTypes.LOAD_STATIC_WEBSITE_PAGE_LINK: {
			return state;
		}
		case StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_LINK: {
			return state;
		}
		case StaticWebsiteActionTypes.NAVIGATE_STATIC_WEBSITE_PAGE_SECTION: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default StaticWebsiteReducer;
