import { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Layout } from 'antd';

import Header from './Header';
import Footer from './Footer';

import './StaticWebsite.scss';

import { AppRoutes, AppRouteParams, AppRouteState } from '../../../Contracts';

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState> {
	readonly children?: ReactNode;
}

export class StaticWebsite extends Component<Props> {
	render() {
		return (
			<div className="static-website-page-container">
				<Layout hasSider={false} className="static-website-page-layout">
					<Layout hasSider={false} className="static-website-page-wrapper">
						<Header />
						<Layout.Content className="static-website-page-content">
							{this.props.children}
						</Layout.Content>
						{this.props.location.pathname !== AppRoutes.Error && (
							<Footer />
						)}
					</Layout>
				</Layout>
			</div>
		);
	}
}

export default withRouter(StaticWebsite);
