import { Reducer } from 'redux';

import { AdminUser } from '../../Contracts/Admin';
import { UserCountAggregates } from '../../Contracts/User';

import { AdminActionTypes, AdminActions } from '../../Actions/Admin';

export interface AdminState {
	readonly isLoading: boolean;
	readonly totalUsers: UserCountAggregates;
	readonly usersList: AdminUser[];
}

const initialState: AdminState = {
	isLoading: false,
	totalUsers: { count: 0 },
	usersList: new Array<AdminUser>()
};

export const AdminReducer: Reducer<AdminState, AdminActions> = (
	state: AdminState = initialState,
	action: AdminActions
): AdminState => {
	switch (action.type) {
		case AdminActionTypes.SHOW_ADMIN_LOADER: {
			return {
				...state,
				isLoading: true
			};
		}
		case AdminActionTypes.HIDE_ADMIN_LOADER: {
			return {
				...state,
				isLoading: false
			};
		}
		case AdminActionTypes.TOTAL_USERS_REQUESTED: {
			return state;
		}
		case AdminActionTypes.TOTAL_USERS_SUCCESSFUL: {
			return {
				...state,
				totalUsers: { ...action.totalUsers }
			};
		}
		case AdminActionTypes.TOTAL_USERS_FAILED: {
			return state;
		}
		case AdminActionTypes.USERS_REQUESTED: {
			return state;
		}
		case AdminActionTypes.USERS_SUCCESSFUL: {
			return {
				...state,
				usersList: [ ...action.usersList ]
			};
		}
		case AdminActionTypes.USERS_FAILED: {
			return state;
		}
		case AdminActionTypes.USER_AGGREGATES_REQUESTED: {
			return state;
		}
		case AdminActionTypes.USER_AGGREGATES_SUCCESSFUL: {
			const userData = state.usersList.find((user) => user.id === action.userId);
			if (userData) {
				userData.aggregates = action.userAggregates;
				const usersList = state.usersList.filter((user) => user.id !== action.userId);
				const updateUserIndex = state.usersList.findIndex((user) => user.id === action.userId);
				if (updateUserIndex > -1) {
					usersList.splice(updateUserIndex, 0, userData);
				}
				return {
					...state,
					usersList
				};
			}
			return state;
		}
		case AdminActionTypes.USER_AGGREGATES_FAILED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_SUBSCRIPTION_REQUESTED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_SUBSCRIPTION_SUCCESSFUL: {
			const userData = state.usersList.find((user) => user.id === action.userId);
			if (userData) {
				userData.subscription = action.chargebeeSubscription.subscription;
				userData.card = action.chargebeeSubscription.card;
				const usersList = state.usersList.filter((user) => user.id !== action.userId);
				const updateUserIndex = state.usersList.findIndex((user) => user.id === action.userId);
				if (updateUserIndex > -1) {
					usersList.splice(updateUserIndex, 0, userData);
				}
				return {
					...state,
					usersList
				};
			}
			return state;
		}
		case AdminActionTypes.CHARGEBEE_SUBSCRIPTION_FAILED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICES_REQUESTED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICES_SUCCESSFUL: {
			const userData = state.usersList.find((user) => user.id === action.userId);
			if (userData) {
				userData.invoicesList = action.chargebeeInvoicesList;
				const usersList = state.usersList.filter((user) => user.id !== action.userId);
				const updateUserIndex = state.usersList.findIndex((user) => user.id === action.userId);
				if (updateUserIndex > -1) {
					usersList.splice(updateUserIndex, 0, userData);
				}
				return {
					...state,
					usersList
				};
			}
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICES_FAILED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_REQUESTED: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_SUCCESSFUL: {
			return state;
		}
		case AdminActionTypes.CHARGEBEE_INVOICE_DOWNLOAD_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default AdminReducer;
