import { Component } from 'react';
import { Link } from 'react-router-dom';

import { Layout } from 'antd';

import {
	MenuFoldOutlined as MenuFoldOutlinedIcon,
	MenuUnfoldOutlined as MenuUnfoldOutlinedIcon
} from '@ant-design/icons';

import Image from '../../Util/UI/Image';

import { AppRoutes } from '../../../Contracts';

export interface Props {
	readonly siderCollapsed: boolean;
	readonly toggleSiderCollapsible: () => void;
}

export class Header extends Component<Props> {
	render() {
		return (
			<Layout.Header className="dashboard-page-header">
				<div className="dashboard-page-sider-trigger-wrapper">
					{this.props.siderCollapsed && (
						<MenuUnfoldOutlinedIcon className="dashboard-page-sider-trigger" onClick={this.props.toggleSiderCollapsible} />
					)}
					{!this.props.siderCollapsed && (
						<MenuFoldOutlinedIcon className="dashboard-page-sider-trigger" onClick={this.props.toggleSiderCollapsible} />
					)}
				</div>
				<div className="dashboard-page-header-wrapper ellipsis">
					<Link to={AppRoutes.Home}>
						<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/logo512.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/logo512.png`]} alt="logo" className="logo" />
						{process.env.REACT_APP_COMPANY_NAME}
					</Link>
				</div>
			</Layout.Header>
		);
	}
}

export default Header;
