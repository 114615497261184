import axios, { AxiosResponse } from 'axios';

import { UploadFile } from 'antd/lib/upload/interface';
import fetchJsonp from 'fetch-jsonp';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getFirestore as firebaseGetFirestore, Timestamp as FirebaseFirestoreTimestamp, collection as firestoreCollection, query as firebaseQuery, doc as firestoreDocument, getDoc as firestoreGetDocument, getDocs as firestoreGetDocuments, setDoc as firestoreSetDocument, deleteDoc as firestoreDeleteDocument, where as firestoreWhere, QueryConstraint as FirestoreQueryConstraint } from 'firebase/firestore/lite';

import { getRequest, postRequest } from '../Backend';

import { DefaultErrorMessage, SocialPlatform } from '../../Contracts';
import { FireStoreCollections } from '../../Contracts/Util/Firebase';
import { PostConverter } from '../../Contracts/Util/Converter';
import { Post, GetPostsRequest, CreatePostRequest } from '../../Contracts/Post';
import { MentionSuggestion, SearchTwitterQueryResult } from '../../Contracts/Post/Editor';

const publishPostUrl = '/post/publish/now';
const suggestPostMediaUrl = '/post/url/media/suggest';

const facebookSearchMentionsUrl = '/post/search/facebook/pages?search={search}';
const twitterSearchHashtagsUrl = 'https://twitter.com/i/search/typeahead.json?filters=true&result_type=hashtags&src=COMPOSE&q={search}&count={count}';
const twitterSearchMentionsUrl = 'https://twitter.com/i/search/typeahead.json?filters=true&result_type=true&src=COMPOSE&q={search}&count={count}';
const linkedInSearchMentionsUrl = '/post/search/linkedin/companies?search={search}&accessToken={accessToken}';

export const getPost = async (postId: string): Promise<Post> => {
	try {
		if (!postId || postId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firebaseResponse = await firestoreGetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Posts, postId).withConverter(PostConverter));
		if (!firebaseResponse || !firebaseResponse.exists()) {
			return Promise.reject(DefaultErrorMessage);
		}
		return firebaseResponse.data() as Post;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const getPosts = async (getPostsRequest: GetPostsRequest): Promise<Post[]> => {
	try {
		if (!getPostsRequest.userId || getPostsRequest?.userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const firestoreConstraints: FirestoreQueryConstraint[] = new Array<FirestoreQueryConstraint>(firestoreWhere('userId', '==', getPostsRequest.userId));
		if (getPostsRequest.dateTimeEqualTo) {
			firestoreConstraints.push(firestoreWhere('dateTime', '==', FirebaseFirestoreTimestamp.fromDate(getPostsRequest.dateTimeEqualTo)));
		}
		if (getPostsRequest.dateTimeLessThan) {
			firestoreConstraints.push(firestoreWhere('dateTime', '<', FirebaseFirestoreTimestamp.fromDate(getPostsRequest.dateTimeLessThan)));
		}
		if (getPostsRequest.dateTimeLessThanOrEqualTo) {
			firestoreConstraints.push(firestoreWhere('dateTime', '<=', FirebaseFirestoreTimestamp.fromDate(getPostsRequest.dateTimeLessThanOrEqualTo)));
		}
		if (getPostsRequest.dateTimeGreaterThan) {
			firestoreConstraints.push(firestoreWhere('dateTime', '>', FirebaseFirestoreTimestamp.fromDate(getPostsRequest.dateTimeGreaterThan)));
		}
		if (getPostsRequest.dateTimeGreaterThanOrEqualTo) {
			firestoreConstraints.push(firestoreWhere('dateTime', '>=', FirebaseFirestoreTimestamp.fromDate(getPostsRequest.dateTimeGreaterThanOrEqualTo)));
		}
		const firebaseResponse = await firestoreGetDocuments(firebaseQuery(firestoreCollection(firebaseFirestore, FireStoreCollections.Posts).withConverter(PostConverter), ...firestoreConstraints));
		const posts = new Array<Post>();
		if (!firebaseResponse.empty) {
			firebaseResponse.docs.forEach((firebaseDocument) => {
				if (firebaseDocument.exists()) {
					posts.push(firebaseDocument.data() as Post);
				}
			});
		}
		return posts;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const createPost = async(createPostRequest: CreatePostRequest): Promise<Post> => {
	try {
		if (!createPostRequest.userId || createPostRequest?.userId?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		const id = firestoreDocument(firebaseFirestore, FireStoreCollections.Aggregates, FireStoreCollections.Users).withConverter(PostConverter).id;
		await firestoreSetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Posts, id).withConverter(PostConverter), { ...createPostRequest, id }, { merge: true });
		return await getPost(id);
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const editPost = async (editPostRequest: Post): Promise<Post> => {
	try {
		if (!editPostRequest.id || editPostRequest?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		await firestoreSetDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Posts, editPostRequest.id).withConverter(PostConverter), editPostRequest, { merge: true });
		return await getPost(editPostRequest.id);
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const deletePost = async (deletePostRequest: Post): Promise<void> => {
	try {
		if (!deletePostRequest.id || deletePostRequest?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const firebaseFirestore = firebaseGetFirestore(firebaseGetApp());
		await firestoreDeleteDocument(firestoreDocument(firebaseFirestore, FireStoreCollections.Posts, deletePostRequest.id).withConverter(PostConverter));
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const publishPost = async (publishPostRequest: Post): Promise<Post> => {
	try {
		if (!publishPostRequest.id || publishPostRequest?.id?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		await axios(await postRequest(publishPostUrl, publishPostRequest, true, false, false, false, {}));
		return await getPost(publishPostRequest.id);
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const suggestPostMedia = async (url: string, socialPlatform: SocialPlatform): Promise<UploadFile[]> => {
	try {
		if (!url || url?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const suggestPostMediaResponse: AxiosResponse<UploadFile[]> = await axios(await postRequest(suggestPostMediaUrl, { url, socialPlatform }, true, false, false, false, {}));
		return suggestPostMediaResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const searchFacebookMentions = async (search: string): Promise<MentionSuggestion[]> => {
	try {
		if (!search || search?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const searchFacebookMentionsResponse: AxiosResponse<MentionSuggestion[]> = await axios(await getRequest(facebookSearchMentionsUrl.replace('{search}', encodeURIComponent(search)), null, true, false, false, false, {}));
		return searchFacebookMentionsResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const searchTwitterHashtags = async (search: string, count: number): Promise<SearchTwitterQueryResult> => {
	try {
		if (!search || search?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const searchTwitterHashtagsResponse = await fetchJsonp(twitterSearchHashtagsUrl.replace('{search}', encodeURIComponent(search)).replace('{count}', count.toString()));
		if (searchTwitterHashtagsResponse.ok) {
			return await searchTwitterHashtagsResponse.json() as SearchTwitterQueryResult;
		} else {
			return Promise.reject(DefaultErrorMessage);
		}
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const searchTwitterMentions = async (search: string, count: number): Promise<SearchTwitterQueryResult> => {
	try {
		if (!search || search?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const searchTwitterMentionsResponse = await fetchJsonp(twitterSearchMentionsUrl.replace('{search}', encodeURIComponent(search)).replace('{count}', count.toString()));
		if (searchTwitterMentionsResponse.ok) {
			return await searchTwitterMentionsResponse.json() as SearchTwitterQueryResult;
		} else {
			return Promise.reject(DefaultErrorMessage);
		}
	} catch (error: any) {
		return Promise.reject(error);
	}
};

export const searchLinkedInMentions = async (search: string, accessToken: string): Promise<MentionSuggestion[]> => {
	try {
		if (!search || search?.length === 0 || !accessToken || accessToken?.length === 0) {
			return Promise.reject(DefaultErrorMessage);
		}
		const searchLinkedInMentionsResponse: AxiosResponse<MentionSuggestion[]> = await axios(await getRequest(linkedInSearchMentionsUrl.replace('{search}', encodeURIComponent(search)).replace('{accessToken}', accessToken), null, true, false, false, false, {}));
		return searchLinkedInMentionsResponse.data;
	} catch (error: any) {
		return Promise.reject(error);
	}
};
