import { Dispatch, Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { GlobalState } from '../../Contracts';
import { SettingsActiveTab } from '../../Contracts/Settings';

export enum SettingsActionTypes {
	CURRENT_ACTIVE_TAB_CHANGED = 'SETTINGS_CURRENT_ACTIVE_TAB_CHANGED'
}

export interface CurrentActiveTabChangedAction extends Action<SettingsActionTypes.CURRENT_ACTIVE_TAB_CHANGED> {
	readonly currentActiveTab: SettingsActiveTab;
}

export type SettingsActions =
	| CurrentActiveTabChangedAction;

export interface SettingsDispatch {
	readonly currentActiveTabChanged: ActionCreator<ThunkAction<CurrentActiveTabChangedAction, GlobalState, SettingsActiveTab, CurrentActiveTabChangedAction>>;
}

export const SettingsActionCreators: SettingsDispatch = {
	currentActiveTabChanged: (currentActiveTab: SettingsActiveTab) => {
		return (dispatch: Dispatch): CurrentActiveTabChangedAction => {
			const currentActiveTabChangedAction: CurrentActiveTabChangedAction = {
				type: SettingsActionTypes.CURRENT_ACTIVE_TAB_CHANGED,
				currentActiveTab
			};
			window.location.hash = currentActiveTab;
			return dispatch(currentActiveTabChangedAction);
		};
	}
};

export default SettingsActionCreators;
