import { ReactText } from 'react';
import { Reducer } from 'redux';

import { ColorScheme } from '../../Contracts';
import { SIDER_WIDTH, SIDER_WIDTH_MOBILE, SIDER_COLLAPSED_WIDTH, SIDER_COLLAPSED_WIDTH_MOBILE } from '../../Contracts/Util';

import { UtilActionTypes, UtilActions } from '../../Actions/Util';

export interface UtilState {
	readonly colorScheme: ColorScheme;
	readonly internetConnected: boolean;
	readonly browserLocationHash: string;
	readonly browserWindowHeight: number;
	readonly browserWindowWidth: number;
	readonly dashboardSiderCollapsed: boolean;
	readonly dashboardSiderWidth: ReactText;
	readonly dashboardSiderCollapsedWidth: ReactText;
}

const initialState: UtilState = {
	colorScheme: ColorScheme.Light,
	internetConnected: navigator.onLine,
	browserLocationHash: (window.location.hash?.length > 0) ? window.location.hash.replace('#', '') : '',
	browserWindowHeight: document.body.clientHeight,
	browserWindowWidth: document.body.clientWidth,
	dashboardSiderCollapsed: true,
	dashboardSiderWidth: (document.body.clientWidth >= 0 && document.body.clientWidth <= 575) ? SIDER_WIDTH_MOBILE : SIDER_WIDTH,
	dashboardSiderCollapsedWidth: (document.body.clientWidth >= 0 && document.body.clientWidth <= 575) ? SIDER_COLLAPSED_WIDTH_MOBILE : SIDER_COLLAPSED_WIDTH
};

export const UtilReducer: Reducer<UtilState, UtilActions> = (
	state: UtilState = initialState,
	action: UtilActions
): UtilState => {
	switch (action.type) {
		case UtilActionTypes.TOGGLE_COLOR_SCHEME: {
			return {
				...state,
				colorScheme: action.colorScheme
			};
		}
		case UtilActionTypes.TOGGLE_INTERNET_CONNECTED: {
			return {
				...state,
				internetConnected: action.internetConnected
			};
		}
		case UtilActionTypes.BROWSER_LOCATION_HASH_CHANGED: {
			return {
				...state,
				browserLocationHash: action.browserLocationHash
			};
		}
		case UtilActionTypes.BROWSER_WINDOW_HEIGHT_RESIZED: {
			return {
				...state,
				browserWindowHeight: action.browserWindowHeight
			};
		}
		case UtilActionTypes.BROWSER_WINDOW_WIDTH_RESIZED: {
			return {
				...state,
				browserWindowWidth: action.browserWindowWidth
			};
		}
		case UtilActionTypes.TOGGLE_DASHBOARD_SIDER_COLLAPSED: {
			return {
				...state,
				dashboardSiderCollapsed: action.dashboardSiderCollapsed
			};
		}
		case UtilActionTypes.DASHBOARD_SIDER_WIDTH_CHANGED: {
			return {
				...state,
				dashboardSiderWidth: action.dashboardSiderWidth
			};
		}
		case UtilActionTypes.DASHBOARD_SIDER_COLLAPSED_WIDTH_CHANGED: {
			return {
				...state,
				dashboardSiderCollapsedWidth: action.dashboardSiderCollapsedWidth
			};
		}
		case UtilActionTypes.FILE_UPLOAD_ASYNC: {
			return state;
		}
		case UtilActionTypes.FILE_UPLOAD_SYNC: {
			return state;
		}
		case UtilActionTypes.FILE_DELETE_REQUESTED: {
			return state;
		}
		case UtilActionTypes.FILE_DELETE_SUCCESSFUL: {
			return state;
		}
		case UtilActionTypes.FILE_DELETE_FAILED: {
			return state;
		}
		default: {
			return state;
		}
	}
};

export default UtilReducer;
