import { Component, ReactNode } from 'react';

import { Table, Tooltip, Skeleton } from 'antd';

import {
	CheckCircleOutlined as CheckCircleOutlinedIcon,
	InfoCircleOutlined as InfoCircleOutlinedIcon
} from '@ant-design/icons';

import './Pricing.scss';

import Image from '../../Image';

import { BillingPlanCycle, BillingPlanData, BillingPlanIdentifier, BillingPlanTable } from '../../../../../Contracts';

export interface Props {
	readonly billingPlanList: BillingPlanData[];
	readonly billingCycle: BillingPlanCycle;
}

export class PricingTable extends Component<Props> {
	render() {
		const freePlan = this.props.billingPlanList?.find((billingPlan) => billingPlan.id === BillingPlanIdentifier.FreePlan);
		const basicPlan = this.props.billingPlanList?.find((billingPlan) => billingPlan.id === BillingPlanIdentifier.BasicPlan);
		const proPlan = this.props.billingPlanList?.find((billingPlan) => billingPlan.id === BillingPlanIdentifier.ProPlan);
		const advancedPlan = this.props.billingPlanList?.find((billingPlan) => billingPlan.id === BillingPlanIdentifier.AdvancedPlan);
		return (
			<Table<BillingPlanTable>
				rowKey="key"
				tableLayout="fixed"
				showHeader={false}
				pagination={{
					hideOnSinglePage: true,
					pageSize: 25,
					current: 1,
					total: 25
				}}
				bordered={true}
				columns={[
					{
						width: '20%',
						render: (_value: ReactNode, record: BillingPlanTable, index: number) => {
							return (index === 0) ? null : (
								<div className="align-left">{record.feature}</div>
							);
						}
					},
					{
						width: '20%',
						render: (_value: ReactNode, record: BillingPlanTable, _index: number) => {
							return (
								<div className="align-center">
									<Skeleton title={true} loading={!this.props.billingPlanList || this.props.billingPlanList.length === 0 || !freePlan || freePlan.id?.length === 0} active={true} paragraph={false}>
										{record.column1}
									</Skeleton>
								</div>
							);
						}
					},
					{
						width: '20%',
						render: (_value: ReactNode, record: BillingPlanTable, _index: number) => {
							return (
								<div className="align-center">
									<Skeleton title={true} loading={!this.props.billingPlanList || this.props.billingPlanList.length === 0 || !basicPlan || basicPlan.id?.length === 0} active={true} paragraph={false}>
										{record.column2}
									</Skeleton>
								</div>
							);
						}
					},
					{
						width: '20%',
						render: (_value: ReactNode, record: BillingPlanTable, _index: number) => {
							return (
								<div className="align-center">
									<Skeleton title={true} loading={!this.props.billingPlanList || this.props.billingPlanList.length === 0 || !proPlan || proPlan.id?.length === 0} active={true} paragraph={false}>
										{record.column3}
									</Skeleton>
								</div>
							);
						}
					},
					{
						width: '20%',
						render: (_value: ReactNode, record: BillingPlanTable, _index: number) => {
							return (
								<div className="align-center">
									<Skeleton title={true} loading={!this.props.billingPlanList || this.props.billingPlanList.length === 0 || !advancedPlan || advancedPlan.id?.length === 0} active={true} paragraph={false}>
										{record.column4}
									</Skeleton>
								</div>
							);
						}
					}
				]}
				dataSource={[
					{
						key: '0',
						feature: '',
						column1: (
							<div className="pricing-plan-name-container">
								<div className="pricing-plan-name">{freePlan?.plan_name}</div>
								<div className="pricing-plan-icon">
									<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${freePlan?.id}.svg`} alt="billing-plan" />
								</div>
								<div className="pricing-plan-value">
									<span className="pricing-plan-amount">$ {this.props.billingCycle === BillingPlanCycle.Monthly ? freePlan?.monthly_price : freePlan?.yearly_price}</span>
									<span className="pricing-plan-unit">{this.props.billingCycle === BillingPlanCycle.Monthly ? ' / month' : ' / year'}</span>
								</div>
							</div>
						),
						column2: (
							<div className="pricing-plan-name-container">
								<div className="pricing-plan-name">{basicPlan?.plan_name}</div>
								<div className="pricing-plan-icon">
									<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${basicPlan?.id}.svg`} alt="billing-plan" />
								</div>
								<div className="pricing-plan-value">
									<span className="pricing-plan-amount">$ {this.props.billingCycle === BillingPlanCycle.Monthly ? basicPlan?.monthly_price : basicPlan?.yearly_price}</span>
									<span className="pricing-plan-unit">{this.props.billingCycle === BillingPlanCycle.Monthly ? ' / month' : ' / year'}</span>
								</div>
							</div>
						),
						column3: (
							<div className="pricing-plan-name-container">
								<div className="pricing-plan-name">{proPlan?.plan_name}</div>
								<div className="pricing-plan-icon">
									<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${proPlan?.id}.svg`} alt="billing-plan" />
								</div>
								<div className="pricing-plan-value">
									<span className="pricing-plan-amount">$ {this.props.billingCycle === BillingPlanCycle.Monthly ? proPlan?.monthly_price : proPlan?.yearly_price}</span>
									<span className="pricing-plan-unit">{this.props.billingCycle === BillingPlanCycle.Monthly ? ' / month' : ' / year'}</span>
								</div>
							</div>
						),
						column4: (
							<div className="pricing-plan-name-container">
								<div className="pricing-plan-name">{advancedPlan?.plan_name}</div>
								<div className="pricing-plan-icon">
									<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/images/billing/${advancedPlan?.id}.svg`} alt="billing-plan" />
								</div>
								<div className="pricing-plan-value">
									<span className="pricing-plan-amount">$ {this.props.billingCycle === BillingPlanCycle.Monthly ? advancedPlan?.monthly_price : advancedPlan?.yearly_price}</span>
									<span className="pricing-plan-unit">{this.props.billingCycle === BillingPlanCycle.Monthly ? ' / month' : ' / year'}</span>
								</div>
							</div>
						)
					},
					{
						key: '1',
						feature: (
							<div className="pricing-plan-feature-container">
								Accounts
								<Tooltip title="This is the total number of social media accounts (Facebook, Twitter, LinkedIn) you can connect to your account.">
									<InfoCircleOutlinedIcon className="info-icon" />
								</Tooltip>
							</div>
						),
						column1: freePlan?.max_social_accounts,
						column2: basicPlan?.max_social_accounts,
						column3: proPlan?.max_social_accounts,
						column4: advancedPlan?.max_social_accounts
					},
					{
						key: '2',
						feature: (
							<div className="pricing-plan-feature-container">
								Posts
								<Tooltip title="This is the total number of posts that can be scheduled at any time in your account. This does not include posts that have already been published.">
									<InfoCircleOutlinedIcon className="info-icon" />
								</Tooltip>
							</div>
						),
						column1: freePlan?.max_scheduled_posts,
						column2: basicPlan?.max_scheduled_posts,
						column3: proPlan?.max_scheduled_posts,
						column4: advancedPlan?.max_scheduled_posts
					},
					{
						key: '3',
						feature: (
							<div className="pricing-plan-feature-container">
								Uploads
								<Tooltip title="This is the total number of images/videos/gifs that can be uploaded while scheduling any post in your account.">
									<InfoCircleOutlinedIcon className="info-icon" />
								</Tooltip>
							</div>
						),
						column1: 'Unlimited',
						column2: 'Unlimited',
						column3: 'Unlimited',
						column4: 'Unlimited'
					},
					{
						key: '4',
						feature: (
							<div className="pricing-plan-feature-container">
								Core Features
								<Tooltip title="Calendar View with search/filters, Twitter hashtags/mentions search, Automated Publishing, Post Previews, Post notitications (Push/Email).">
									<InfoCircleOutlinedIcon className="info-icon" />
								</Tooltip>
							</div>
						),
						column1: (
							<div className="pricing-plan-feature-container">
								<CheckCircleOutlinedIcon className="check-icon" />
							</div>
						),
						column2: (
							<div className="pricing-plan-feature-container">
								<CheckCircleOutlinedIcon className="check-icon" />
							</div>
						),
						column3: (
							<div className="pricing-plan-feature-container">
								<CheckCircleOutlinedIcon className="check-icon" />
							</div>
						),
						column4: (
							<div className="pricing-plan-feature-container">
								<CheckCircleOutlinedIcon className="check-icon" />
							</div>
						)
					},
					{
						key: '5',
						feature: (
							<div className="pricing-plan-feature-container">
								Support
								<Tooltip title="Support representatives available to assist you between 10AM to 6pm IST, Monday to Friday, except on public holidays">
									<InfoCircleOutlinedIcon className="info-icon" />
								</Tooltip>
							</div>
						),
						column1: 'Email',
						column2: 'Email/Chat',
						column3: 'Email/Chat',
						column4: 'Email/Chat/Phone'
					}
				]}
				loading={(!this.props.billingPlanList || this.props.billingPlanList.length === 0)}
				className="pricing-table"
			/>
		);
	}
}

export default PricingTable;

