import { Component } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Typography, Card, Divider } from 'antd';

import './TeamPage.scss';

import Image from '../../Util/UI/Image';
import SocialIcon from '../../Util/UI/SocialIcon';

import { GlobalState, SocialPlatform } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps> { }

export class TeamPage extends Component<Props> {

	componentDidMount() {
		this.props.loadStaticWebsitePageLink(window.location.pathname + window.location.search);
	}

	render() {
		return (
			<div className="static-website-team-page-container">
				<Helmet>
					<title>Our Team</title>
				</Helmet>
				<Typography className="team-page-content">
					<Typography.Title level={1} className="team-page-title">Our team</Typography.Title>
					<Typography.Paragraph>We are a team of two passionate people committed to building amazing softwares and we believe anything is possible.</Typography.Paragraph>
					<Typography.Paragraph>We made Schedulify because we found that all existing social media scheduling solutions were limited in either features or too expensive for upcoming entrepreneurs, bloggers, and existing business owners.</Typography.Paragraph>
					<Divider className="margin-top-48px margin-bottom-42px" />
					<Typography.Paragraph>
						<div className="team-page-row">
							<div className="team-page-column description-column">
								<Typography.Title level={2} className="team-founder-title">Sagar Jain</Typography.Title>
								<Typography.Paragraph>Sagar loves to code and watch tech videos demonstrating the most peculiar things present and possible in the world.</Typography.Paragraph>
								<Typography.Paragraph>{process.env.REACT_APP_COMPANY_NAME} is the brain child of Sagar. One night when he saw Priyanka, the other half of the duo, struggling to schedule her social media on expensive tools, he filled his diary with {process.env.REACT_APP_COMPANY_NAME} product designs. He coded the product in a few months single-handedly while managing a full-time job in the pandemic.</Typography.Paragraph>
								<Typography.Paragraph>Sagar is weird, super fun to hang out with, and the coolest of the two. Originally from Mumbai, he wants to build a product for every problem. When he is not coding, he games or clambers up steep hills behind Priyanka.</Typography.Paragraph>
								<Typography.Paragraph>Front-end development, beautiful and clean designs, a beach house, and trying new things every other day is what he loves the most.</Typography.Paragraph>
							</div>
							<div className="team-page-column card-column">
								<Card bordered={false} cover={<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/Sagar.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/Sagar.jpg`]} alt="team" className="team-card-banner" />} className="team-card">
									<Card.Meta title="Sagar Jain" description={
										<div className="team-card-description">
											<div className="team-card-social-icons-container">
												<a href="https://facebook.com/sagar7993" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Facebook} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://twitter.com/sagar7993" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Twitter} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://instagram.com/sagar.jain07" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Instagram} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://www.linkedin.com/in/sagar-jain-006074a1/" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.LinkedIn} className="team-card-social-icon" />
												</a>
											</div>
										</div>
									} />
								</Card>
							</div>
						</div>
						<Divider className="margin-top-16px margin-bottom-42px" />
						<div className="team-page-row">
							<div className="team-page-column description-column">
								<Typography.Title level={2} className="team-founder-title">Priyanka Gupta</Typography.Title>
								<Typography.Paragraph>Originally from a small town in Uttar Pradesh, Priyanka studied software engineering from one of the most serious places (IIT Delhi) but quit it all to write and travel the world. Imagine the chaos that ensued!</Typography.Paragraph>
								<Typography.Paragraph>Priyanka now writes and blogs full-time while managing and marketing {process.env.REACT_APP_COMPANY_NAME}. She tests its features and condemns Sagar when an error pops up and breaks her flow. But secretly she thanks Sagar for if it wasn't for {process.env.REACT_APP_COMPANY_NAME}, she would have deactivated her Facebook and Twitter.</Typography.Paragraph>
								<Typography.Paragraph>When not writing, reading, and {process.env.REACT_APP_COMPANY_NAME}-ing, Priyanka rattles, runs, and hike from obscure mountain to mountain thinking one day she will connect the world through her mountain trail.</Typography.Paragraph>
								<Typography.Paragraph>Priyanka often gives Sagar food coma with her cooking. She writes at <a target="_blank" rel="noopener noreferrer" href="https://www.onmycanvas.com" title="On My Canvas">On My Canvas</a>, her second love. For her, experiences are more important than anything else and the beach house would be made out of recycled stuff.</Typography.Paragraph>
							</div>
							<div className="team-page-column card-column">
								<Card bordered={false} cover={<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/Priyanka.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/Priyanka.jpg`]} alt="team" className="team-card-banner" />} className="team-card">
									<Card.Meta title="Priyanka Gupta" description={
										<div className="team-card-description">
											<div className="team-card-social-icons-container">
												<a href="https://facebook.com/guptapranky06" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Facebook} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://twitter.com/guptapranky" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Twitter} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://instagram.com/priyanka_onmycanvas" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.Instagram} className="team-card-social-icon margin-right-16px" />
												</a>
												<a href="https://www.linkedin.com/in/priyanka-gupta-21156724/" target="_blank" rel="noopener noreferrer nofollow" className="footer-social-link">
													<SocialIcon platform={SocialPlatform.LinkedIn} className="team-card-social-icon" />
												</a>
											</div>
										</div>
									} />
								</Card>
							</div>
						</div>
						<Divider className="margin-top-16px margin-bottom-36px" />
					</Typography.Paragraph>
					<Typography.Paragraph>Do reach out to us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a> to know more about us.</Typography.Paragraph>
				</Typography>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(TeamPage);
