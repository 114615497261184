import { Component, createRef as CreateRef } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Form, Typography, Row, Col, Input, Button, Spin, message as Message } from 'antd';
import { FormInstance } from 'antd/lib/form';

import ReCaptcha from 'react-google-recaptcha';

import './ContactFormPage.scss';

import { DefaultErrorMessage, GlobalState } from '../../../Contracts';
import { getServerErrorMessage } from '../../../Contracts/Util';
import { FeatureRequestFormValues, FeatureRequest, ContactFormType, ContactFormStatus } from '../../../Contracts/StaticWebsite';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

const LogTag: string = 'FeatureRequestPage Component';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isStaticWebsiteLoading: state.staticWebsite.isLoading,
		userData: state.user.userData,
		colorScheme: state.util.colorScheme
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.showStaticWebsiteLoader()),
		hideStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.hideStaticWebsiteLoader()),
		featureRequest: (featureRequestRequest: FeatureRequest) => dispatch(StaticWebsiteActionCreators.featureRequest(featureRequestRequest)),
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly formSubmitted: boolean;
}

export class FeatureRequestPage extends Component<Props, State> {

	state: State = {
		formSubmitted: false
	};

	formRef = CreateRef<FormInstance>();
	reCaptchaRef = CreateRef<ReCaptcha>();

	featureRequest = async (featureRequestFormValues: FeatureRequestFormValues) => {
		if (this.state.formSubmitted) {
			Message.info('We have received your request. Please give us some time to work on it before submitting another request', 3);
		} else {
			this.props.showStaticWebsiteLoader();
			const googleRecaptchaToken = await this.reCaptchaRef.current?.executeAsync();
			if (googleRecaptchaToken && googleRecaptchaToken?.length > 0) {
				try {
					const featureRequestRequest: FeatureRequest = {
						...featureRequestFormValues,
						userId: this.props.userData?.id,
						type: ContactFormType.FeatureRequest,
						status: ContactFormStatus.Open,
						googleRecaptchaToken
					};
					await this.props.featureRequest(featureRequestRequest);
					Message.success('Your request has been sent successfully', 3);
					this.props.hideStaticWebsiteLoader();
					this.formRef.current?.resetFields();
					this.setState({ formSubmitted: true });
				} catch (error: any) {
					console.error(LogTag, 'FeatureRequest', error);
					Message.error(getServerErrorMessage(error), 3);
				} finally {
					this.props.hideStaticWebsiteLoader();
				}
			} else {
				Message.error(DefaultErrorMessage, 3);
			}
		}
	}

	render() {
		return (
			<Spin spinning={this.props.isStaticWebsiteLoading}>
				<Helmet>
					<title>Feature Request</title>
				</Helmet>
				<Form ref={this.formRef} layout="vertical" onFinish={(featureRequestFormValues) => this.featureRequest(featureRequestFormValues as FeatureRequestFormValues)} className="feature-request-form">
					<Typography>
						<Typography.Title level={1} className="contact-form-title">Feature request</Typography.Title>
						<Typography.Paragraph>Looking for a feature but can't find it or want to tell us about something you think would fit into the product? Reach out to us through the contact form below. We might find out your idea to be a must-have or cool and just build it in. All the awesome products are constructed through regular feedback anyway.</Typography.Paragraph>
						<Typography.Paragraph>Give your idea a chance.</Typography.Paragraph>
					</Typography>
					<Form.Item label="Name" name="name" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your name' }
					]}>
						<Input id="name" name="name" type="text" placeholder="Enter name" disabled={this.props.isStaticWebsiteLoading} autoComplete="name" />
					</Form.Item>
					<Form.Item label="Email" name="email" hasFeedback={true} rules={[
						{ required: true, type: 'email', whitespace: true, message: 'Please enter your email' }
					]}>
						<Input id="email" name="email" type="email" placeholder="Enter email" disabled={this.props.isStaticWebsiteLoading} autoComplete="email" />
					</Form.Item>
					<Form.Item label="Subject" name="subject" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter subject' }
					]}>
						<Input id="subject" name="subject" type="text" placeholder="Enter subject" disabled={this.props.isStaticWebsiteLoading} />
					</Form.Item>
					<Form.Item label="Message" name="message" hasFeedback={true} rules={[
						{ required: true, type: 'string', whitespace: true, message: 'Please enter your message' }
					]}>
						<Input.TextArea id="message" name="message" placeholder="Enter message" disabled={this.props.isStaticWebsiteLoading} autoSize={{ minRows: 3 }} />
					</Form.Item>
					<div className="display-none">
						<ReCaptcha ref={this.reCaptchaRef} size="invisible" sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string} theme={this.props.colorScheme} />
					</div>
					<div className="contact-form-button-container margin-top-32px">
						<Row justify="center" align="middle">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<Button type="primary" htmlType="submit" disabled={this.props.isStaticWebsiteLoading} loading={this.props.isStaticWebsiteLoading} className="contact-form-button">Submit</Button>
							</Col>
						</Row>
					</div>
				</Form>
			</Spin>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureRequestPage);
