import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Row, Col, Divider, Button, Typography } from 'antd';

import './FeaturesPage.scss';

import Image from '../../Util/UI/Image';

import { GlobalState, AppRoutes, ColorScheme } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapStateToProps = (state: GlobalState) => {
	return {
		colorScheme: state.util.colorScheme,
		browserWindowWidth: state.util.browserWindowWidth
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export class FeaturesPage extends Component<Props> {

	render() {
		return (
			<div className="static-website-features-page-container">
				<Helmet>
					<title>Features</title>
				</Helmet>
				<div className="features-page-container">
					<section className="features-page-section banner-section">
						<Typography.Title level={1} className="features-page-title">{process.env.REACT_APP_COMPANY_TAGLINE}</Typography.Title>
						<Typography.Title level={2} className="features-page-subtitle">{process.env.REACT_APP_COMPANY_DESCRIPTION}</Typography.Title>
						{(this.props.browserWindowWidth < 768) && (
							<Typography.Title level={3} className="features-page-subtitle">Easily automate your social media marketing and save precious time</Typography.Title>
						)}
						<div className="features-screenshot-carousel-container">
							<Carousel
								axis="horizontal"
								verticalSwipe="standard"
								autoFocus={true}
								autoPlay={true}
								centerMode={false}
								dynamicHeight={false}
								emulateTouch={true}
								infiniteLoop={true}
								preventMovementUntilSwipeScrollTolerance={false}
								showStatus={true}
								showIndicators={true}
								showThumbs={false}
								stopOnHover={true}
								swipeable={true}
								useKeyboardArrows={true}
								showArrows={true}
								className="features-screenshot-carousel"
							>
								<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/screenshots/${this.props.colorScheme ?? ColorScheme.Light}/calendar.png`} alt="calendar page screenshot" />
								<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/screenshots/${this.props.colorScheme ?? ColorScheme.Light}/post.png`} alt="create-post page screenshot" />
								<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/screenshots/${this.props.colorScheme ?? ColorScheme.Light}/accounts.png`} alt="accounts page screenshot" />
								<Image src={`${process.env.REACT_APP_WEBSITE_HOST}/screenshots/${this.props.colorScheme ?? ColorScheme.Light}/home.png`} alt="home page screenshot" />
							</Carousel>
						</div>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="left-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner3.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner3.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Plan your campaign.</Typography.Title>
									<Typography.Text className="feature-subtitle">Get an instant overview of published and scheduled content across all your networks using the built-in Calendar view. Map out your campaigns, months in advance.</Typography.Text>
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="right-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner4.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner4.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Maximize engagement.</Typography.Title>
									<Typography.Text className="feature-subtitle">Easily create engaging posts with photos, videos, link previews and animated gifs. You can see exactly what your post would look like before publishing.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Maximize engagement.</Typography.Title>
									<Typography.Text className="feature-subtitle">Easily create engaging posts with photos, videos, link previews and animated gifs. You can see exactly what your post would look like before publishing.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner4.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner4.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="left-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner5.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner5.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Stay in control.</Typography.Title>
									<Typography.Text className="feature-subtitle">Save time and stay active by scheduling your content to all your connected accounts in advance. You get notified every time your content is published.</Typography.Text>
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="right-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner6.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner6.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Grab photos from links.</Typography.Title>
									<Typography.Text className="feature-subtitle">If you're sharing a link to an article in your post, {process.env.REACT_APP_COMPANY_NAME} will automatically grab the photos in that article and you click attach it to your post in one click.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Grab photos from links.</Typography.Title>
									<Typography.Text className="feature-subtitle">If you're sharing a link to an article in your post, {process.env.REACT_APP_COMPANY_NAME} will automatically grab the photos in that article and you click attach it to your post in one click.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner6.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner6.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="left-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner7.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner7.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Consolidated calendar.</Typography.Title>
									<Typography.Text className="feature-subtitle">You can view all your across all platforms on an intuitive calender. You can even filter by platform, post status, and search by keywords.</Typography.Text>
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section feature-section">
						<div className="right-shape" />
						<Row justify="space-between" align="middle" className="feature-row">
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner8.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner8.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
							<Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Hashtag and account search for Twitter.</Typography.Title>
									<Typography.Text className="feature-subtitle">{process.env.REACT_APP_COMPANY_NAME} lets you autocomplete hashtags and accounts in your Twitter posts.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-title-container">
									<Typography.Title level={3} className="feature-title">Hashtag and account search for Twitter.</Typography.Title>
									<Typography.Text className="feature-subtitle">{process.env.REACT_APP_COMPANY_NAME} lets you autocomplete hashtags and accounts in your Twitter posts.</Typography.Text>
								</div>
							</Col>
							<Col xs={0} sm={0} md={12} lg={12} xl={12} xxl={12} className="feature-column">
								<div className="feature-image-container">
									<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner8.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/FeatureBanner8.png`]} className="feature-image responsive-image" alt="feature" />
								</div>
							</Col>
						</Row>
					</section>
					<Divider className="section-divider" />
					<section className="features-page-section actions-section">
						<Row justify="space-between" gutter={[72, 48]} className="actions-row">
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="actions-column">
								<div className="action-title-container">
									<Typography.Paragraph className="action-title">Like what you see?</Typography.Paragraph>
									<Typography.Paragraph className="action-subtitle">Now, with {process.env.REACT_APP_COMPANY_NAME}, you can manage all your social media organic posts from one platform.</Typography.Paragraph>
								</div>
								<div className="action-button-container">
									<Button type="primary" htmlType="button">
										<Link to={AppRoutes.Login} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Login)}>Create Account</Link>
									</Button>
								</div>
								<Divider type="vertical" className="actions-divider" />
							</Col>
							<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="actions-column">
								<div className="action-title-container">
									<Typography.Paragraph className="action-title">Want to know more?</Typography.Paragraph>
									<Typography.Paragraph className="action-subtitle">{process.env.REACT_APP_COMPANY_NAME} has pricing plans that offer the best value to all customers.</Typography.Paragraph>
								</div>
								<div className="action-button-container">
									<Button type="default" htmlType="button">
										<Link to={AppRoutes.Pricing} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Pricing)}>Explore Pricing</Link>
									</Button>
								</div>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesPage);
