import { Fragment } from 'react';

import {
	FacebookOutlined as FacebookOutlinedIcon,
	TwitterOutlined as TwitterOutlinedIcon,
	InstagramOutlined as InstagramOutlinedIcon,
	LinkedinOutlined as LinkedInOutlinedIcon
} from '@ant-design/icons';

import { ReactComponent as FacebookNativeIcon } from '../../../../Assets/SocialIcon/FacebookIcon.svg';
import { ReactComponent as TwitterNativeIcon } from '../../../../Assets/SocialIcon/TwitterIcon.svg';
import { ReactComponent as InstagramNativeIcon } from '../../../../Assets/SocialIcon/InstagramIcon.svg';
import { ReactComponent as LinkedInNativeIcon } from '../../../../Assets/SocialIcon/LinkedInIcon.svg';

import { SocialPlatform } from '../../../../Contracts';

export interface Props {
	readonly platform: SocialPlatform;
	readonly theme?: 'native';
	readonly height?: number;
	readonly width?: number;
	readonly className?: string;
}

export const SocialIcon = (props: Props) => {
	return (
		<Fragment>
			{(props.platform === SocialPlatform.Facebook) && (
				props.theme === 'native' ? (
					<span role="img" aria-label="facebook" className="anticon anticon-facebook native">
						<FacebookNativeIcon height={props.height ?? '1em'} width={props.width ?? '1em'} className={props.className} />
					</span>
				) : (
					<FacebookOutlinedIcon className={props.className} />
				)
			)}
			{(props.platform === SocialPlatform.Twitter) && (
				props.theme === 'native' ? (
					<span role="img" aria-label="twitter" className="anticon anticon-twitter native">
						<TwitterNativeIcon height={props.height ?? '1em'} width={props.width ?? '1em'} className={props.className} />
					</span>
				) : (
					<TwitterOutlinedIcon className={props.className} />
				)
			)}
			{(props.platform === SocialPlatform.Instagram) && (
				props.theme === 'native' ? (
					<span role="img" aria-label="instagram" className="anticon anticon-instagram native">
						<InstagramNativeIcon height={props.height ?? '1em'} width={props.width ?? '1em'} className={props.className} />
					</span>
				) : (
					<InstagramOutlinedIcon className={props.className} />
				)
			)}
			{(props.platform === SocialPlatform.LinkedIn) && (
				props.theme === 'native' ? (
					<span role="img" aria-label="linkedin" className="anticon anticon-linkedin native">
						<LinkedInNativeIcon height={props.height ?? '1em'} width={props.width ?? '1em'} className={props.className} />
					</span>
				) : (
					<LinkedInOutlinedIcon className={props.className} />
				)
			)}
		</Fragment>
	);
};

export default SocialIcon;
