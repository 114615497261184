import { Component } from 'react';
import { Img as ReactImage, ImgProps as ImageProps } from 'react-image';

import NoImageFound from '../../../../Assets/PostPreview/NoImageFound.jpg';

export interface Props extends Omit<ImageProps, 'src'> {
	readonly src?: string | string[];
}

export class Image extends Component<Props> {

	render() {
		return (
			<ReactImage {...this.props} srcSet={(this.props.src && this.props.src?.length > 0) ? (typeof this.props.src === 'string' ? [this.props.src, NoImageFound].join(', ') : [...this.props.src, NoImageFound].join(', ')) : NoImageFound} src={(this.props.src && this.props.src?.length > 0) ? (typeof this.props.src === 'string' ? [this.props.src, NoImageFound] : [...this.props.src, NoImageFound]) : NoImageFound} alt={this.props.alt ?? 'media'} loading={this.props.loading ?? 'lazy'} loader={this.props.loader} />
		);
	}

}

export default Image;
