export interface GoogleRecaptchaTokenResponse {
	readonly success: boolean;
	readonly score: number;
	readonly action: string;
	readonly challenge_ts: string;
	readonly timestamp: Date;
	readonly hostname: string;
}
export interface ContactForm {
	readonly id?: string;
	readonly name: string;
	readonly email: string;
	readonly subject: string;
	readonly message: string;
	readonly googleRecaptchaToken: string;
	readonly googleRecaptchaTokenResponse?: GoogleRecaptchaTokenResponse;
}

export interface ContactFormRequest extends ContactForm {
	readonly type: ContactFormType;
	readonly status: ContactFormStatus;
	readonly userId?: string;
}

export enum ContactFormType {
	ContactUs = 'contact_us',
	BugReport = 'bug_report',
	FeatureRequest = 'feature_request'
}

export enum ContactFormStatus {
	Open = 'open',
	Closed = 'closed',
	Cancelled = 'cancelled'
}

export interface ContactUsFormValues extends ContactForm { }

export interface ContactUs extends ContactFormRequest {}

export interface BugReportFormValues extends ContactForm { }

export interface BugReport extends ContactFormRequest {}

export interface FeatureRequestFormValues extends ContactForm { }

export interface FeatureRequest extends ContactFormRequest {}
