import { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Typography } from 'antd';

import './PrivacyPolicyPage.scss';

import { AppRoutes, GlobalState, AppRouteParams, AppRouteState } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps> { }

export class PrivacyPolicyPage extends Component<Props> {

	componentDidMount() {
		this.props.loadStaticWebsitePageLink(window.location.pathname + window.location.search);
	}

	render() {
		return (
			<div className="static-website-privacy-policy-page-container">
				<Helmet>
					<title>Privacy Policy</title>
				</Helmet>
				<Typography>
					<Typography.Title level={1}>Privacy policy</Typography.Title>
					<Typography.Paragraph>Last updated: December 20th, 2020</Typography.Paragraph>
					<Typography.Title level={2}>Important definitions</Typography.Title>
					<Typography.Paragraph>
						<ol>
							<li>
								<Typography.Paragraph>For all legal purposes, Schedulify shall mean ("Company", "we," "us" or "our")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>When you use any of our services (the "Service" or "Services"), which include your access to and use of the <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> website as well as any other media form, media channel, application, mobile website or mobile application, and other services related, linked, or otherwise connected thereto (collectively, the "Site" or "Website")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>OnMyCanvas Technology Solutions LLP, is doing business as Schedulify</Typography.Paragraph>
							</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Title level={2}>Introduction</Typography.Title>
					<Typography.Paragraph>Thank you for choosing to be part of our community at {process.env.REACT_APP_COMPANY_NAME} ("Company", "we", "us" or "our"). We are committed to protecting your personal information and your right to privacy.</Typography.Paragraph>
					<Typography.Paragraph>When you use any of our services (the "Services"), which include your access to and use of the <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> website as well as any other media form, media channel, application, mobile website or mobile application, and other services related, linked, or otherwise connected thereto (collectively, the "Site"), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it.</Typography.Paragraph>
					<Typography.Paragraph>We hope you take some time to read through it carefully, as this privacy policy is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</Typography.Paragraph>
					<Typography.Paragraph>This privacy notice applies to all information collected through our Services, as well as any related services, sales, marketing or events.</Typography.Paragraph>
					<Typography.Paragraph>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</Typography.Paragraph>
					<Typography.Paragraph>If you have any questions about our privacy policy, do not hesitate to contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={2}>What information do we collect?</Typography.Title>
					<Typography.Paragraph>In short: Information Voluntarily Provided By You</Typography.Paragraph>
					<Typography.Paragraph>We collect personal information that you voluntarily provide to us when you register or express an interest in obtaining information about us or our products and Services, when you participate in activities on the Site (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</Typography.Paragraph>
					<Typography.Paragraph>The personal information that we collect depends on the context of your interactions with us and the choices you make and the products and features you use.</Typography.Paragraph>
					<Typography.Paragraph>The personal information we collect may include the following:</Typography.Paragraph>
					<Typography.Title level={3}>Social media login data</Typography.Title>
					<Typography.Paragraph>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called "How do we handle your social logins" below.</Typography.Paragraph>
					<Typography.Paragraph>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</Typography.Paragraph>
					<Typography.Title level={3}>Information automatically collected</Typography.Title>
					<Typography.Paragraph>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Site.</Typography.Paragraph>
					<Typography.Paragraph>We automatically collect certain information when you visit, use or navigate the Site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</Typography.Paragraph>
					<Typography.Paragraph>Like many businesses, we also collect information through cookies and similar technologies. Please have a look at our <Link to={AppRoutes.Cookie} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Cookie)}>Cookie Policy</Link> here.</Typography.Paragraph>
					<Typography.Title level={3}>Will your information be shared with anyone?</Typography.Title>
					<Typography.Paragraph>In Short: We only share information with your consent, to comply with laws, to provide you our services, to protect your rights, or to fulfil business obligations.</Typography.Paragraph>
					<Typography.Paragraph>We may process or share your data that we hold based on the following legal basis:</Typography.Paragraph>
					<Typography.Paragraph>
						<ul>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Consent:</Typography.Title>
									<Typography.Paragraph>We may process your data if you have given us specific consent to use your personal information in a specific purpose.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Legitimate Interests:</Typography.Title>
									<Typography.Paragraph>We may process your data when it is reasonably necessary to achieve our legitimate business interests.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Performance of a Contract:</Typography.Title>
									<Typography.Paragraph>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Legal Obligations:</Typography.Title>
									<Typography.Paragraph>We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Vital Interests:</Typography.Title>
									<Typography.Paragraph>We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Business Transfers:</Typography.Title>
									<Typography.Paragraph>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Affiliates:</Typography.Title>
									<Typography.Paragraph>We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
						</ul>
					</Typography.Paragraph>
					<Typography.Title level={2}>Do we use cookies and other tracking technologies?</Typography.Title>
					<Typography.Paragraph>In Short: We may use cookies and other tracking technologies to collect and store your information.</Typography.Paragraph>
					<Typography.Paragraph>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our <Link to={AppRoutes.Cookie} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Cookie)}>Cookie Policy</Link>.</Typography.Paragraph>
					<Typography.Title level={2}>How do we handle your social logins?</Typography.Title>
					<Typography.Paragraph>In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</Typography.Paragraph>
					<Typography.Paragraph>Our Site offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.</Typography.Paragraph>
					<Typography.Paragraph>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant social profile. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</Typography.Paragraph>
					<Typography.Title level={2}>Is your information transferred internationally?</Typography.Title>
					<Typography.Paragraph>In Short: We may transfer, store, and process your information in countries other than your own.</Typography.Paragraph>
					<Typography.Paragraph>Our servers are located in the United States. If you are accessing our Site from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "Will your information be shared with anyone?" above), in and other countries.</Typography.Paragraph>
					<Typography.Paragraph>In any case, we will take all necessary measures to protect your personal information in accordance with this privacy notice.</Typography.Paragraph>
					<Typography.Title level={2}>How long do we keep your information?</Typography.Title>
					<Typography.Paragraph>In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</Typography.Paragraph>
					<Typography.Paragraph>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements).</Typography.Paragraph>
					<Typography.Paragraph>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Typography.Paragraph>
					<Typography.Title level={2}>Do we collect information from minors?</Typography.Title>
					<Typography.Paragraph>In Short: We do not knowingly collect data from or market to children under 18 years of age.</Typography.Paragraph>
					<Typography.Paragraph>We do not knowingly solicit data from or market to children under 18 years of age. By using the Site and our Services, you represent that you are at least 18.</Typography.Paragraph>
					<Typography.Paragraph>If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={2}>What are your privacy rights?</Typography.Title>
					<Typography.Paragraph>You may review, change, or terminate your account at any time.</Typography.Paragraph>
					<Typography.Title level={2}>Account information</Typography.Title>
					<Typography.Paragraph>If you would at any time like to review or change the information in your account or terminate your account, you can either login and do some changes yourself, such as changing your phone or contact address, or you can email us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Paragraph>Please note that once the account is created, you cannot change some of the information without contacting us. Such as the email address with which you created the account. Please contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a> to do so.</Typography.Paragraph>
					<Typography.Paragraph>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our <Link to={AppRoutes.TermsConditions} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.TermsConditions)}>Terms of Use</Link> and/or comply with applicable legal requirements.</Typography.Paragraph>
					<Typography.Title level={2}>Opting out of email marketing</Typography.Title>
					<Typography.Paragraph>You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</Typography.Paragraph>
					<Typography.Title level={2}>Controls for do-not-track features</Typography.Title>
					<Typography.Paragraph>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</Typography.Paragraph>
					<Typography.Title level={2}>Country specific privacy protection</Typography.Title>
					<Typography.Paragraph>If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer nofollow">here</a>.</Typography.Paragraph>
					<Typography.Paragraph>If you are resident in Switzerland, the contact details for the data protection authorities are available <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer nofollow">here</a>.</Typography.Paragraph>
					<Typography.Title level={2}>Cookie statement</Typography.Title>
					<Typography.Paragraph>Like many websites, we also use "cookie" technology to collect additional website usage data and to improve the Site and our Services. </Typography.Paragraph>
					<Typography.Paragraph>A cookie is a small data file that we transfer to your computer's hard disk. A session cookie enables certain features of the Site and our service and is deleted from your computer when you disconnect from or leave the Site. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Site. Persistent cookies can be removed by following your web browser help file directions. Most Internet browsers automatically accept cookies. Our Site may use both session cookies and persistent cookies to better understand how you interact with the Site and our Service, to monitor aggregate usage by our users and web traffic routing on the Site, and to improve the Site and our Services.</Typography.Paragraph>
					<Typography.Paragraph>We may also automatically record certain information from your device by using various types of technology, including "clear gifs" or "web beacons." This automatically collected information may include your IP address or other device address or ID, web browser and/or device type, the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, and the dates and times that you visit, access, or use the Service. We also may use these technologies to collect information regarding your interaction with email messages, such as whether you opened, clicked on, or forwarded a message, to the extent permitted under applicable law.</Typography.Paragraph>
					<Typography.Paragraph>You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Please note that if you delete, or choose not to accept, cookies from the Service, you may not be able to utilise the features of the Service to their fullest potential.</Typography.Paragraph>
					<Typography.Title level={2}>GDPR compliance</Typography.Title>
					<Typography.Paragraph>The EU's General Data Protection Regulations (GDPR) took effect May 25 2018. We embrace privacy and, whenever possible, to not collect and store personally-identifiable information.</Typography.Paragraph>
					<Typography.Paragraph>Our Privacy policy mentions a few instances where personally-identifiable information is required. Some of the personal information we take is an email address to log into your {process.env.REACT_APP_COMPANY_NAME} account or a Twitter handle to manage your account.</Typography.Paragraph>
					<Typography.Paragraph>We mostly only collect data when we need it for the usage of the product. We promptly honour any requests by you to have your information deleted and forgotten. To do the same, please email us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Paragraph>Here is what we've done for GDPR compliance:</Typography.Paragraph>
					<Typography.Title level={4}>Data mapping</Typography.Title>
					<Typography.Paragraph>We keep a close check on all areas of our Site and Services to determine what personal data we collect and for what purpose. In a case where collecting personal data is not essential, we remove that collection process.</Typography.Paragraph>
					<Typography.Title level={4}>Privacy policy</Typography.Title>
					<Typography.Paragraph>We're working to ensure that our policy contains the proper language, that it's easy to understand, and that it communicates clearly any instances of personal data collection.</Typography.Paragraph>
					<Typography.Title level={4}>Cookies</Typography.Title>
					<Typography.Paragraph>We have added a cookie notice to all marketing pages and blogs in order to comply with the E-Privacy Directive. We do not collect personally-identifiable information with our cookies, but we do want to acknowledge the use of cookie technology on our website.</Typography.Paragraph>
					<Typography.Title level={4}>Deletion</Typography.Title>
					<Typography.Paragraph>A user has the right to request that we delete all of their personal data. Users who wish to inquire about the right to be forgotten will be able to reach out to us at any time at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={4}>Access / Portability</Typography.Title>
					<Typography.Paragraph>A user can request access to a copy of the personal data that we have collected. Users who wish to request portability can reach out to us at any time at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={4}>Modification</Typography.Title>
					<Typography.Paragraph>On our Site, if a user asks to change their information, we can do so within our admin portal. If a user has a modification to make, they can reach out to us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={4}>Data protection agreement</Typography.Title>
					<Typography.Paragraph>Everything related to the personal information we collect and data privacy is written clearly in our <Link to={AppRoutes.TermsConditions} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.TermsConditions)}>Terms of Use</Link> and this privacy policy.</Typography.Paragraph>
					<Typography.Title level={4}>Data sub-processors</Typography.Title>
					<Typography.Paragraph>We commit to displaying a list of all current sub-processors in use by our Site and Services. A sub-processor includes any third party that we share personally identifiable info with.</Typography.Paragraph>
					<Typography.Paragraph>Here is that list: Google, Firebase, Chargebee, Sentry, Freshchat.</Typography.Paragraph>
					<Typography.Title level={3}>Frequently asked questions about GDPR and {process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
					<Typography.Title level={5}>Q: As a social media marketer, how will the GDPR affect me?</Typography.Title>
					<Typography.Paragraph>A: If you are a business with customers in the EU, the GDPR will be applicable to you when you are handling personal data of your EU customers. We advise you to consult a legal advisor to ensure you are compliant with GDPR.</Typography.Paragraph>
					<Typography.Title level={5}>Q: Does the GDPR change how I can use {process.env.REACT_APP_COMPANY_NAME}?</Typography.Title>
					<Typography.Paragraph>A: No. {process.env.REACT_APP_COMPANY_NAME}'s features and functionality are unaffected by the GDPR.</Typography.Paragraph>
					<Typography.Title level={5}>Q: How does {process.env.REACT_APP_COMPANY_NAME} collect data—by e-mail, electronic forms, activity tracking, etc.?</Typography.Title>
					<Typography.Paragraph>A: We primarily collect data when a user sign-up for our services. Where data tracking is enabled we make sure that we do not collect any personally identifiable information.</Typography.Paragraph>
					<Typography.Title level={2}>CCPA compliance</Typography.Title>
					<Typography.Paragraph>If you are a resident of California, you are granted specific rights regarding access to your personal information.</Typography.Paragraph>
					<Typography.Paragraph>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Paragraph>If you are under 18 years of age, residing in California, you are not allowed to use our Site and our Services.</Typography.Paragraph>
					<Typography.Paragraph>We do not sell any California resident's personal information.</Typography.Paragraph>
					<Typography.Paragraph>If you are a California resident, you may exercise the following rights.</Typography.Paragraph>
					<Typography.Paragraph>
						<ol>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Right to know and access:</Typography.Title>
									<Typography.Paragraph>You may submit a verifiable request for information regarding the: (1) categories of personal information collected, sold, or disclosed by us; (2) purposes for which categories of personal information are collected or sold by us; (3) categories of sources from which we collect personal information; and (4) specific pieces of personal information we have collected about you during the past twelve months.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Right to delete:</Typography.Title>
									<Typography.Paragraph>Subject to certain exceptions, you may submit a verifiable request that we delete personal information about you that we have collected from you.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Verification:</Typography.Title>
									<Typography.Paragraph>Requests for access to or deletion of personal information are subject to our ability to reasonably verify your identity in light of the information requested and pursuant to relevant CCPA requirements, limitations, and regulations. To verify your access or deletion request, please provide us with the email address associated with your {process.env.REACT_APP_COMPANY_NAME} account and the last 4 four digits and expiration date of the payment card that's linked to the {process.env.REACT_APP_COMPANY_NAME} account. If you are a non-paying customer please provide us with the email address associated with your {process.env.REACT_APP_COMPANY_NAME} account and the phone number used for two-step authentication.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Right to equal service and price:</Typography.Title>
									<Typography.Paragraph>You have the right not to receive discriminatory treatment for the exercise of your CCPA privacy rights, subject to certain limitations.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Shine the light:</Typography.Title>
									<Typography.Paragraph>You may request information once per calendar year about our disclosures of certain categories of personal information to third parties for their direct marketing purposes. Such requests must be submitted to us in writing using the Contact Information section below.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Submit requests:</Typography.Title>
									<Typography.Paragraph>To exercise your rights under the CCPA, please contact us by email at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Authorizing an Agent:</Typography.Title>
									<Typography.Paragraph>To authorize an agent to make a request to know or delete on your behalf, please send a written authorization signed by you and the authorized agent to us via <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Title level={2}>Use of personal information</Typography.Title>
					<Typography.Paragraph>We collect, use, and disclose your personal information in accordance with the specific business and commercial purposes below:</Typography.Paragraph>
					<Typography.Paragraph>
						<ol>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Providing services:</Typography.Title>
									<Typography.Paragraph>Providing our services.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Communicating:</Typography.Title>
									<Typography.Paragraph>Communicating with you, providing you with updates and other information relating to our services, providing information that you request, responding to comments and questions, and otherwise providing customer support.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Social network support:</Typography.Title>
									<Typography.Paragraph>Updating your status on social networks, sending messages on your behalf to your social networks, and providing other features and services to you.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Connecting third party services:</Typography.Title>
									<Typography.Paragraph>Facilitating the connection of third-party services or applications, such as social networks.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Marketing:</Typography.Title>
									<Typography.Paragraph>Marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest to you.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Personalization:</Typography.Title>
									<Typography.Paragraph>Personalizing your experience on our services such as presenting tailored content.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Facilitating payments:</Typography.Title>
									<Typography.Paragraph>Facilitating transactions and payments.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>De-identification and aggregation:</Typography.Title>
									<Typography.Paragraph>De-identifying and aggregating information collected through our services and using it for any lawful purpose.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Safety issues:</Typography.Title>
									<Typography.Paragraph>Responding to trust and safety issues that may arise.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Compliance:</Typography.Title>
									<Typography.Paragraph>For compliance purposes, including enforcing our Terms of Service or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Auditing interactions:</Typography.Title>
									<Typography.Paragraph>Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Fraud and incident prevention:</Typography.Title>
									<Typography.Paragraph>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Debugging:</Typography.Title>
									<Typography.Paragraph>Debugging to identify and repair errors that impair existing intended functionality.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Transient use:</Typography.Title>
									<Typography.Paragraph>Short-term, transient use.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Contracting vendors:</Typography.Title>
									<Typography.Paragraph>Contracting with service providers to perform services on our behalf or on their behalf, including maintaining or servicing accounts, providing customer service, processing orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Research:</Typography.Title>
									<Typography.Paragraph>Undertaking internal research for technological development and demonstration.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Product improvement:</Typography.Title>
									<Typography.Paragraph>Undertaking activities to verify or maintain the quality or safety of our services, and to improve, upgrade, or enhance our services.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Enabling transactions:</Typography.Title>
									<Typography.Paragraph>Otherwise enabling or effecting, directly or indirectly, a commercial transaction.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>
									<Typography.Title level={5}>Notified purpose:</Typography.Title>
									<Typography.Paragraph>For other purposes for which we provide specific notice at the time the information is collected.</Typography.Paragraph>
								</Typography.Paragraph>
							</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Title level={2}>Collection and Disclosure of personal information</Typography.Title>
					<Typography.Paragraph>We have collected personal information from the following categories of sources:</Typography.Paragraph>
					<Typography.Paragraph>
						<ul>
							<li>You or your devices directly.</li>
							<li>Other users on our service.</li>
							<li>Business partners.</li>
							<li>Publicly accessible sources.</li>
						</ul>
					</Typography.Paragraph>
					<Typography.Paragraph>Pursuant to this policy, we may share your personal information with the following categories of third parties:</Typography.Paragraph>
					<Typography.Paragraph>
						<ul>
							<li>Internet service providers.</li>
							<li>Analytics Providers.</li>
							<li>Operating systems and platforms.</li>
							<li>Social Networks.</li>
							<li>Affiliates.</li>
							<li>Vendors and service providers.</li>
							<li>Third parties integrated into our services.</li>
							<li>Third parties as required by law and similar disclosures.</li>
							<li>Third parties in connection with a merger, sale, or asset transfer.</li>
							<li>Other third parties for whom we have obtained your permission to disclose your personal information.</li>
						</ul>
					</Typography.Paragraph>
					<Typography.Title level={2}>Do Not Track</Typography.Title>
					<Typography.Paragraph>There is no accepted standard on how to respond to do not track signals, and we do not respond to such signals.</Typography.Paragraph>
					<Typography.Title level={2}>Do we make updates to this notice?</Typography.Title>
					<Typography.Paragraph>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</Typography.Paragraph>
					<Typography.Paragraph>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Typography.Paragraph>
					<Typography.Title level={2}>How can you contact us about this notice?</Typography.Title>
					<Typography.Paragraph>If you have questions or comments about this notice, you may email us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
					<Typography.Title level={2}>How can you review, update, or delete the data we collect from you?</Typography.Title>
					<Typography.Paragraph>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please write to us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>. We will respond to your request within 30 days.</Typography.Paragraph>
					<Typography.Paragraph>If you have any questions about privacy policy, do not hesitate to contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
				</Typography>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(PrivacyPolicyPage));
