import { Component, Fragment, ErrorInfo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import debounce from 'lodash/debounce';

import { Modal, message as Message } from 'antd';

import './AppBoundary.scss';

import axios from 'axios';

import { CaptureConsole as SentryConsole } from '@sentry/integrations';
import { init as SentryInit, withScope as SentryScope, captureException as SentryCapture, showReportDialog as SentryReportDialog, withProfiler as SentryProfiler } from '@sentry/react';
import { Integrations as SentryIntegrations } from '@sentry/tracing';

import InternetError from '../../../InternetError';

import { GlobalState, DefaultErrorMessage, AppWindow, AppRoutes, AppRouteParams, AppRouteState, DefaultPrivateRoute, ColorScheme, AppRouteHash } from '../../../Contracts';
import { getServerErrorMessage, createPreconnectLink } from '../../../Contracts/Util';

import UtilActionCreators from '../../../Actions/Util';
import AccountsActionCreators from '../../../Actions/Accounts';
import PostActionCreators from '../../../Actions/Post';
import LoginActionCreators from '../../../Actions/Login';
import UserActionCreators from '../../../Actions/User';
import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';
import AdminActionCreators from '../../../Actions/Admin';

const LogTag: string = 'AppBoundary Component';

declare const window: AppWindow;

export const mapStateToProps = (state: GlobalState) => {
	return {
		internetConnected: state.util.internetConnected,
		isAccountLoading: state.accounts.isLoading,
		isPostLoading: state.post.isLoading,
		isLoginLoading: state.login.isLoading,
		isUserLoading: state.user.isLoading,
		isAdminLoading: state.admin.isLoading,
		isStaticWebsiteLoading: state.staticWebsite.isLoading,
		userData: state.user.userData,
		billingPlanList: state.user.billingPlanList,
		colorScheme: state.util.colorScheme,
		browserLocationHash: state.util.browserLocationHash,
		updateBillingPlanDrawerOpen: state.user.updateBillingPlanDrawerOpen,
		createPostDrawerOpen: state.post.createPostDrawerOpen,
		editPostDrawerOpen: state.post.editPostDrawerOpen
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		hidePostLoader: () => dispatch(PostActionCreators.hidePostLoader()),
		hideLoginLoader: () => dispatch(LoginActionCreators.hideLoginLoader()),
		showUserLoader: () => dispatch(UserActionCreators.showUserLoader()),
		hideUserLoader: () => dispatch(UserActionCreators.hideUserLoader()),
		hideAccountsLoader: () => dispatch(AccountsActionCreators.hideAccountsLoader()),
		hideAdminLoader: () => dispatch(AdminActionCreators.hideAdminLoader()),
		hideStaticWebsiteLoader: () => dispatch(StaticWebsiteActionCreators.hideStaticWebsiteLoader()),
		logout: () => dispatch(LoginActionCreators.logout()),
		resetUser: () => dispatch(UserActionCreators.resetUser()),
		toggleColorScheme: (colorScheme: ColorScheme) => dispatch(UtilActionCreators.toggleColorScheme(colorScheme)),
		toggleInternetConnected: (internetConnected: boolean) => dispatch(UtilActionCreators.toggleInternetConnected(internetConnected)),
		browserLocationHashChanged: (browserLocationHash: string) => dispatch(UtilActionCreators.browserLocationHashChanged(browserLocationHash)),
		browserWindowHeightResized: (browserWindowWidth: number) => dispatch(UtilActionCreators.browserWindowHeightResized(browserWindowWidth)),
		browserWindowWidthResized: (browserWindowWidth: number) => dispatch(UtilActionCreators.browserWindowWidthResized(browserWindowWidth)),
		updateBillingPlanDrawerOpened: () => dispatch(UserActionCreators.updateBillingPlanDrawerOpened()),
		updateBillingPlanDrawerClosed: () => dispatch(UserActionCreators.updateBillingPlanDrawerClosed()),
		createPostDrawerOpened: () => dispatch(PostActionCreators.createPostDrawerOpened()),
		createPostDrawerClosed: () => dispatch(PostActionCreators.createPostDrawerClosed()),
		editPostDrawerOpened: () => dispatch(PostActionCreators.editPostDrawerOpened()),
		editPostDrawerClosed: () => dispatch(PostActionCreators.editPostDrawerClosed())
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> { }

export interface State {
	readonly errorThrown: boolean;
	readonly errorEvent: any;
}

export class AppBoundary extends Component<Props, State> {

	state: State = {
		errorThrown: false,
		errorEvent: null
	};

	constructor (props: Props) {
		super(props);
		axios.interceptors.request.use((request) => {
			return this.props.internetConnected ? request : Promise.reject('Internet connection not available');
		}, (error) => {
			return Promise.reject(error);
		});
		axios.interceptors.response.use((response) => {
			return response;
		}, async (error) => {
			if (error?.response?.status === 401) {
				await this.props.logout();
				this.props.resetUser();
				this.props.history.push(AppRoutes.Login);
			} else if (error?.response?.status === 500) {
				this.props.history.push(AppRoutes.Error);
			}
			return Promise.reject(error?.response?.data ?? error);
		});
	}

	static getDerivedStateFromError(errorEvent: Error): State {
		return { errorThrown: true, errorEvent };
	}

	componentDidMount() {
		this.hideAllLoadersIfOpen();
		this.closeAllDrawersIfOpen();
		this.addInternetConnectionListener();
		this.addThirdPartyScripts();
		this.onWindowResize();
		this.toggleColorScheme();
		this.handleBrowserUrlHashChanged();
		window.addEventListener('resize', this.delayedOnWindowResize);
		window.addEventListener('hashchange', this.handleBrowserUrlHashChanged);
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		try {
			SentryScope((scope) => {
				scope.setExtras(errorInfo as unknown as Record<string, unknown>);
				const errorEvent = SentryCapture(error);
				this.setState({ errorEvent });
			});
		} catch (error: any) {
			console.error(LogTag, 'ComponentDidCatch', error);
			Message.error(DefaultErrorMessage, 3);
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.browserLocationHash !== this.props.browserLocationHash) {
			if (this.props.browserLocationHash.length === 0) {
				this.closeAllDrawersIfOpen();
				this.closeAllModalsIfOpen();
				this.closeFreshchat();
			} else {
				const hashLowerCase = this.props.browserLocationHash.toLowerCase();
				if (hashLowerCase === AppRouteHash.CreatePostDrawer) {
					this.props.createPostDrawerOpened();
				} else if (hashLowerCase === AppRouteHash.EditPostDrawer) {
					this.props.editPostDrawerOpened();
				} else if (hashLowerCase === AppRouteHash.BillingPlanDrawer) {
					this.props.updateBillingPlanDrawerOpened();
				} else if (hashLowerCase === AppRouteHash.FreshchatWidget) {
					this.showFreshchat();
				}
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.delayedOnWindowResize);
		window.removeEventListener('hashchange', this.handleBrowserUrlHashChanged);
	}

	hideAllLoadersIfOpen = () => {
		if (this.props.isAccountLoading) {
			this.props.hideAccountsLoader();
		}
		if (this.props.isPostLoading) {
			this.props.hidePostLoader();
		}
		if (this.props.isLoginLoading) {
			this.props.hideLoginLoader();
		}
		if (this.props.isUserLoading) {
			this.props.hideUserLoader();
		}
		if (this.props.isAdminLoading) {
			this.props.hideAdminLoader();
		}
		if (this.props.isStaticWebsiteLoading) {
			this.props.hideStaticWebsiteLoader();
		}
	}

	closeAllDrawersIfOpen = () => {
		if (this.props.updateBillingPlanDrawerOpen) {
			this.props.updateBillingPlanDrawerClosed();
		}
		if (this.props.createPostDrawerOpen) {
			this.props.createPostDrawerClosed();
		}
		if (this.props.editPostDrawerOpen) {
			this.props.editPostDrawerClosed();
		}
	}

	closeAllModalsIfOpen = () => {
		if (Modal.length > 0) {
			Modal.destroyAll();
		}
	}

	handleBrowserUrlHashChanged = () => {
		try {
			const browserLocationHash = (window.location.hash?.length > 0) ? window.location.hash.replace('#', '') : '';
			this.props.browserLocationHashChanged(browserLocationHash);
		} catch (error) {
			console.error(LogTag, 'HandleBrowserUrlHashChanged', error);
		}
	}

	onWindowResize = () => {
		try {
			this.props.browserWindowHeightResized(document.body.clientHeight);
			this.props.browserWindowWidthResized(document.body.clientWidth);
		} catch (error) {
			console.error(LogTag, 'OnWindowResize', error);
		}
	}

	delayedOnWindowResize = debounce(this.onWindowResize, 300);

	toggleColorScheme = () => {
		try {
			if (window.matchMedia && window.matchMedia('(prefers-color-scheme)')?.media !== 'not all') {
				const colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
				if (colorSchemeMediaQuery.matches) {
					this.props.toggleColorScheme(ColorScheme.Dark);
				} else {
					this.props.toggleColorScheme(ColorScheme.Light);
				}
				colorSchemeMediaQuery.addEventListener('change', ($event) => {
					this.props.toggleColorScheme($event.matches ? ColorScheme.Dark : ColorScheme.Light);
				});
			} else {
				this.props.toggleColorScheme(ColorScheme.Light);
			}
		} catch (error) {
			console.error(LogTag, 'ToggleColorScheme', error);
		}
	}

	addThirdPartyScripts = async () => {
		try {
			createPreconnectLink(process.env.REACT_APP_API_HOST);
			createPreconnectLink('https://www.googletagmanager.com');
			createPreconnectLink('https://www.google-analytics.com');
			this.initializeSentry();
			window.onerror = (message: Event | string, url?: string, line?: number, column?: number, error?: Error) => {
				if (!message.toString().includes('ResizeObserver') && !message.toString().includes('postMessage')) {
					console.error(LogTag, 'AddThirdPartyScripts OnError', message, url, line, column, error);
				}
			};
		} catch (error) {
			console.error(LogTag, 'AddThirdPartyScripts', error);
		}
	}

	addInternetConnectionListener = () => {
		try {
			this.props.toggleInternetConnected(window.navigator ? window.navigator.onLine : false);
			window.addEventListener('online', () => {
				this.props.toggleInternetConnected(true);
			});
			window.addEventListener('offline', () => {
				this.props.toggleInternetConnected(false);
			});
		} catch (error) {
			console.error(LogTag, 'AddInternetConnectionListener', error);
		}
	}

	initializeSentry = () => {
		try {
			SentryInit({
				enabled: true,
				dsn: process.env.REACT_APP_SENTRY,
				beforeSend: ($event, _hint) => {
					if ($event.logger === 'console' && $event.extra && $event.message && $event.message.includes('[object Object]')) {
						try {
							$event.message = JSON.stringify($event.extra.arguments).slice(1, -1);
						} catch (error: any) {
							console.error(LogTag, 'InitializeSentry', error);
						}
					}
					return $event;
				},
				release: 'front-end@' + process.env.REACT_APP_GIT_SHA,
				environment: process.env.REACT_APP_NODE_ENV,
				integrations: (process.env.REACT_APP_NODE_ENV === 'production') ? [new SentryConsole({ levels: ['error'] }), new SentryIntegrations.BrowserTracing()] : [new SentryIntegrations.BrowserTracing()],
				tracesSampleRate: 1.0,
				attachStacktrace: true,
				maxBreadcrumbs: 50
			});
		} catch (error: any) {
			console.error(LogTag, 'InitializeSentry', error);
			Message.error(DefaultErrorMessage, 3);
		}
	}

	showFreshchat = () => {
		try {
			if (window.fcWidget?.isOpen && window.fcWidget?.open && !window.fcWidget?.isOpen()) {
				window.fcWidget?.open();
			}
		} catch (error: any) {
			console.error(LogTag, 'ShowFreshchat', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	closeFreshchat = () => {
		try {
			if (window.fcWidget?.isOpen && window.fcWidget?.close && window.fcWidget?.isOpen()) {
				window.fcWidget?.close();
			}
		} catch (error: any) {
			console.error(LogTag, 'CloseFreshchat', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	showSentryDialog = () => {
		try {
			SentryReportDialog({ eventId: this.state.errorEvent });
		} catch (error: any) {
			console.error(LogTag, 'ShowSentryDialog', error);
			Message.error(DefaultErrorMessage, 3);
		} finally {
			this.setState({ errorThrown: false, errorEvent: null });
		}
	}

	goHome = () => {
		this.setState({ errorThrown: false, errorEvent: null });
		this.props.history.push(DefaultPrivateRoute, { from: this.props.location.pathname as AppRoutes });
	}

	render() {
		return (
			<Fragment>
				<Modal visible={this.state.errorThrown} centered={true} closable={false} maskClosable={false} keyboard={false} destroyOnClose={true} title="Oops! Looks like we're facing some issues.." onOk={this.showSentryDialog} okText="Report feedback" onCancel={this.goHome} cancelText="Go Back" afterClose={() => { this.setState({ errorThrown: false, errorEvent: null }); }}>
					We are experiencing technical issues. Our team is looking into it, and we should get it fixed ASAP
				</Modal>
				{(this.props.internetConnected) ? this.props.children : <InternetError />}
			</Fragment>
		);
	}

}

export default SentryProfiler(connect(mapStateToProps, mapDispatchToProps)(withRouter(AppBoundary)), { name: 'Schedulify', includeRender: true, includeUpdates: false });
