import { ReactElement } from 'react';

import { ContentState, EntityInstance } from 'draft-js';

import { Match } from 'linkify-it';

import moment from 'moment';

import { UploadFile } from 'antd/lib/upload/interface';

import { SocialPlatform, LinkPreview } from '../';

export enum PostStatus {
	Scheduled = 'scheduled',
	Published = 'published',
	Failed = 'failed'
}

export interface GetPostsRequest {
	readonly userId: string;
	readonly dateTimeEqualTo?: Date;
	readonly dateTimeLessThan?: Date;
	readonly dateTimeLessThanOrEqualTo?: Date;
	readonly dateTimeGreaterThan?: Date;
	readonly dateTimeGreaterThanOrEqualTo?: Date;
}

export interface CreatePostRequest extends Omit<Post, 'id'> { }

export interface SuggestPostMediaRequest {
	readonly url: string;
	readonly socialPlatform: SocialPlatform;
}

export interface PostLink extends Match {
	readonly preview?: LinkPreview;
}

export interface Post {
	readonly id: string;
	readonly textContent: string;
	readonly accountId: string;
	readonly dateTime: Date;
	readonly socialPlatform: SocialPlatform;
	readonly status: PostStatus;
	readonly publishFailedError?: string;
	readonly publishSuccessfulDateTime?: Date;
	readonly publishFailedDateTime?: Date;
	readonly userId: string;
	readonly files?: PostFile[];
	readonly [SocialPlatform.Facebook]?: {
		readonly entityId: string;
		readonly publisherId: string;
	};
	readonly [SocialPlatform.LinkedIn]?: {
		readonly subject: string | null;
		readonly entityId: string;
	};
	readonly postLinks?: PostLink[];
	readonly cloud_task?: {
		readonly name: string;
	};
}

export interface PostFormValues {
	textContent: string;
	readonly subject: string | null;
	readonly accountId: string;
	readonly entityId?: string;
	readonly publisherId?: string;
	readonly dateTime: moment.Moment;
}

export interface PostFileResponse {
	readonly remote: boolean;
}

export interface PostFile extends UploadFile<PostFileResponse> {
	twitterMediaFile?: TwitterMediaFile;
	linkedInMediaFile?: LinkedInMediaFile;
}

export interface TwitterMediaFile {
	readonly uid: string;
	readonly postId: string;
	readonly expires_after_secs?: number;
	readonly image?: {
		readonly h: number;
		readonly w: number;
		readonly image_type: string;
	};
	readonly media_id?: number;
	readonly media_id_string?: string;
	readonly media_key?: string;
	readonly size?: number;
}

export interface LinkedInMediaFile {
	readonly uid: string;
	readonly postId: string;
	readonly assetId: string;
	readonly mediaTypeFamily: string;
	readonly shareMediaCategory: 'NONE' | 'ARTICLE' | 'IMAGE' | 'RICH' | 'VIDEO' | 'CAROUSEL';
	readonly status: 'AVAILABLE' | 'ALLOWED' | 'PROCESSING' | 'NEW' | 'INCOMPLETE' | 'WAITING_UPLOAD' | 'CLIENT_ERROR' | 'SEVER_ERROR' | 'MUTATING';
}

export interface CalendarPostEvent extends Post {
	readonly unix: number;
}

export interface DraftEditorDecoratorContentChildProps {
	readonly blockKey: string;
	readonly children: ReactElement;
	readonly contentState: ContentState;
	readonly decoratedText: string;
	readonly dir: boolean | null;
	readonly end: number;
	readonly entityKey: string | null;
	readonly offsetKey: string;
	readonly start: number;
	readonly key: string | number | undefined;
}

export enum DraftJsEntityType {
	MENTION = 'mention',
	HASHTAG = 'hashtag'
}

export interface SelectedDraftJsEntity {
	readonly entityKey: string;
	readonly blockKey: string;
	readonly entity: EntityInstance;
	readonly entityType: DraftJsEntityType;
	readonly data: Record<string, any>;
	readonly start: number;
	readonly end: number;
}

export enum PostEditorUploadAcceptMimeTypes {
	Image = 'image/png, image/jpeg, image/jpg',
	Video = 'video/mp4, video/mkv, video/mov, video/m4v, video/avi',
	Gif = 'image/gif'
}

