import { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Typography } from 'antd';

import './AboutPage.scss';

import Image from '../../Util/UI/Image';

import { GlobalState, AppRoutes } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends ReturnType<typeof mapDispatchToProps> { }

export class AboutPage extends Component<Props> {

	render() {
		return (
			<div className="static-website-about-page-container">
				<Helmet>
					<title>About Us</title>
				</Helmet>
				<Typography className="about-page-content">
					<Typography.Title level={1} className="about-page-title">About us</Typography.Title>
					<Typography.Paragraph>{process.env.REACT_APP_COMPANY_NAME} is a dream of two passionate people committed to change the way things are done. We believe there is a solution for everything and brilliant products shouldn't cost a bomb.</Typography.Paragraph>
					<Typography.Paragraph>While one of us is a blogger herself and schedules tonnes of social media, the other one is a software engineer who solves problems and builds things he can't find.</Typography.Paragraph>
					<Typography.Paragraph>We made {process.env.REACT_APP_COMPANY_NAME} because we found that all existing social media scheduling solutions were limited in either features or too expensive for upcoming entrepreneurs, bloggers, and existing business owners.</Typography.Paragraph>
					<Typography.Paragraph>Our goals are three-fold</Typography.Paragraph>
					<Typography.Paragraph>
						<ol>
							<li>Give the best user-friendly social media scheduling platform with all important features bundled in.</li>
							<li>Keep the price low so that entrepreneurs and website owners of all scale and income can use our tool to schedule unlimited posts and shed off the burden of staying socially active 24*7.</li>
							<li>Enable the creators and entrepreneurs to focus on their art and business.</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Paragraph>You shouldn't fumble while using {process.env.REACT_APP_COMPANY_NAME} to create a Facebook post or Tweet, things should be fast, and there is no space for failures as unlike humans, computer programs can be absolutely perfect.</Typography.Paragraph>
					<Typography.Paragraph>We're working continuously to build the best product and provide quick service to help our customers create their brands and grow their businesses on social media.</Typography.Paragraph>
					<Typography.Paragraph>Our aim is to build long-lasting relationships with our customers. So if you use the tool and are happy, drop us a line. But if a feature, or the lack of it, annoyed you, do write to us <Link to={AppRoutes.ContactUs} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.ContactUs)}>here</Link>.</Typography.Paragraph>
					<Typography.Paragraph>Above and beyond {process.env.REACT_APP_COMPANY_NAME}, our team believes in the freedom to be who we want to be.</Typography.Paragraph>
					<Typography.Paragraph>
						<Image src={[`${process.env.REACT_APP_WEBSITE_HOST}/images/website/Team.webp`, `${process.env.REACT_APP_WEBSITE_HOST}/images/website/Team.jpg`]} alt="about-us" width="100%" height="100%" className="margin-top-12px margin-bottom-12px" />
					</Typography.Paragraph>
					<Typography.Paragraph>Here are some quirky facts about us and our vision.</Typography.Paragraph>
					<Typography.Paragraph>
						<ul>
							<li>We love being in the nature. This About Us is being written on a farm on the outskirts of Bengaluru.</li>
							<li>We both are software engineers from a reputed college in Mumbai and IIT Delhi(Indian Institute of Technogoly). But we believe that we can do anything we want.</li>
							<li>Writing, blogging, gardening, traveling, computer gaming, cooking, baking, photography, long-distance running, yoga are only some of our interests and ventures.</li>
							<li>Money was never the goal.</li>
							<li>We don't like when softwares hang or throw errors.</li>
							<li>We don't have time for slow systems. Why not use fast products, get the work done, and clear up some mental space?</li>
							<li>One of us writes the code, the other one tests and designs it.</li>
							<li>We adore ducks and lizards. Cockroaches are a strict no-no.</li>
							<li>We love movies, theater, books, and superhero comics.</li>
							<li>Hiking and taking jungle safaris make our favorite travel days. But we are all in for coding and blogging in the green balcony while watching the rain fall on the coconut and betel nut trees.</li>
							<li>India is our home, but we are wanderers by heart.</li>
							<li>We know six language amongst the two of us: Hindi, English, Spanish, Marathi, a bit of Punjabi, and Java.</li>
							<li>{process.env.REACT_APP_COMPANY_NAME} is not our first product.</li>
						</ul>
					</Typography.Paragraph>
					<Typography.Paragraph>Click <Link to={AppRoutes.Team} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Team)}>here</Link> to know more about our team, and to learn more about our company, feel free to reach out to us <Link to={AppRoutes.ContactUs} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.ContactUs)}>here</Link>.</Typography.Paragraph>
					<Typography.Paragraph>Saludos!</Typography.Paragraph>
				</Typography>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(AboutPage);
