import { Component, ReactNode, ReactText, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getApp as firebaseGetApp } from 'firebase/app';
import { getAuth as firebaseGetAuth, setPersistence as firebaseAuthSetPersistence, browserLocalPersistence as firebaseAuthBrowserLocalPersistence, onAuthStateChanged as firebaseAuthOnAuthStateChanged, onIdTokenChanged as firebaseAuthOnIdTokenChanged, User as FirebaseAuthUser  } from 'firebase/auth';
import { isSupported as firebaseAnalyticsIsSupported, getAnalytics as firebaseGetAnalytics, setUserId as firebaseAnalyticsSetUserId, setUserProperties as firebaseAnalyticsSetUserProperties } from 'firebase/analytics';
import { isSupported as firebaseMessagingIsSupported, getMessaging as firebaseGetMessaging, getToken as firebaseMessagingGetToken, onMessage as firebaseMessagingOnMessage } from 'firebase/messaging';

import { setUser as SentryUser } from '@sentry/react';

import { Alert, Layout, Modal, Button, notification as Notification, Avatar, Spin, message as Message } from 'antd';

import { CloseCircleOutlined as CloseCircleOutlinedIcon } from '@ant-design/icons';

import Header from './Header';
import Sider from './Sider';

import './Dashboard.scss';

import Loader from '../UI/Loader';
import Image from '../UI/Image';

import { AppRoutes, GlobalState, AppLocalStorage, AppStorageKeys, AppWindow, AppRouteParams, AppRouteState, DefaultErrorMessage, FCMPushNotificationData, AppRouteHash } from '../../../Contracts';
import { resetBrowserUrlHash, capitalizeFirstLetter, getServerErrorMessage, addScriptToDocument, getDefaultFreshChatConfig, isKnownWebCrawler, SIDER_WIDTH, SIDER_WIDTH_MOBILE, SIDER_COLLAPSED_WIDTH, SIDER_COLLAPSED_WIDTH_MOBILE } from '../../../Contracts/Util';
import { AuthToken } from '../../../Contracts/Login';
import { UserData, UserNotificationToken } from '../../../Contracts/User';
import { AccountsRequest } from '../../../Contracts/Accounts';

import LoginActionCreators from '../../../Actions/Login';
import UtilActionCreators from '../../../Actions/Util';
import UserActionCreators from '../../../Actions/User';
import AccountsActionCreators from '../../../Actions/Accounts';

const LogTag: string = 'Dashboard Component';

declare const window: AppWindow;

export const mapStateToProps = (state: GlobalState) => {
	return {
		isLoginLoading: state.login.isLoading,
		isUserLoading: state.user.isLoading,
		isLoggedIn: state.login.isLoggedIn,
		userData: state.user.userData,
		billingPlanList: state.user.billingPlanList,
		colorScheme: state.util.colorScheme,
		dashboardSiderCollapsed: state.util.dashboardSiderCollapsed,
		dashboardSiderWidth: state.util.dashboardSiderWidth,
		dashboardSiderCollapsedWidth: state.util.dashboardSiderCollapsedWidth,
		browserLocationHash: state.util.browserLocationHash,
		browserWindowWidth: state.util.browserWindowWidth
	};
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		showLoginLoader: () => dispatch(LoginActionCreators.showLoginLoader()),
		hideLoginLoader: () => dispatch(LoginActionCreators.hideLoginLoader()),
		showUserLoader: () => dispatch(UserActionCreators.showUserLoader()),
		hideUserLoader: () => dispatch(UserActionCreators.hideUserLoader()),
		showAccountsLoader: () => dispatch(AccountsActionCreators.showAccountsLoader()),
		hideAccountsLoader: () => dispatch(AccountsActionCreators.hideAccountsLoader()),
		loginFromSession: () => dispatch(LoginActionCreators.loginFromSession()),
		verifyEmail: (firebaseUser: FirebaseAuthUser) => dispatch(LoginActionCreators.verifyEmail(firebaseUser)),
		getUser: (userId: string) => dispatch(UserActionCreators.getUser(userId)),
		editUser: (editUserRequest: UserData) => dispatch(UserActionCreators.editUser(editUserRequest)),
		resetUser: () => dispatch(UserActionCreators.resetUser()),
		getUserAggregates: (userId: string) => dispatch(UserActionCreators.getUserAggregates(userId)),
		getBillingPlans: (fetchViaClientSdk: boolean) => dispatch(UserActionCreators.getBillingPlans(fetchViaClientSdk)),
		getAccounts: (accountsRequest: AccountsRequest) => dispatch(AccountsActionCreators.getAccounts(accountsRequest)),
		toggleDashboardSiderCollapsed: (dashboardSiderCollapsed: boolean) => dispatch(UtilActionCreators.toggleDashboardSiderCollapsed(dashboardSiderCollapsed)),
		dashboardSiderWidthChanged: (dashboardSiderWidth: ReactText) => dispatch(UtilActionCreators.dashboardSiderWidthChanged(dashboardSiderWidth)),
		dashboardSiderCollapsedWidthChanged: (dashboardSiderCollapsedWidth: ReactText) => dispatch(UtilActionCreators.dashboardSiderCollapsedWidthChanged(dashboardSiderCollapsedWidth))
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
	readonly children?: ReactNode;
}

export class Dashboard extends Component<Props> {

	firebasePushNotificationsTimeout: NodeJS.Timeout | null = null;

	componentDidMount() {
		this.addFirebaseAuthenticationStateChangedListener();
		this.handleBrowserUrlHashChanged();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.browserLocationHash !== this.props.browserLocationHash) {
			this.handleBrowserUrlHashChanged();
		}
	}

	componentWillUnmount() {
		if (this.firebasePushNotificationsTimeout) {
			clearTimeout(this.firebasePushNotificationsTimeout);
		}
	}

	handleBrowserUrlHashChanged = () => {
		if (this.props.browserLocationHash?.length > 0) {
			const hashLowerCase = this.props.browserLocationHash.toLowerCase();
			if (hashLowerCase === AppRouteHash.DashboardSiderMenu) {
				if (this.props.dashboardSiderCollapsed) {
					this.toggleSiderCollapsible();
				}
			} else {
				if (!this.props.dashboardSiderCollapsed) {
					this.toggleSiderCollapsible();
				}
			}
			if (hashLowerCase === AppRouteHash.FreshchatWidget) {
				this.openFreshchatWidget();
			}
		} else {
			if (!this.props.dashboardSiderCollapsed) {
				this.toggleSiderCollapsible();
			}
			this.closeFreshchatWidget();
		}
	}

	addFirebaseAuthenticationStateChangedListener = async () => {
		try {
			const firebaseAuth = firebaseGetAuth(firebaseGetApp());
			firebaseAuthSetPersistence(firebaseAuth, firebaseAuthBrowserLocalPersistence);
			firebaseAuthOnAuthStateChanged(firebaseAuth, async (firebaseUser) => {
				if (firebaseUser?.uid) {
					const token = await firebaseUser.getIdToken();
					AppLocalStorage.save(AppStorageKeys.Token, new AuthToken(firebaseUser.refreshToken, token, firebaseUser.uid));
					await this.getUser(firebaseUser);
					await this.props.loginFromSession();
				} else {
					AppLocalStorage.deleteAll();
					this.props.resetUser();
				}
			});
			firebaseAuthOnIdTokenChanged(firebaseAuth, async (firebaseUser) => {
				if (firebaseUser?.uid) {
					const token = await firebaseUser.getIdToken();
					AppLocalStorage.save(AppStorageKeys.Token, new AuthToken(firebaseUser.refreshToken, token, firebaseUser.uid));
				}
			});
		} catch (error: any) {
			console.error(LogTag, 'AddFirebaseAuthenticationStateChangedListener', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	getUser = async (firebaseUser: FirebaseAuthUser) => {
		this.props.showUserLoader();
		try {
			await Promise.all([this.props.getUser(firebaseUser.uid), this.props.getUserAggregates(firebaseUser.uid), this.getBillingPlans(true), this.getAccounts(firebaseUser.uid)]);
			this.updateThirdPartyScripts();
		} catch (error: any) {
			console.error(LogTag, 'GetUser', error);
			Message.error(getServerErrorMessage(error), 3);
			this.props.history.push(`${AppRoutes.Error}?error=${error}`);
		} finally {
			this.props.hideUserLoader();
		}
	}

	getBillingPlans = async (fetchViaClientSdk: boolean) => {
		try {
			await this.props.getBillingPlans(fetchViaClientSdk);
		} catch (error: any) {
			console.error(LogTag, 'GetBillingPlans', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	getAccounts = async (userId: string) => {
		this.props.showAccountsLoader();
		try {
			await this.props.getAccounts({ userId, limit: this.props.billingPlanList?.find((billingPlan) => billingPlan.id === this.props.userData?.billing_plan?.id)?.max_social_accounts ?? 25 });
		} catch (error: any) {
			console.error(LogTag, 'GetAccounts', error);
			Message.error(getServerErrorMessage(error), 3);
		} finally {
			this.props.hideAccountsLoader();
		}
	}

	verifyEmail = () => {
		this.props.showUserLoader();
		try {
			const firebaseCurrentUser = firebaseGetAuth(firebaseGetApp()).currentUser;
			if (firebaseCurrentUser) {
				this.props.verifyEmail(firebaseCurrentUser);
				Message.success('Email verification link sent', 3);
			}
		} catch (error: any) {
			console.error(LogTag, 'VerifyEmail', error);
			Message.error(getServerErrorMessage(error), 3);
		} finally {
			this.props.hideUserLoader();
		}
	}

	updateThirdPartyScripts = async () => {
		try {
			if (this.props.userData?.id?.length > 0 && !isKnownWebCrawler(navigator.userAgent)) {
				this.updateSentry();
				this.updateGoogleAnalytics();
				this.triggerFirebasePushNotifications();
				addScriptToDocument('https://wchat.freshchat.com/js/widget.js', null, this.initializeFreshChat, true, null, 'freshchat-js-sdk', true, true, null);
				addScriptToDocument('https://js.chargebee.com/v2/chargebee.js', null, this.initializeChargebee, true, null, null, true, true, null);
			}
		} catch (error: any) {
			console.error(LogTag, 'UpdateThirtPartyScripts', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	updateSentry = async () => {
		try {
			if (this.props.userData?.id?.length > 0 && !isKnownWebCrawler(navigator.userAgent)) {
				await SentryUser({
					id: this.props.userData?.id,
					email: this.props.userData?.email,
					username: this.props.userData?.name?.full_name,
					name: this.props.userData?.name?.full_name,
					phone: (this.props.userData?.country_code && this.props.userData?.phone) ? ('+' + this.props.userData?.country_code + ' ' + this.props.userData?.phone) : undefined,
					photo: this.props.userData?.photo,
					role: this.props.userData?.role,
					billing_plan: `${capitalizeFirstLetter(this.props.billingPlanList?.find((billingPlan) => billingPlan.id === this.props.userData?.billing_plan?.id)?.plan_name)} (${capitalizeFirstLetter(this.props.userData?.billing_plan?.billing_cycle)})`
				});
			}
		} catch (error: any) {
			console.error(LogTag, 'UpdateSentry', error);
			Message.error(DefaultErrorMessage, 3);
		}
	}

	initializeChargebee = async () => {
		if (window.Chargebee && window.Chargebee.init && !window.Chargebee.inited && !isKnownWebCrawler(navigator.userAgent)) {
			await window.Chargebee.init({
				site: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_SITE,
				publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
				enableFriendbuyTracking: false,
				enableRedirectMode: false
			});
		}
	}

	initializeFreshChat = () => {
		try {
			if (this.props.userData?.id?.length > 0 && window.fcWidget?.isInitialized && !window.fcWidget?.isInitialized() && window.fcWidget?.init && window.fcWidget?.on && window.fcWidget?.user?.get && !isKnownWebCrawler(navigator.userAgent)) {
				window.fcWidget.init({
					...getDefaultFreshChatConfig(this.props.colorScheme),
					externalId: this.props.userData?.id,
					restoreId: this.props.userData?.freshchat_restore_id
				});
				window.fcWidget.on('user:created', (freshchatUserResponse: any) => {
					if (freshchatUserResponse?.status === 200 && freshchatUserResponse?.data.restoreId?.length > 0) {
						const restoreId = freshchatUserResponse.data.restoreId;
						if (restoreId !== this.props.userData?.freshchat_restore_id) {
							const editUserRequest: UserData = {
								...this.props.userData,
								freshchat_restore_id: freshchatUserResponse.data.restoreId
							};
							this.props.editUser(editUserRequest);
						}
					}
				}, (error: any) => {
					console.error(LogTag, 'UpdateFreshchatUser OnFreshchatUserCreated', error);
					Message.error(getServerErrorMessage(error), 3);
				});
				window.fcWidget.user.get().then((freshchatUserResponse: any) => {
					if (freshchatUserResponse?.status === 200) {
						if (freshchatUserResponse?.data?.restoreId?.length > 0) {
							const restoreId = freshchatUserResponse.data.restoreId;
							if (restoreId !== this.props.userData?.freshchat_restore_id) {
								const editUserRequest: UserData = {
									...this.props.userData,
									freshchat_restore_id: freshchatUserResponse.data.restoreId
								};
								this.props.editUser(editUserRequest);
							}
						}
						this.updateFreshchatUser();
					} else {
						this.updateFreshchatUser();
					}
				}, (_error: any) => {
					this.updateFreshchatUser();
				});
				window.fcWidget.on('widget:opened', () => {
					window.location.hash = AppRouteHash.FreshchatWidget;
				});
				window.fcWidget.on('widget:closed', () => {
					resetBrowserUrlHash();
				});
			}
		} catch (error: any) {
			console.error(LogTag, 'UpdateFreshchat', error);
			Message.error(DefaultErrorMessage, 3);
		}
	}

	updateFreshchatUser = () => {
		try {
			if (window.fcWidget?.user?.setProperties && this.props.userData?.id?.length > 0 && !isKnownWebCrawler(navigator.userAgent)) {
				window.fcWidget.user.setProperties({
					firstName: this.props.userData?.name?.first_name,
					lastName: this.props.userData?.name?.last_name,
					email: this.props.userData?.email,
					phone: (this.props.userData?.country_code && this.props.userData?.phone) ? ('+' + this.props.userData?.country_code + ' ' + this.props.userData?.phone) : undefined,
					photo: this.props.userData?.photo,
					role: this.props.userData?.role,
					billing_plan: `${capitalizeFirstLetter(this.props.billingPlanList?.find((billingPlan) => billingPlan.id === this.props.userData?.billing_plan?.id)?.plan_name)} (${capitalizeFirstLetter(this.props.userData?.billing_plan?.billing_cycle)})`
				});
			}
		} catch (error: any) {
			console.error(LogTag, 'UpdateFreshchatUser', error);
			Message.error(getServerErrorMessage(error), 3);
		}
	}

	openFreshchatWidget = () => {
		if (window.fcWidget?.isInitialized && window.fcWidget?.isInitialized() && window.fcWidget?.isOpen) {
			if (!window.fcWidget.isOpen()) {
				window.fcWidget.open();
			}
		}
	}

	closeFreshchatWidget = () => {
		if (window.fcWidget?.isInitialized && window.fcWidget?.isInitialized() && window.fcWidget?.isOpen) {
			if (window.fcWidget.isOpen()) {
				window.fcWidget.close();
			}
		}
	}

	toggleFreshchatWidget = () => {
		if (window.fcWidget?.isInitialized && window.fcWidget?.isInitialized() && window.fcWidget?.isOpen) {
			if (window.fcWidget.isOpen()) {
				window.fcWidget.close();
			} else {
				window.fcWidget.open();
			}
		}
	}

	updateGoogleAnalytics = async () => {
		try {
			if (this.props.userData?.id?.length > 0 && await firebaseAnalyticsIsSupported() && !isKnownWebCrawler(navigator.userAgent)) {
				const firebaseAnalytics = firebaseGetAnalytics(firebaseGetApp());
				firebaseAnalyticsSetUserId(firebaseAnalytics, this.props.userData?.id);
				firebaseAnalyticsSetUserProperties(firebaseAnalytics, { ...this.props.userData });
			}
		} catch (error: any) {
			console.error(LogTag, 'UpdateGoogleAnalytics', error);
			Message.error(DefaultErrorMessage, 3);
		}
	}

	triggerFirebasePushNotifications = async () => {
		if (this.props.userData?.id?.length > 0 && await firebaseMessagingIsSupported() && !isKnownWebCrawler(navigator.userAgent)) {
			if (['granted', 'denied'].indexOf(window.Notification?.permission) === -1 && this.props.userData?.notification_preferences?.push) {
				const firebasePushNotificationsPrompt = AppLocalStorage.get(AppStorageKeys.FirebasePushNotificationsPrompt, true) as { readonly enabled: boolean; };
				if (!firebasePushNotificationsPrompt || firebasePushNotificationsPrompt.enabled) {
					this.firebasePushNotificationsTimeout = setTimeout(() => {
						this.showFirebasePushNotificationsModal();
					}, 30000);
				}
			} else if (window.Notification?.permission === 'granted') {
				await this.firebasePushNotificationsSetup(false);
			}
		}
	}

	showFirebasePushNotificationsModal = async () => {
		if (this.props.userData?.id?.length > 0 && await firebaseMessagingIsSupported() && !isKnownWebCrawler(navigator.userAgent)) {
			const modal = Modal.confirm({
				title: 'Push notifications',
				content: 'We would like to show you push notifications whenever your scheduled posts get published, or if there is something urgent that needs your attention.',
				centered: true,
				closable: false,
				maskClosable: false,
				keyboard: false,
				okText: 'Allow',
				cancelText: 'Decline',
				onOk: async () => {
					modal.update({ okButtonProps: { loading: true, disabled: true }, cancelButtonProps: { disabled: true } });
					AppLocalStorage.save(AppStorageKeys.FirebasePushNotificationsPrompt, { enabled: true });
					await this.firebasePushNotificationsSetup(true);
				},
				onCancel: () => {
					AppLocalStorage.save(AppStorageKeys.FirebasePushNotificationsPrompt, { enabled: false });
				}
			});
		}
	}

	firebasePushNotificationsSetup = async (showSuccessToastOrErrorMessage: boolean) => {
		if (this.props.userData?.id?.length > 0 && await firebaseMessagingIsSupported() && !isKnownWebCrawler(navigator.userAgent)) {
			const firebaseMessaging = firebaseGetMessaging(firebaseGetApp());
			if (showSuccessToastOrErrorMessage) {
				this.props.showUserLoader();
			}
			try {
				const firebaseMessagingToken = await firebaseMessagingGetToken(firebaseMessaging, {
					vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY
				});
				let notification_token = (this.props.userData?.notification_token?.length > 0) ? [...this.props.userData?.notification_token] : new Array<UserNotificationToken>();
				const notificationTokenForCurrentUserAgentIndex = notification_token.findIndex((notificationToken) => notificationToken.userAgent === navigator.userAgent);
				if (notificationTokenForCurrentUserAgentIndex > -1) {
					// Do not save multiple tokens for same device to avoid sending multiple notifications to users for the same event
					// User agents will be same for most laptops and a lot of phones - this method is far from perfect - but still good to have this check
					if (notification_token[notificationTokenForCurrentUserAgentIndex]?.firebase_messaging_token !== firebaseMessagingToken) {
						notification_token.splice(notificationTokenForCurrentUserAgentIndex, 1, { firebase_messaging_token: firebaseMessagingToken, userAgent: navigator.userAgent });
					}
				} else {
					notification_token = notification_token.concat([
						{ firebase_messaging_token: firebaseMessagingToken, userAgent: navigator.userAgent }
					]);
					if (notification_token.length >= 10) {
						// Remove least recently used device and restrict total number of devices stored to 10
						notification_token.shift();
					}
				}
				const editUserRequest: UserData = {
					...this.props.userData,
					notification_token
				};
				await this.props.editUser(editUserRequest);
				await this.firebasePushNotificationsListen();
				if (showSuccessToastOrErrorMessage) {
					Message.success('Push notifications have been enabled for your browser', 3);
				}
			} catch (error: any) {
				if (showSuccessToastOrErrorMessage) {
					console.error(LogTag, 'FirebasePushNotificationsSetup', error);
					Message.error(getServerErrorMessage(error), 3);
				}
			} finally {
				if (showSuccessToastOrErrorMessage) {
					this.props.hideUserLoader();
				}
			}
		}
	}

	firebasePushNotificationsListen = async () => {
		if (this.props.userData?.id?.length > 0 && await firebaseMessagingIsSupported() && window.Notification?.permission === 'granted' && !isKnownWebCrawler(navigator.userAgent)) {
			try {
				const firebaseMessaging = firebaseGetMessaging(firebaseGetApp());
				firebaseMessagingOnMessage(firebaseMessaging, {
					next: (firebaseMessagePayload: { data?: FCMPushNotificationData }) => {
						const firebaseMessagePayloadData = firebaseMessagePayload.data;
						Notification.info({
							icon: (
								<Avatar alt="notification-icon" icon={(
									<Image src={firebaseMessagePayloadData?.icon ?? firebaseMessagePayloadData?.image ?? firebaseMessagePayloadData?.badge ?? `${process.env.REACT_APP_WEBSITE_HOST}/icons/favicon-96x96.png`} alt="notification-icon" draggable={false} decode={false} loader={<Spin spinning={true} className="spin-center" />} />
								)} />
							),
							message: firebaseMessagePayloadData?.title,
							description: firebaseMessagePayloadData?.body,
							placement: 'bottomRight',
							duration: 10
						});
					},
					error: (error) => {
						console.error(LogTag, 'FirebasePushNotificationsListen OnMessage', error);
						Message.error(getServerErrorMessage(error), 3);
					},
					complete: () => {

					}
				});
			} catch (error: any) {
				console.error(LogTag, 'FirebasePushNotificationsListen', error);
				Message.error(getServerErrorMessage(error), 3);
			}
		}
	}

	toggleSiderCollapsible = () => {
		this.props.toggleDashboardSiderCollapsed(!this.props.dashboardSiderCollapsed);
	}

	onSiderBreakpoint = (siderBreakpointBroken: boolean) => {
		this.props.dashboardSiderWidthChanged(siderBreakpointBroken ? SIDER_WIDTH_MOBILE : SIDER_WIDTH);
		this.props.dashboardSiderCollapsedWidthChanged(siderBreakpointBroken ? SIDER_COLLAPSED_WIDTH_MOBILE : SIDER_COLLAPSED_WIDTH);
		if (siderBreakpointBroken && !this.props.dashboardSiderCollapsed) {
			this.toggleSiderCollapsible();
		}
	}

	render() {
		const firebaseAuthCurrentUser = firebaseGetAuth(firebaseGetApp()).currentUser;
		return (
			<div className="dashboard-page-container">
				{(firebaseAuthCurrentUser) ? (
					<Fragment>
						{(firebaseAuthCurrentUser && !firebaseAuthCurrentUser?.emailVerified && this.props.userData?.id?.length > 0 && [AppRoutes.Website, AppRoutes.Error, AppRoutes.Login, AppRoutes.Signup, AppRoutes.ForgotPassword].indexOf(this.props.location.pathname as AppRoutes) === -1) && (
							<Alert
								type="error"
								showIcon={false}
								className="email-unverified-alert"
								message={(
									<Fragment>
										<CloseCircleOutlinedIcon />
										<span className="margin-left-12px margin-right-2px">Your email address isn't verified. Please click on the link received in the verification email to continue using the app.</span>
										<Button type="link" htmlType="button" disabled={this.props.isUserLoading} onClick={this.verifyEmail} className="text-underline">Resend Email</Button>
									</Fragment>
								)}
								banner={true}
							/>
						)}
						<Layout hasSider={true} className="dashboard-page-layout">
							<Sider siderCollapsed={this.props.dashboardSiderCollapsed} siderWidth={this.props.dashboardSiderWidth} siderCollapsedWidth={this.props.dashboardSiderCollapsedWidth} onSiderBreakpoint={this.onSiderBreakpoint} toggleSiderCollapsible={this.toggleSiderCollapsible} toggleFreshchatWidget={this.toggleFreshchatWidget} />
							<Layout hasSider={false} className="dashboard-page-wrapper" style={{ marginLeft: this.props.dashboardSiderCollapsed ? this.props.dashboardSiderCollapsedWidth : (this.props.browserWindowWidth <= 575 ? 0 : this.props.dashboardSiderWidth) }}>
								<Header siderCollapsed={this.props.dashboardSiderCollapsed} toggleSiderCollapsible={this.toggleSiderCollapsible} />
								<Layout.Content className="dashboard-page-content">
									{(!this.props.isLoginLoading && this.props.isLoggedIn && this.props.children && this.props.userData?.id?.length > 0 && this.props.userData?.email?.length > 0 && firebaseAuthCurrentUser?.uid && this.props.userData?.email === firebaseAuthCurrentUser?.email) ? this.props.children : <Loader postLoginLoader={true} />}
								</Layout.Content>
							</Layout>
						</Layout>
					</Fragment>
				) : <Loader />}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
