import { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Helmet } from 'react-helmet';

import { Typography } from 'antd';

import './DisclaimerPage.scss';

import { AppRoutes, GlobalState, AppRouteParams, AppRouteState } from '../../../Contracts';

import StaticWebsiteActionCreators from '../../../Actions/StaticWebsite';

export const mapDispatchToProps = (dispatch: ThunkDispatch<GlobalState, any, Action>) => {
	return {
		loadStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.loadStaticWebsitePageLink(pageName)),
		navigateStaticWebsitePageLink: (pageName: string) => dispatch(StaticWebsiteActionCreators.navigateStaticWebsitePageLink(pageName))
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapDispatchToProps> { }

export class DisclaimerPage extends Component<Props> {

	render() {
		return (
			<div className="static-website-disclaimer-page-container">
				<Helmet>
					<title>Disclaimer</title>
				</Helmet>
				<Typography>
					<Typography.Title level={1}>Disclaimer</Typography.Title>
					<Typography.Paragraph>Last updated: December 20th, 2020</Typography.Paragraph>
					<Typography.Title level={2}>Important definitions</Typography.Title>
					<Typography.Paragraph>
						<ol>
							<li>
								<Typography.Paragraph>For all legal purposes, Schedulify shall mean ("Company", "we," "us" or "our")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>When you use any of our services (the "Service" or "Services"), which include your access to and use of the <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> website as well as any other media form, media channel, application, mobile website or mobile application, and other services related, linked, or otherwise connected thereto (collectively, the "Site" or "Website")</Typography.Paragraph>
							</li>
							<li>
								<Typography.Paragraph>OnMyCanvas Technology Solutions LLP, is doing business as Schedulify</Typography.Paragraph>
							</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Title level={2}>Introduction</Typography.Title>
					<Typography.Paragraph>The information provided by {process.env.REACT_APP_COMPANY_NAME} ("we," "us" or "our") on <Link to={AppRoutes.Website} onClick={() => this.props.navigateStaticWebsitePageLink(AppRoutes.Website)}>{process.env.REACT_APP_WEBSITE_HOST}</Link> (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.</Typography.Paragraph>
					<Typography.Paragraph>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.</Typography.Paragraph>
					<Typography.Paragraph>The site is provided on an as-is and as-available basis. You agree that your use of the site and our services will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the site and your use thereof, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties or representations about the accuracy or completeness of the site's content or the content of any websites linked to the site and we will assume no liability or responsibility for any:</Typography.Paragraph>
					<Typography.Paragraph>
						<ol>
							<li>Errors, mistakes, or inaccuracies of content and materials.</li>
							<li>Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the site.</li>
							<li>Any unauthorised access to or use of our secure servers and/or any and all personal information and/or financial information stored therein.</li>
							<li>Any interruption or cessation of transmission to or from the site.</li>
							<li>Any bugs, viruses, trojan horses, or the like which may be transmitted to or through the site by any third party.</li>
							<li>Any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the site.</li>
						</ol>
					</Typography.Paragraph>
					<Typography.Paragraph>We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the site, any hyperlinked website, or any website or mobile application featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party providers of products or services. As with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</Typography.Paragraph>
					<Typography.Title level={2}>Limitations of liability</Typography.Title>
					<Typography.Paragraph>In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the site, even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to us during the six (6) month period prior to any cause of action arising. Certain us state laws and international laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers or limitations may not apply to you, and you may have additional rights.</Typography.Paragraph>
					<Typography.Paragraph>If you have any questions about our disclaimer, do not hesitate to contact us at <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`} target="_blank" rel="noopener noreferrer nofollow">{process.env.REACT_APP_COMPANY_EMAIL}</a>.</Typography.Paragraph>
				</Typography>
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(withRouter(DisclaimerPage));
