import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

import { Row, Col, Divider, Typography } from 'antd';

import { LockOutlined as LockOutlinedIcon } from '@ant-design/icons';

import '../AuthenticationPage.scss';

import Loader from '../../Util/UI/Loader';
import SignupForm from '../../Util/UI/Forms/Login/SignupForm';

import { GlobalState, DefaultPrivateRoute, AppRoutes, AppRouteParams, AppRouteState } from '../../../Contracts';

export const mapStateToProps = (state: GlobalState) => {
	return {
		isLoginLoading: state.login.isLoading,
		browserWindowWidth: state.util.browserWindowWidth
	};
};

export interface Props extends RouteComponentProps<AppRouteParams, {}, AppRouteState>, ReturnType<typeof mapStateToProps> { }

export class SignupPage extends Component<Props> {

	showLoginPage = () => {
		this.props.history.push(AppRoutes.Login);
	}

	showForgotPasswordPage = () => {
		this.props.history.push(AppRoutes.ForgotPassword);
	}

	onSignup = () => {
		this.props.history.push((this.props.location.state?.from?.length > 0) ? this.props.location.state?.from : DefaultPrivateRoute, { from: this.props.location.pathname as AppRoutes });
	}

	render() {
		return (
			<div className="signup-page-container">
				<Helmet>
					<title>Signup</title>
				</Helmet>
				{this.props.isLoginLoading && <Loader />}
				<Row className="full-height">
					<Col xs={0} sm={0} md={14} lg={16} xl={18} xxl={18} className="signup-banner-container">
						<div className="signup-banner-title-container">
							<div className="signup-banner" />
							<div className="right-shape" />
							<Typography.Title level={1} className="signup-banner-title">{process.env.REACT_APP_COMPANY_NAME}</Typography.Title>
							<Divider className="signup-banner-title-divider" />
							<Typography.Text className="signup-banner-subtitle">{process.env.REACT_APP_COMPANY_BIO}</Typography.Text>
							<Typography.Text className="signup-banner-description">{process.env.REACT_APP_COMPANY_DESCRIPTION}</Typography.Text>
						</div>
					</Col>
					<Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={6} className="signup-form-container">
						<div className="signup-form-icon margin-bottom-32px">
							<LockOutlinedIcon />
						</div>
						<Typography.Text className="margin-bottom-24px signup-form-header">Sign Up</Typography.Text>
						<SignupForm afterSubmit={this.onSignup} showLoginPage={this.showLoginPage} showForgotPasswordPage={this.showForgotPasswordPage} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default connect(mapStateToProps, {})(withRouter(SignupPage));
